import { CommandActionType } from "../CommandActionType.js";
import { CommandOutputType } from "../CommandOutputType.js";
import { ICommand } from "../ICommand.js";

export const COMMAND_EXTRACT_QUESTIONS_TEMPLATE: ICommand = {
  id: "702aef9d-0a71-414d-bc20-011ca5d7594a",
  name: "Extract Questions",
  description: "",
  action: {
    actionType: CommandActionType.ExtractQuestionsAction,
    data: {},
  },
  inputs: [],
  inputTemplate: "",
  isBlocking: false,
  outputTypes: [CommandOutputType.None],
};
