import { ItemGridSize, ItemViewType } from "@bigpi/cookbook";
import { Box, Button } from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useWorkspacesQuery } from "GraphQL/Generated/Apollo";
import { WorkspaceGrid } from "./WorkspaceGrid";
import { WorkspaceList } from "./WorkspaceList";

const DEFAULT_PAGE_SIZE = 20;

export interface IWorkspaceViewProps {
  filterTags: Array<string>;
  gridSize: ItemGridSize;
  includeUntagged: boolean;
  onGridSizeChange: (gridSize: ItemGridSize) => void;
  setCurrentWorkspaceCount: Dispatch<SetStateAction<number>>;
  setTotalWorkspaceCount: Dispatch<SetStateAction<number>>;
  viewType: ItemViewType;
  searchValue: string;
}

export function WorkspaceView(props: IWorkspaceViewProps) {
  const {
    filterTags,
    gridSize,
    includeUntagged,
    onGridSizeChange,
    setCurrentWorkspaceCount,
    setTotalWorkspaceCount,
    viewType,
    searchValue,
  } = props;
  const { t } = useTranslation();

  const [offset, setOffset] = useState(0);
  const [orderBy, setOrderBy] = useState<Record<string, string>>({ updatedAt: "desc" });

  const {
    data: workspacesData,
    loading: workspacesLoading,
    fetchMore: fetchMoreWorkspaces,
    error: workspacesError,
  } = useWorkspacesQuery({
    variables: {
      limit: DEFAULT_PAGE_SIZE,
      offset,
      orderBy,
      filters: {
        tags: filterTags,
        includeUntagged,
        searchTerm: searchValue,
      },
    },
  });
  const rows = workspacesData?.workspaces || [];
  const count = workspacesData?.workspaceAggregate.count || 0;

  useEffect(() => {
    setCurrentWorkspaceCount(rows.length);
    setTotalWorkspaceCount(count);
  }, [rows.length, count]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
      <Box sx={{ display: "flex", p: 3, justifyContent: "center" }}>
        {viewType === ItemViewType.List ? (
          <WorkspaceList loading={workspacesLoading} rows={rows} error={workspacesError} />
        ) : (
          <WorkspaceGrid
            gridSize={gridSize}
            loading={workspacesLoading}
            onGridSizeChange={onGridSizeChange}
            rows={rows}
            error={workspacesError}
          />
        )}
      </Box>

      {count > rows.length && (
        <Button
          sx={{ alignSelf: "center", maxWidth: "150px" }}
          onClick={() => {
            fetchMoreWorkspaces({
              variables: {
                offset: rows.length,
                filters: {
                  tags: filterTags,
                  includeUntagged,
                  searchTerm: searchValue,
                },
              },
            });
          }}
        >
          {t("Pages.Workspaces.List.ShowMore")}
        </Button>
      )}
    </Box>
  );
}
