import { FileSourceType, getFileDownloadUrlByFileId, IndexDocumentType } from "@bigpi/cookbook";
import { Add, Download, Preview } from "@mui/icons-material";
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  IconButton,
  Link,
  Popover,
  Typography,
} from "@mui/material";
import { HTMLReactParserOptions, Element, domToReact, DOMNode, default as parse } from "html-react-parser";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import { useCallback, useEffect, useState } from "react";

import { ChatActionExecutor } from "Chat/ChatActionManagers/ChatActionExecutor";
import { ChatMessageAction } from "Chat/ChatActionManagers/ChatMessageActions";
import { FilePreviewDialog, IFilePreviewDetails } from "Components/FilePreviewDialog/FilePreviewDialog";
import { Config } from "Config";
import { ChatMessageChannelType, useFileLazyQuery, useWorkspaceFileLazyQuery } from "GraphQL/Generated/Apollo";
import { getWorkspaceFileDownloadUrl } from "Utils/WorkspaceFileUtils";

export interface IShowCard1Props {
  dataReferenceId: string;
  domNode: Element;
  nodeData?: Record<string, any>;
  options: HTMLReactParserOptions;
  channelType: ChatMessageChannelType;
  channelId: string;
  chatActionExecutor: ChatActionExecutor;
}

export function ShowCard1(props: IShowCard1Props) {
  const { dataReferenceId, domNode, nodeData, options, channelId, channelType, chatActionExecutor } = props;

  const [cardData, setCardData] = useState<Record<string, any> | undefined>(nodeData);
  const [isDownloadButtonDisabled, setIsDownloadButtonDisabled] = useState<boolean>(true);
  const [fileDownloadUrl, setFileDownloadUrl] = useState<string>("");
  const [filePreviewDetails, setFilePreviewDetails] = useState<IFilePreviewDetails | null>(null);
  const [previewDialogOpen, setPreviewDialogOpen] = useState<boolean>(false);

  const [getWorkspaceFile, { loading: workspaceFileDataLoading }] = useWorkspaceFileLazyQuery();
  const [getFile, { loading: fileDataLoading }] = useFileLazyQuery();

  useEffect(() => {
    if (JSON.stringify(nodeData) !== JSON.stringify(cardData)) {
      setCardData(nodeData);
    }
  }, [nodeData, cardData]);

  useEffect(() => {
    const workspaceId = channelType === ChatMessageChannelType.Workspace ? channelId : undefined;
    if (cardData && cardData.fileId && cardData.documentType && workspaceId) {
      /**
       * Sanity check to ensure -
       * 1. We have the required file in the storage
       * 2. The file belongs to the current workspace, if it is a workspace file
       * 3. User has access to the file/ workspace
       * 4. We are not using unsanitized filename
       */
      switch (cardData.documentType) {
        case IndexDocumentType.WorkspaceFile:
          getWorkspaceFile({
            variables: {
              id: cardData.fileId,
            },
          }).then(({ data }) => {
            if (data?.workspaceFile?.workspaceId === workspaceId) {
              const downloadUrl = getWorkspaceFileDownloadUrl(workspaceId, data.workspaceFile.id, data.workspaceFile.filename);
              setFileDownloadUrl(downloadUrl);
              setIsDownloadButtonDisabled(false);

              setFilePreviewDetails({
                fileId: data.workspaceFile.id,
                fileSourceType: FileSourceType.WorkspaceFile,
              });
            }
          });
          break;
        case IndexDocumentType.File:
          getFile({
            variables: {
              id: cardData.fileId,
            },
          }).then(({ data }) => {
            if (data?.file) {
              const downloadUrl = getFileDownloadUrlByFileId(Config.assetHttpUrl, data.file.id);
              setFileDownloadUrl(downloadUrl);
              setIsDownloadButtonDisabled(false);

              setFilePreviewDetails({
                fileId: data.file.id,
                fileSourceType: FileSourceType.Library,
              });
            }
          });
          break;
        default:
          // No-Op
          break;
      }
    }
  }, [cardData, channelId, channelType, getFile, getWorkspaceFile]);

  const addFilePreviewToBoard = useCallback(() => {
    if (!filePreviewDetails) {
      return;
    }

    chatActionExecutor.raiseChatAction({
      action: ChatMessageAction.AddFilePreviewToBoard,
      data: {
        fileId: filePreviewDetails.fileId,
        fileSourceType: filePreviewDetails.fileSourceType,
      },
    });
  }, [chatActionExecutor, filePreviewDetails]);

  return (
    <PopupState variant="popover" popupId={dataReferenceId}>
      {(popupState) => (
        <>
          <Link component="button" sx={{ textAlign: "left" }} disabled={!cardData} {...bindTrigger(popupState)}>
            {domToReact(domNode.children as Array<DOMNode>, options)}
          </Link>
          {cardData && (
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
            >
              <Card sx={{ maxWidth: 518 }}>
                {cardData && cardData.title && (
                  <CardHeader title={cardData.title} titleTypographyProps={{ variant: "h6" }} subheader={cardData.subTitle} />
                )}
                <CardContent className="astra document">
                  <Typography>{cardData.content ? parse(cardData.content, options) : ""}</Typography>
                </CardContent>
                <CardActions disableSpacing sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <IconButton
                    onClick={addFilePreviewToBoard}
                    disabled={!filePreviewDetails || workspaceFileDataLoading || fileDataLoading}
                  >
                    {workspaceFileDataLoading || fileDataLoading ? <CircularProgress size={20} /> : <Add />}
                  </IconButton>

                  <IconButton
                    onClick={() => setPreviewDialogOpen(true)}
                    disabled={!filePreviewDetails || workspaceFileDataLoading || fileDataLoading}
                  >
                    {workspaceFileDataLoading || fileDataLoading ? <CircularProgress size={20} /> : <Preview />}
                  </IconButton>

                  <IconButton
                    href={fileDownloadUrl}
                    target="_blank"
                    disabled={isDownloadButtonDisabled || workspaceFileDataLoading || fileDataLoading}
                  >
                    {workspaceFileDataLoading || fileDataLoading ? <CircularProgress size={20} /> : <Download />}
                  </IconButton>
                </CardActions>
              </Card>

              {previewDialogOpen && filePreviewDetails && (
                <FilePreviewDialog
                  fileId={filePreviewDetails.fileId}
                  fileType={filePreviewDetails.fileSourceType}
                  onClose={() => setPreviewDialogOpen(false)}
                />
              )}
            </Popover>
          )}
        </>
      )}
    </PopupState>
  );
}
