/**
 * Possible valid status values for a command response.
 */
export type ICommandResponseStatus = "canceled" | "duplicate" | "queued" | "running" | "progress" | "success" | "failure";

export const COMMAND_STATUS_RUNNING: Array<ICommandResponseStatus> = ["queued", "running", "progress"];

/**
 * The response to a command request.
 */
export interface ICommandResponse {
  activeCommandContext: Record<string, any>;
  callerData?: string;
  data: string | undefined;
  errors: Array<string>;
  requestId: string;
  status: ICommandResponseStatus;
  sessionId: string;
}
