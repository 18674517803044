import { Node, mergeAttributes } from "@tiptap/core";

import { getBaseClassWithoutImageAlignment } from "../../Utils/CommandUtil.js";

export interface FigureOptions {
  HTMLAttributes: Record<string, any>;
}

export const Figure = Node.create<FigureOptions>({
  name: "figure",

  content: "image",

  draggable: true,

  selectable: true,

  // Figure is a block node
  inline: false,

  // Figure is part of the "block" group i.e. if a node defines "block" in the content
  // expression then an "figure" node can be a child of that node
  group: "block",

  // Though the figure node is not a leaf node, but it doesn't exist without its contents
  atom: true,

  addOptions() {
    return {
      HTMLAttributes: {
        class: "image",
      },
    };
  },

  addAttributes() {
    return {
      class: {
        default: null,
        parseHTML: (element: HTMLElement) => {
          const classAttr = element.hasAttribute("class") ? element.getAttribute("class") : null;
          // Keep all classes except for those that start with "image-style-"
          return getBaseClassWithoutImageAlignment(classAttr);
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "figure",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["figure", mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },
});
