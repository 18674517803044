/**
 * Enum for item view type, used to determine how items are displayed in the UI.
 */
export enum ItemViewType {
  /**
   * List view.
   */
  List = "List",

  /**
   * Grid view.
   */
  Grid = "Grid",
}
