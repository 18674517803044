import { TabContext, TabPanel } from "@mui/lab";
import { Divider } from "@mui/material";
import { TLShapeId, useValue } from "@tldraw/tldraw";
import { useCallback, useEffect, useState } from "react";

import { useCollaborativeBoardEditor } from "TldrawBoard/useCollaborativeBoardEditor";
import { CompanionTabActions } from "./CompanionActions";
import { CompanionContent } from "./CompanionContent";
import { CompanionHeader } from "./CompanionHeader";
import { CompanionOpenedEventArgs } from "./CompanionManager";
import { CompanionTab } from "./CompanionTab";
import { CompanionTabs } from "./CompanionTabs";
import { CompanionTitle } from "./CompanionTitle";
import { CompanionWindow } from "./CompanionWindow";
import { ICompanion } from "./ICompanion";
import { ICompanionOptions } from "./ICompanionOptions";
import { useCompanionManager } from "./useCompanionManager";

/**
 * Manages companion windows for all shapes.
 *
 * @param props The component props.
 *
 * @returns A component containing passed in children and the common Companion element.
 */
export function Companion() {
  const [activeCompanion, setActiveCompanion] = useState<ICompanion | undefined>(undefined);
  const [activeTabId, setActiveTabId] = useState<string | undefined>(undefined);
  const [parentShapeId, setParentShapeId] = useState<TLShapeId | undefined>(undefined);
  const [options, setOptions] = useState<ICompanionOptions | undefined>();
  const companionManager = useCompanionManager();
  const editor = useCollaborativeBoardEditor();

  const isOpen = useValue("Companion.isOpen", () => companionManager.isOpen, [companionManager]);
  const parentShape = useValue("Companion.parentShape", () => (parentShapeId ? editor?.getShape(parentShapeId) : undefined), [
    editor,
    parentShapeId,
  ]);

  useEffect(() => {
    // Handle the shape being deleted
    if (!parentShape && isOpen) {
      // Raise event
      companionManager.raiseCompanionClosed();
    }
  }, [parentShape]);

  useEffect(() => {
    const onCompanionOpened = (event: CompanionOpenedEventArgs) => {
      setParentShapeId(event.shapeId);
      setActiveCompanion(event.companion);

      if (event.options?.tabId) {
        setActiveTabId(event.options.tabId);
      } else {
        // Activate the first tab
        setActiveTabId(event.companion.tabs[0].tabId);
      }

      setOptions(event.options);
    };

    companionManager.on("companionOpened", onCompanionOpened);

    return () => {
      companionManager.off("companionOpened", onCompanionOpened);
    };
  }, [companionManager]);

  const onClose = useCallback(() => {
    // Raise event
    companionManager.raiseCompanionClosed();
  }, [companionManager]);

  // Raise event when the active tab changes
  useEffect(() => {
    if (activeCompanion && parentShapeId && activeTabId) {
      companionManager.raiseCompanionTabChanged({
        companion: activeCompanion,
        shapeId: parentShapeId,
        tabId: activeTabId,
      });
    }
  }, [activeTabId]);

  if (!activeCompanion || !isOpen || !parentShapeId) {
    return null;
  }

  const { tabs } = activeCompanion;

  return (
    <CompanionWindow isVisible={true} shapeId={parentShapeId} relativePosition={options?.relativePosition}>
      <CompanionTitle onClose={onClose}>{activeCompanion.title}</CompanionTitle>
      <Divider />

      {tabs.length > 1 || (tabs.length === 1 && tabs[0].actions.length > 0) ? (
        <>
          <CompanionHeader>
            <CompanionTabs
              value={activeTabId ?? tabs[0].tabId}
              onChange={(event: React.SyntheticEvent, newValue: string) => {
                // Event?
                setActiveTabId(newValue);
              }}
              sx={{ flexGrow: 1, mr: 2 }}
            >
              {tabs.map((tab) => (
                <CompanionTab key={tab.tabId} label={tab.title} value={tab.tabId} />
              ))}
            </CompanionTabs>
            <CompanionTabActions tabActions={tabs.find((tab) => tab.tabId === activeTabId)?.actions ?? []} />
          </CompanionHeader>
          <CompanionContent>
            <TabContext value={activeTabId ?? tabs[0].tabId}>
              {tabs.map((tab) => (
                <TabPanel key={tab.tabId} value={tab.tabId}>
                  {tab.children}
                </TabPanel>
              ))}
            </TabContext>
          </CompanionContent>
        </>
      ) : (
        <CompanionContent>{tabs[0]?.children}</CompanionContent>
      )}
    </CompanionWindow>
  );
}
