import { ShapeTag } from "../Enums/ShapeTag.js";

export enum TagColors {
  Red = "#F08070",
  Orange = "#F0C070",
  Yellow = "#FAE070",
  Green = "#90E080",
  Blue = "#60A0F0",
  Purple = "#D080F0",
  Gray = "#D0D0D0",
}

/**
 * Shape tag properties
 */
export const ShapeTagProps: Record<ShapeTag, { color: string }> = {
  [ShapeTag.KnowledgeBaseArticle]: {
    color: TagColors.Blue,
  },
  [ShapeTag.Plan]: {
    color: TagColors.Green,
  },
  [ShapeTag.Questionnaire]: {
    color: TagColors.Purple,
  },
  [ShapeTag.ReadOnly]: {
    color: TagColors.Gray,
  },
};
