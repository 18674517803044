export const RoutePaths = {
  home: {
    path: "/",
  },
  document: {
    path: "/documents/:id",
  },
  documents: {
    path: "/documents",
  },
  library: {
    path: "/library",
  },
  userGroups: {
    path: "/userGroups",
  },
  userGroup: {
    path: "/userGroups/:id",
  },
  workspaces: {
    path: "/workspaces",
  },
  workspace: {
    path: "/workspaces/:id",
  },
  workspaceBoard: {
    path: "/workspaces/:workspaceId/boards/:workspaceBoardId",
  },
  charts: {
    path: "/charts",
  },
};

export type PathKeys = keyof typeof RoutePaths;

export function linkToId(path: PathKeys, id: string): string {
  return RoutePaths[path].path.replace(":id", id);
}
