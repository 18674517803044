import { useApolloClient } from "@apollo/client";
import { FixedToolbar } from "@bigpi/cutlery";
import {
  CollaborativeDocumentEditor,
  CollaborativeDocumentEditorConfig,
  ToolbarFactory,
  useCollaborativeEditor,
  FloatingToolbar,
  PageSize,
  PageMargin,
  PageOrientation,
  getCommonEditorExtensions,
  EditorOptions,
  IExtensionOptions,
  ActionMenuFactory,
  getPageClassNames,
} from "@bigpi/editor-tiptap";
import { useAuthUser } from "@frontegg/react";
import { useValue } from "@tldraw/tldraw";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Config } from "Config";
import { ExportToWord } from "Editor/Extensions/ExportToWord/ExportToWordExtension";
import { useDoubtfireCleanup } from "Editor/Extensions/PasteContent/useDoubtfireCleanup";
import { ObjectRole, useDocumentAccessControlListUserRolesQuery } from "GraphQL/Generated/Apollo";
import { getRandomColor } from "Utils/ColorUtils";
import { getExportHtmlToWordFn } from "Utils/ExportToWordUtil";
import { getUploadDocumentImageFn } from "Utils/UploadUtil";
import { getPlaceholderFn } from "Utils/PlaceholderUtil";

import "./Document.css";

// *********************************************
// Interfaces
// *********************************************/
export interface IDocumentProps {
  id: string;
  isReadOnly?: boolean;
  name: string;
}

// *********************************************
// Private methods
// *********************************************/
function _getCollaborativeConfig(user: ReturnType<typeof useAuthUser>, documentId: string) {
  return {
    documentId,
    editorUser: {
      accessToken: user.accessToken,
      color: getRandomColor(),
      name: user.name,
    },
    runnerHttpUrl: Config.runnerHttpUrl,
    runnerPath: "documents",
    runnerWsUrl: Config.runnerWsUrl,
  };
}

// *********************************************
// Public methods
// *********************************************/
export function Document(props: IDocumentProps) {
  const { id, isReadOnly, name } = props;

  const apolloClient = useApolloClient();
  const user = useAuthUser();
  const { t } = useTranslation();
  const getUserToken = useCallback(() => {
    return user.accessToken;
  }, [user]);
  const {
    data: aclData,
    error: aclError,
    loading: aclLoading,
  } = useDocumentAccessControlListUserRolesQuery({
    variables: {
      userId: user.id,
      documentId: id,
    },
  });
  const [editorOptions, setEditorOptions] = useState<Partial<EditorOptions>>();
  const [extensionOptions, setExtensionOptions] = useState<IExtensionOptions>();
  const [collaborativeConfig, setCollaborativeConfig] = useState<CollaborativeDocumentEditorConfig>(
    _getCollaborativeConfig(user, id),
  );

  const pageSize = PageSize.Letter;
  const pageMargin = PageMargin.Normal;
  const pageOrientation = PageOrientation.Portrait;

  const isEditable = useValue(
    "isEditable",
    () => {
      if (isReadOnly !== undefined) {
        return !isReadOnly;
      }
      return (
        aclData?.documentAccessControlListUserRoles.roles.some((assignedRole) =>
          [ObjectRole.ContentManager, ObjectRole.Contributor, ObjectRole.Manager, ObjectRole.Owner].includes(assignedRole),
        ) === true
      );
    },
    [isReadOnly, aclData?.documentAccessControlListUserRoles],
  );

  useEffect(() => {
    setExtensionOptions({
      Image: {
        uploadImage: getUploadDocumentImageFn(Config.apiGatewayHttpUrl, getUserToken, id),
      },
      Placeholder: {
        placeholder: getPlaceholderFn(t),
      },
      ExportToWord: {
        exportHtmlToWord: getExportHtmlToWordFn(name, Config.apiGatewayHttpUrl, getUserToken),
      },
    });
  }, [getUserToken, id, name, t, apolloClient]);

  useEffect(() => {
    if (extensionOptions) {
      setEditorOptions({
        // Disabled: Built-in autofocus goes to end of document due to async load of data
        autofocus: false,
        editable: isEditable,
        editorProps: {
          attributes: {
            class: getPageClassNames(pageSize, pageOrientation, pageMargin, "astra document"),
          },
        },
        extensions: [ExportToWord, ...getCommonEditorExtensions(extensionOptions, ["history"])],
      });
    }
  }, [isEditable, extensionOptions]);

  const { collaborativeEditor, authError, contentError, isAuthLoading } = useCollaborativeEditor(
    editorOptions,
    collaborativeConfig,
  );

  useDoubtfireCleanup(collaborativeEditor);

  useEffect(() => {
    if (collaborativeEditor && aclData) {
      collaborativeEditor.setEditable(isEditable);
    }
  }, [collaborativeEditor, aclData, isEditable]);

  let floatingToolbar: JSX.Element | JSX.Element[] | null = null;
  let fixedToolbar: JSX.Element | JSX.Element[] | null = null;
  let actionMenus: JSX.Element[] | null = null;
  if (collaborativeEditor && editorOptions) {
    floatingToolbar = ToolbarFactory.createToolbar(collaborativeEditor, { editorOptions, extensionOptions }, [
      "bulletList",
      "orderedList",
      "image",
      "table",
    ]);
    fixedToolbar = ToolbarFactory.createToolbar(collaborativeEditor, { editorOptions, extensionOptions }, [
      "undo",
      "redo",
      "|",
      "textStyle",
      "|",
      "bold",
      "italic",
      "strike",
      "underline",
      "|",
      "textColor",
      "highlight",
      "|",
      "bulletList",
      "orderedList",
      "collapsibleBlock",
      "|",
      "textAlign",
      "|",
      "indent",
      "outdent",
      "|",
      "link",
      "image",
      "table",
      "|",
      "search",
      "|",
      "exportToWord",
    ]);
    actionMenus = ActionMenuFactory.createActionMenu(collaborativeEditor, { editorOptions, extensionOptions }, [
      "imageActions",
      "linkActions",
    ]);
  }

  const isFixedToolbarAvailable = !!fixedToolbar && isEditable;
  const isFloatingToolbarAvailable = !!floatingToolbar && isEditable;
  const isActionMenusAvailable = !!actionMenus && isEditable;

  // NOTE: The toolbar transform needs to account for page main menu width (width / 2)
  return (
    <>
      {isFixedToolbarAvailable && (
        <FixedToolbar sx={{ marginTop: "12px", transform: "translateX(calc(-50% + 24px))", left: "50%" }}>
          {fixedToolbar}
        </FixedToolbar>
      )}

      {isFloatingToolbarAvailable && <FloatingToolbar editor={collaborativeEditor}>{floatingToolbar}</FloatingToolbar>}

      {isActionMenusAvailable && <>{actionMenus}</>}

      <CollaborativeDocumentEditor
        collaborativeEditor={collaborativeEditor}
        authError={authError}
        contentError={contentError}
        isAuthLoading={isAuthLoading}
        containerClassName={`editor-document ${isFixedToolbarAvailable ? "with-fixed-menu" : ""}`}
        pageMargin={pageMargin}
        pageOrientation={pageOrientation}
        pageSize={pageSize}
      />
    </>
  );
}
