export enum CommandOutputType {
  // Search results for content on a board
  BoardSearchResults = "BoardSearchResults",

  // General search results
  SearchResults = "SearchResults",

  Html = "Html",
  // LineChart = "LineChart",
  // PieChart = "PieChart",
  Table = "Table",
  // BarChart = "BarChart",
  // TimeSeriesChart = "TimeSeriesChart",

  // Specialized output types
  HeadlineSimulator = "HeadlineSimulator",

  // Display analyst question analysis
  AnalystQuestionAnalysis = "AnalystQuestionAnalysis",

  // Display generic command error
  Error = "Error",

  // Transcript, Press release, etc. feed
  Feed = "Feed",

  // No output to display or add. Generated data may be placed directly into a database
  None = "None",

  // Display question analysis
  QuestionAnalysis = "QuestionAnalysis",

  // Display topic discussion examples in a bubble chart
  TopicDiscussionAnalysis = "TopicDiscussionAnalysis",

  // Display topic discussion summary examples in a bubble chart
  TopicDiscussionSummaryAnalysis = "TopicDiscussionSummaryAnalysis",

  // Display topic discussion in news articles in a histogram chart
  TopicDiscussionInNewsArticleAnalysis = "TopicDiscussionInNewsArticleAnalysis",

  // HTML Prompt
  Summarize = "Summarize",
  Compare = "Compare",
}
