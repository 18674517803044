/**
 * Enum for ItemGridSize, used to determine the size of items in the grid view.
 */
export enum ItemGridSize {
  /**
   * Small grid item.
   */
  Small = "Small",

  /**
   * Medium grid item.
   */
  Medium = "Medium",

  /**
   * Large grid item.
   */
  Large = "Large",
}
