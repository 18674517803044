import { CONFIG_KEY_ORGANIZATION_PREFERENCES, IPagePreferences } from "@bigpi/cookbook";
import { useAuthUser } from "@frontegg/react";
import { useEffect, useState } from "react";

import { useGetConfigDataQuery } from "GraphQL/Generated/Apollo";
import { PathKeys } from "RoutePaths";

export function useDefaultPagePreferences(page: PathKeys): IPagePreferences {
  const user = useAuthUser();
  const organizationId = user?.tenantId;

  const [defaultPagePreferences, setDefaultPagePreferences] = useState<IPagePreferences>({});

  const { data: appConfigDataResult } = useGetConfigDataQuery({
    variables: {
      key: CONFIG_KEY_ORGANIZATION_PREFERENCES,
      organizationId,
    },
  });

  useEffect(() => {
    let newDefaultPagePreferences: IPagePreferences = {};
    const pagePreferences = JSON.parse(appConfigDataResult?.Config?.data || "{}").pagePreferences;
    if (pagePreferences) {
      newDefaultPagePreferences = pagePreferences[page] || {};
    }

    setDefaultPagePreferences(newDefaultPagePreferences);
  }, [appConfigDataResult?.Config?.data, page]);

  return defaultPagePreferences;
}
