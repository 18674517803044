import { CommandActionType } from "../CommandActionType.js";
import { CommandOutputType } from "../CommandOutputType.js";
import { ICommand } from "../ICommand.js";

export const COMMAND_CREATE_HTML_DOCUMENT_SHAPE_FROM_FILE_TEMPLATE: ICommand = {
  id: "5a69b6e6-3a7f-4f20-b72b-70f1f865805e",
  name: "Create html document shape from file",
  description: "Ingests file content to standard HTML & creates a HTML document shape",
  action: {
    actionType: CommandActionType.CreateHtmlDocumentShapeFromFile,
    data: {},
  },
  inputs: [],
  inputTemplate: "",
  isBlocking: false,
  outputTypes: [CommandOutputType.None],
};
