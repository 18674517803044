import { ItemGridSize } from "@bigpi/cookbook";
import { AddOutlined, RemoveOutlined, ZoomOutOutlined } from "@mui/icons-material";
import { Grid, IconButton } from "@mui/material";
import { Box } from "@mui/system";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { DocumentsQuery } from "GraphQL/Generated/Apollo";
import { DocumentsGridItem } from "./DocumentsGridItem";

interface IDocumentsGridProps {
  gridSize: ItemGridSize;
  loading: boolean;
  onGridSizeChange: (gridSize: ItemGridSize) => void;
  onSelectionChange: (id: string) => void;
  rows: DocumentsQuery["documents"];
  selectedIds: Array<string>;
}

export function DocumentsGrid(props: IDocumentsGridProps) {
  const { loading, rows, selectedIds, onSelectionChange, gridSize, onGridSizeChange } = props;
  const { t } = useTranslation();

  // Hooks
  const increaseGridSize = useCallback(() => {
    const newGridSize =
      gridSize === ItemGridSize.Small
        ? ItemGridSize.Medium
        : gridSize === ItemGridSize.Medium
          ? ItemGridSize.Large
          : ItemGridSize.Large;
    onGridSizeChange(newGridSize);
  }, [onGridSizeChange]);

  const decreaseGridSize = useCallback(() => {
    const newGridSize =
      gridSize === ItemGridSize.Large
        ? ItemGridSize.Medium
        : gridSize === ItemGridSize.Medium
          ? ItemGridSize.Small
          : ItemGridSize.Small;
    onGridSizeChange(newGridSize);
  }, [onGridSizeChange]);

  return (
    <>
      {!loading && rows.length > 0 && (
        <>
          <Grid
            component="nav"
            container
            spacing={gridSize === ItemGridSize.Small ? 1 : gridSize === ItemGridSize.Medium ? 2 : 3}
            aria-label={t("Pages.Documents.List.AvailableDocuments")}
            alignItems="stretch"
            columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 24 }}
          >
            {rows.map((row) => {
              return (
                <DocumentsGridItem
                  key={row.id}
                  document={row}
                  size={gridSize}
                  checked={selectedIds.includes(row.id)}
                  onSelectionChange={onSelectionChange}
                />
              );
            })}
          </Grid>
          {rows.length > 0 && (
            <Box
              sx={{
                backgroundColor: "rgba(0,0,0,0.12)",
                border: "1px solid rgba(0,0,0,0.24)",
                display: "flex",
                alignItems: "center",
                position: "fixed",
                bottom: "2rem",
                left: "50%",
                transform: "translateX(-50%)",
                borderRadius: "2rem",
              }}
            >
              <IconButton color="primary" disabled={gridSize === ItemGridSize.Small} onClick={decreaseGridSize}>
                <RemoveOutlined />
              </IconButton>
              <ZoomOutOutlined sx={{ color: "rgba(0,0,0,0.54)" }} />
              <IconButton color="primary" disabled={gridSize === ItemGridSize.Large} onClick={increaseGridSize}>
                <AddOutlined />
              </IconButton>
            </Box>
          )}
        </>
      )}
    </>
  );
}
