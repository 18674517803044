import { IPlugIn } from "@bigpi/cookbook";

import {
  BoardSearchResultResponsePlugIn,
  ErrorResponsePlugIn,
  HeadlineSimulatorResponsePlugIn,
  HtmlDocumentResponsePlugIn,
  SearchResultResponsePlugIn,
} from ".";
import { ICommandResponsePlugInInputs } from "Components/CommandManagers/WorkspaceBoard/WorkspaceBoardCommandManager";

/**
 * Creates the plug-ins.
 *
 * @param options Options for the plug-ins.
 *
 * @returns An array of plug-ins to be registered.
 */
export function createCommandResponsePlugIns(): IPlugIn<ICommandResponsePlugInInputs, undefined | JSX.Element>[] {
  return [
    new BoardSearchResultResponsePlugIn(),
    new ErrorResponsePlugIn(),
    new HeadlineSimulatorResponsePlugIn(),
    new HtmlDocumentResponsePlugIn(),
    new SearchResultResponsePlugIn(),
  ];
}
