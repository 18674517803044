import { ShapeTag } from "./ShapeTag.js";

export enum DocumentType {
  /**
   * None
   */
  None = "None",

  /**
   * Knowledge base article
   */
  KnowledgeBaseArticle = "KnowledgeBaseArticle",

  /**
   * A plan document. Normally used as part of instructions for questionnaire answering/completion.
   */
  Plan = "Plan",

  /**
   * Questionnaire
   */
  Questionnaire = "Questionnaire",
}

export function documentTypeToShapeTag(documentType: DocumentType | undefined): ShapeTag | undefined {
  switch (documentType) {
    case DocumentType.KnowledgeBaseArticle:
      return ShapeTag.KnowledgeBaseArticle;
    case DocumentType.Plan:
      return ShapeTag.Plan;
    case DocumentType.Questionnaire:
      return ShapeTag.Questionnaire;
    default:
      return undefined;
  }
}
