import { gql } from "@apollo/client";

export const WORKSPACE_BOARD_TABLE_OF_CONTENTS_CREATE_SUBSCRIPTION = gql`
  subscription OnWorkspaceBoardTableOfContentsCreate($shapeId: ID!, $workspaceBoardId: ID!) {
    onWorkspaceBoardTableOfContentsCreate(shapeId: $shapeId, workspaceBoardId: $workspaceBoardId) {
      blockId
      id
      parentId
      shapeId
      sortOrder
      title
      workspaceBoardId
    }
  }
`;

export const WORKSPACE_BOARD_TABLE_OF_CONTENTS_UPDATE_SUBSCRIPTION = gql`
  subscription OnWorkspaceBoardTableOfContentsUpdate($shapeId: ID!, $workspaceBoardId: ID!) {
    onWorkspaceBoardTableOfContentsUpdate(shapeId: $shapeId, workspaceBoardId: $workspaceBoardId) {
      blockId
      id
      parentId
      shapeId
      sortOrder
      title
      workspaceBoardId
    }
  }
`;

export const WORKSPACE_BOARD_TABLE_OF_CONTENTS_DELETE_SUBSCRIPTION = gql`
  subscription OnWorkspaceBoardTableOfContentsDelete($shapeId: ID!, $workspaceBoardId: ID!) {
    onWorkspaceBoardTableOfContentsDelete(shapeId: $shapeId, workspaceBoardId: $workspaceBoardId) {
      id
    }
  }
`;
