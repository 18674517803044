import {
  Editor,
  TLBaseBoxShape,
  BaseBoxShapeUtil,
  TLResizeInfo,
  TLOnResizeHandler,
  TLOnDoubleClickHandler,
  Vec,
} from "@tldraw/tldraw";

/**
 * A base class for box-like shapes that encapsulates common, default behavior for custom shapes.
 */
export abstract class BoxBaseUtil<T extends TLBaseBoxShape> extends BaseBoxShapeUtil<T> {
  // *********************************************
  // Protected fields
  // *********************************************/
  protected disableDefaultDoubleClick = true;
  protected minHeight?: number;
  protected minWidth?: number;
  protected maxHeight = Infinity;
  protected maxWidth = Infinity;
  protected superOnResize?: TLOnResizeHandler<any>;

  // *********************************************
  // Constructors
  // *********************************************/
  constructor(editor: Editor) {
    super(editor);

    // Grab a reference to the existing resize handler
    this.superOnResize = this.onResize;

    // Override the resize handler
    this.onResize = this.onCustomResize;
  }

  // *********************************************
  // Method overrides, event handlers
  // *********************************************/
  /**
   * Overrides double-click behavior to prevent zooming and centering.
   *
   * @param shape The shape that was double-clicked.
   *
   * @returns A change to apply to the shape, or void.
   */
  onDoubleClick: TLOnDoubleClickHandler<T> | undefined = (shape: T) => {
    if (this.disableDefaultDoubleClick) {
      // Check if shape allows editing otherwise we'll crash
      if (this.canEdit(shape)) {
        // Prevent Tldraw's default zoom and center behavior
        this.editor.mark("editing shape");
        this.editor.setEditingShape(shape.id);
        this.editor.setCurrentTool("select.editing_shape", {
          target: "shape",
          shape,
        });
      }

      return undefined;
    } else {
      // @ts-expect-error TODO: This is invalid and will be flagged by newer TypeScript versions
      super.onDoubleClick?.(shape);
    }
  };

  /**
   * Adds optional min/max size constraints to the resize behavior.
   * Minimum and maximum sizes are enforced if both `minHeight` and `minWidth` are set.
   *
   * @remarks This method is copied from the @tldraw/editor `resizeBox` method.
   *
   * @param shape The shape being resized.
   * @param info The resize info.
   *
   * @returns A change to apply to the shape, or void.
   */
  onCustomResize: TLOnResizeHandler<any> = (shape: T, info: TLResizeInfo<any>) => {
    if (!this.minHeight || !this.minWidth) {
      return this.superOnResize?.(shape, info);
    }

    const { newPoint, handle, scaleX, scaleY } = info;
    let w = shape.props.w * scaleX;
    let h = shape.props.h * scaleY;

    const offset = new Vec(0, 0);

    if (w > 0) {
      if (w < this.minWidth) {
        switch (handle) {
          case "top_left":
          case "left":
          case "bottom_left": {
            offset.x = w - this.minWidth;
            break;
          }
          case "top":
          case "bottom": {
            offset.x = (w - this.minWidth) / 2;
            break;
          }
          default: {
            offset.x = 0;
          }
        }
        w = this.minWidth;
      }
    } else {
      offset.x = w;
      w = -w;
      if (w < this.minWidth) {
        switch (handle) {
          case "top_left":
          case "left":
          case "bottom_left": {
            offset.x = -w;
            break;
          }
          default: {
            offset.x = -this.minWidth;
          }
        }
        w = this.minWidth;
      }
    }
    if (h > 0) {
      if (h < this.minHeight) {
        switch (handle) {
          case "top_left":
          case "top":
          case "top_right": {
            offset.y = h - this.minHeight;
            break;
          }
          case "right":
          case "left": {
            offset.y = (h - this.minHeight) / 2;
            break;
          }
          default: {
            offset.y = 0;
          }
        }
        h = this.minHeight;
      }
    } else {
      offset.y = h;
      h = -h;
      if (h < this.minHeight) {
        switch (handle) {
          case "top_left":
          case "top":
          case "top_right": {
            offset.y = -h;
            break;
          }
          default: {
            offset.y = -this.minHeight;
          }
        }
        h = this.minHeight;
      }
    }
    const { x, y } = offset.rot(shape.rotation).add(newPoint);

    return {
      x,
      y,
      props: {
        w: Math.min(this.maxWidth, w),
        h: Math.min(this.maxHeight, h),
      },
    };
  };

  // *********************************************
  // Method overrides
  // *********************************************/
  /**
   * @inheritdoc
   */
  indicator(shape: T) {
    const { id } = shape;
    const isEditing = this.editor.getCurrentPageState().editingShapeId === id;
    return <rect width={shape.props.w} height={shape.props.h} stroke={isEditing ? "transparent" : ""} />;
  }
}
