import { Box, BoxProps } from "@mui/material";

export interface ICompanionContentProps {
  children: React.ReactNode;
  sx?: BoxProps["sx"];
}

export const CompanionContent = (props: ICompanionContentProps) => {
  const { children, sx } = props;

  return (
    <Box
      onWheel={(event) => {
        event.stopPropagation();
      }}
      sx={{
        flex: "1 1 auto",
        WebkitOverflowScrolling: "touch",
        overflowY: "auto",
        padding: "20px 24px",
        touchAction: "pan-x pan-y",
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};
