import {
  COMMAND_EXTRACT_QUESTIONS_TEMPLATE,
  COMMAND_GENERATE_BOARD_QUESTIONNAIRE_SUGGESTIONS_TEMPLATE,
  COMMAND_STATUS_RUNNING,
  ICommandRequest,
  ICommandResponseStatus,
} from "@bigpi/cookbook";
import UpdateIcon from "@mui/icons-material/Update";
import { Box, CircularProgress, Stack } from "@mui/material";
import { TLShapeId } from "@tldraw/tldraw";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { CommandContext } from "CommandContext";
import { useCommandExecutor } from "Components/CommandManagers/useCommandExecutor";
import { ICompanionTab } from "BoardComponents/Companion/ICompanionTab";
import { CompanionV2 } from "BoardComponents/Companion/CompanionV2";
import { WorkspaceBoardSuggestions } from "Components/WorkspaceBoardSuggestions/WorkspaceBoardSuggestions";
import { WorkspaceBoardTableOfContents } from "Components/WorkspaceBoardTableOfContents/WorkspaceBoardTableOfContents";
import {
  CommandStatusQuery,
  OnCommandStatusChangedSubscription,
  OnCommandStatusChangedSubscriptionVariables,
  useCommandStatusLazyQuery,
} from "GraphQL/Generated/Apollo";
import { COMMAND_STATUS_SUBSCRIPTION } from "GraphQL/CommandQueue/Subscription";

export enum QuestionnaireCompanionTab {
  TableOfContents = "TableOfContents",
  Suggestions = "Suggestions",
}

export interface IQuestionnaireCompanionProps {
  initialHeight?: number;
  isOpen: boolean;
  onClose: () => void;
  scrollDocumentToBlockId: (blockId: string) => void;
  shapeId: TLShapeId;
  title: string | React.ReactNode;
  workspaceBoardId: string;
}

export function QuestionnaireCompanion(props: IQuestionnaireCompanionProps) {
  const { initialHeight, isOpen, onClose, scrollDocumentToBlockId, shapeId, title, workspaceBoardId } = props;
  const commandExecutor = useCommandExecutor();
  const { t } = useTranslation();
  const [tabs, setTabs] = useState<Array<ICompanionTab>>([]);
  const [isGenerateTocRunning, setIsGenerateTocRunning] = useState<boolean>(false);
  const [isGenerateSuggestionsRunning, setIsGenerateSuggestionsRunning] = useState<boolean>(false);

  // Request IDs for commands
  const generateTocRequestId = `generate-toc-${shapeId}`;
  const generateSuggestionsRequestId = `workspace-board-shape-suggest-${shapeId}`;

  // Generate table of contents
  const generateToc = useCallback(async () => {
    setIsGenerateTocRunning(true);

    const command = {
      ...COMMAND_EXTRACT_QUESTIONS_TEMPLATE,
    };

    // Make sure the shape ID is in the context. It might not be when companion is still open, but shape is no longer selected
    const currentContext = CommandContext.getCommandContext();

    const commandRequest: Partial<ICommandRequest> = {
      commandContext: {
        ...currentContext,
        selection: {
          ...currentContext.selection,
          shapeIds: [shapeId],
        },
      },
      // Create deduplicationId
      deduplicationId: generateTocRequestId,
      requestId: generateTocRequestId,
    };

    await commandExecutor.executeCommand(command, commandRequest);
  }, [commandExecutor, generateTocRequestId, shapeId, workspaceBoardId]);

  const generateSuggestions = useCallback(async () => {
    setIsGenerateSuggestionsRunning(true);

    const command = {
      ...COMMAND_GENERATE_BOARD_QUESTIONNAIRE_SUGGESTIONS_TEMPLATE,
    };

    // Make sure the shape ID is in the context. It might not be when companion is still open, but shape is no longer selected
    const currentContext = CommandContext.getCommandContext();

    // Create the overrides/values that are not set automatically by CommandExecutor
    const commandRequest: Partial<ICommandRequest> = {
      commandContext: {
        ...currentContext,
        selection: {
          ...currentContext.selection,
          shapeIds: [shapeId],
        },
      },
      // Create deduplicationId
      deduplicationId: generateSuggestionsRequestId,
      requestId: generateSuggestionsRequestId,
    };

    await commandExecutor.executeCommand(command, commandRequest);
  }, [commandExecutor, generateSuggestionsRequestId, shapeId, workspaceBoardId]);

  // Lazy query to fetch initial command status
  const [fetchInitialCommandStatusToc, { data: commandStatusDataToc, subscribeToMore: subscribeToMoreToc }] =
    useCommandStatusLazyQuery();
  const [
    fetchInitialCommandStatusSuggestions,
    { data: commandStatusDataSuggestions, subscribeToMore: subscribeToMoreSuggestions },
  ] = useCommandStatusLazyQuery();

  // Dynamically subscribe to command status changes for TOC
  useEffect(() => {
    if (isOpen) {
      fetchInitialCommandStatusToc({
        fetchPolicy: "network-only",
        variables: {
          id: generateTocRequestId,
        },
      });

      const unsubscribeGenerateToc = subscribeToMoreToc<
        OnCommandStatusChangedSubscription,
        OnCommandStatusChangedSubscriptionVariables
      >({
        document: COMMAND_STATUS_SUBSCRIPTION,
        variables: { id: generateTocRequestId },
        updateQuery: (prev, { subscriptionData, variables }) => {
          if (!subscriptionData.data || !variables) {
            return prev;
          }

          // If we don't have any data, it means the command has completed
          const status: ICommandResponseStatus = subscriptionData.data.onCommandStatusChanged?.status
            ? (subscriptionData.data.onCommandStatusChanged?.status as ICommandResponseStatus)
            : "success";
          const newItem: CommandStatusQuery = {
            __typename: "Query",
            commandStatus: {
              __typename: "CommandQueueStatus",
              // We're not using the commandId here, so we can leave it empty if needed
              commandId: prev.commandStatus?.commandId || "",
              requestId: variables.id,
              // Success might not be correct, but works to mark as completed
              status,
            },
          };
          return newItem;
        },
      });

      return () => {
        unsubscribeGenerateToc();
      };
    }
  }, [fetchInitialCommandStatusToc, generateTocRequestId, isOpen, subscribeToMoreToc]);

  // Dynamically subscribe to command status changes for suggestions
  useEffect(() => {
    if (isOpen) {
      fetchInitialCommandStatusSuggestions({
        fetchPolicy: "network-only",
        variables: {
          id: generateSuggestionsRequestId,
        },
      });

      const unsubscribeGenerateSuggestions = subscribeToMoreSuggestions<
        OnCommandStatusChangedSubscription,
        OnCommandStatusChangedSubscriptionVariables
      >({
        document: COMMAND_STATUS_SUBSCRIPTION,
        variables: { id: generateSuggestionsRequestId },
        updateQuery: (prev, { subscriptionData, variables }) => {
          if (!subscriptionData.data || !variables) {
            return prev;
          }

          // If we don't have any data, it means the command has completed
          const status: ICommandResponseStatus = subscriptionData.data.onCommandStatusChanged?.status
            ? (subscriptionData.data.onCommandStatusChanged?.status as ICommandResponseStatus)
            : "success";
          const newItem: CommandStatusQuery = {
            __typename: "Query",
            commandStatus: {
              __typename: "CommandQueueStatus",
              // We're not using the commandId here, so we can leave it empty if needed
              commandId: prev.commandStatus?.commandId || "",
              requestId: variables.id,
              // Success might not be correct, but works to mark as completed
              status,
            },
          };
          return newItem;
        },
      });

      return () => {
        unsubscribeGenerateSuggestions();
      };
    }
  }, [fetchInitialCommandStatusSuggestions, generateSuggestionsRequestId, isOpen, subscribeToMoreSuggestions]);

  // Handle subscription updated for TOC
  useEffect(() => {
    const status = commandStatusDataToc?.commandStatus?.status as ICommandResponseStatus | undefined;

    setIsGenerateTocRunning(status !== undefined && COMMAND_STATUS_RUNNING.includes(status as ICommandResponseStatus));
  }, [commandStatusDataToc]);

  // Handle subscription updated for suggestions
  useEffect(() => {
    const status = commandStatusDataSuggestions?.commandStatus?.status as ICommandResponseStatus | undefined;

    setIsGenerateSuggestionsRunning(status !== undefined && COMMAND_STATUS_RUNNING.includes(status as ICommandResponseStatus));
  }, [commandStatusDataSuggestions]);

  useEffect(() => {
    setTabs([
      {
        tabId: QuestionnaireCompanionTab.TableOfContents,
        title: t(`Components.HtmlDocumentCompanion.Questionnaire.Tabs.${QuestionnaireCompanionTab.TableOfContents}.Title`),
        children: (
          <WorkspaceBoardTableOfContents
            workspaceBoardId={workspaceBoardId}
            shapeId={shapeId}
            scrollDocumentToBlockId={scrollDocumentToBlockId}
          />
        ),
        actions: [
          {
            title: (
              <>
                <Box sx={{ m: 1, position: "relative" }}>
                  <Stack alignItems="center" direction="row" gap={1}>
                    <UpdateIcon />
                    {t(
                      `Components.HtmlDocumentCompanion.Questionnaire.Tabs.${QuestionnaireCompanionTab.TableOfContents}.Actions.Update`,
                    )}
                  </Stack>
                  {isGenerateTocRunning && (
                    <CircularProgress
                      size={24}
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  )}
                </Box>
              </>
            ),
            onAction: generateToc,
            disabled: isGenerateTocRunning,
            value: "generate-toc",
          },
        ],
      },
      {
        tabId: QuestionnaireCompanionTab.Suggestions,
        title: t(`Components.HtmlDocumentCompanion.Questionnaire.Tabs.${QuestionnaireCompanionTab.Suggestions}.Title`),
        children: (
          <WorkspaceBoardSuggestions
            workspaceBoardId={workspaceBoardId}
            shapeId={shapeId}
            scrollDocumentToBlockId={scrollDocumentToBlockId}
          />
        ),
        actions: [
          {
            title: (
              <>
                <Box sx={{ m: 1, position: "relative" }}>
                  <Stack alignItems="center" direction="row" gap={1}>
                    <UpdateIcon sx={{ mr: 1 }} />
                    {t(
                      `Components.HtmlDocumentCompanion.Questionnaire.Tabs.${QuestionnaireCompanionTab.Suggestions}.Actions.Generate`,
                    )}
                  </Stack>
                  {isGenerateSuggestionsRunning && (
                    <CircularProgress
                      size={24}
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  )}
                </Box>
              </>
            ),
            onAction: generateSuggestions,
            disabled: isGenerateSuggestionsRunning,
            value: "generate-answers",
          },
        ],
        sx: {
          p: 0,
        },
      },
    ]);
  }, [
    generateToc,
    generateSuggestions,
    isGenerateSuggestionsRunning,
    isGenerateTocRunning,
    scrollDocumentToBlockId,
    shapeId,
    t,
    workspaceBoardId,
  ]);

  return (
    <CompanionV2
      initialHeight={initialHeight}
      isOpen={isOpen}
      onClose={onClose}
      shapeId={shapeId}
      tabs={tabs}
      title={title}
      slotProps={{
        CompanionContent: {
          sx: { padding: "0px 24px" },
        },
        CompanionTitle: {
          slotProps: {
            Box: {
              sx: {
                backgroundColor: "var(--astra-color-pacific)",
              },
            },
          },
        },
      }}
    />
  );
}
