import { ContentTypes } from "@bigpi/cookbook";
import { InsertPhotoOutlined } from "@mui/icons-material";
import { Input } from "@mui/material";
import type { Editor } from "@tiptap/react";
import React, { ChangeEvent, useRef } from "react";
import { useTranslation } from "react-i18next";

import type { IEditorConfig } from "../../Editor/index.js";
import { ToolbarItemBase } from "../../Toolbars/ToolbarItemBase.js";
import { ToolbarButton } from "../../Toolbars/ToolbarButton.js";
import { getEditorToolbarButtonTooltip } from "../../Utils/ToolbarItemUtils.js";

const ITEM_NAME = "image";
const ACCEPTED_MIME_TYPES = `${ContentTypes.APNG},${ContentTypes.AVIF},${ContentTypes.GIF},${ContentTypes.JPEG},${ContentTypes.PNG},${ContentTypes.SVG},${ContentTypes.WEBP}`;

/**
 * A toolbar button that inserts an image.
 */
export class InsertImageButtonItem extends ToolbarItemBase {
  // *********************************************
  // Public properties
  // *********************************************/
  /**
   * @inheritdoc
   */
  readonly name = ITEM_NAME;

  // *********************************************
  // Public methods
  // *********************************************/
  /**
   * @inheritdoc
   */
  create(editor: Editor, config: IEditorConfig) {
    const { toolbarOptions } = config;
    return (
      <InsertImageButton
        key={ITEM_NAME}
        setImage={(options: { src: string; alt?: string | undefined; title?: string | undefined; style?: string | undefined }) =>
          editor.chain().focus().setImage(options).run()
        }
        uploadImage={config.extensionOptions?.Image?.uploadImage || ((_image: File) => Promise.resolve(""))}
        toolbarOptions={toolbarOptions}
      />
    );
  }
}

/**
 * Props for InsertImageButton.
 */
export interface InsertImageButtonProps {
  setImage: (options: { src: string; alt?: string | undefined; title?: string | undefined; style?: string | undefined }) => void;
  uploadImage: (image: File) => Promise<string>;
  toolbarOptions?: IEditorConfig["toolbarOptions"];
}

/**
 * A toolbar button for the (insert) `image` command.
 */
export const InsertImageButton = (props: InsertImageButtonProps) => {
  const { setImage, uploadImage, toolbarOptions } = props;
  const tooltipPlacement = toolbarOptions?.tooltipPlacement;

  const { t } = useTranslation();

  const fileInputRef = useRef<HTMLInputElement>();

  const handleFileSelection = async (e: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { validity, files },
    } = e;

    if (validity.valid && files && files[0]) {
      const src = await uploadImage(files[0]);
      setImage({ src: src, style: "max-width:100%" });

      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
  };

  return (
    <>
      <ToolbarButton
        Icon={InsertPhotoOutlined}
        tooltip={getEditorToolbarButtonTooltip(t, ITEM_NAME)}
        onClick={() => {
          fileInputRef.current?.click();
        }}
        tooltipPlacement={tooltipPlacement}
      ></ToolbarButton>

      <Input
        type="file"
        id="image-file"
        name="image-file"
        sx={{ mb: 2 }}
        disableUnderline
        inputRef={fileInputRef}
        inputProps={{
          accept: ACCEPTED_MIME_TYPES,
        }}
        onChange={handleFileSelection}
        style={{
          display: "none",
        }}
      />
    </>
  );
};
