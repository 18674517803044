import { DocumentType } from "@bigpi/cookbook";
import { TLShapeId } from "@tldraw/tldraw";

import { QuestionnaireCompanion } from "./QuestionnaireCompanion";

export interface IHtmlDocumentShapeCompanion {
  documentType: DocumentType;
  initialHeight?: number;
  isOpen: boolean;
  onClose: () => void;
  scrollDocumentToBlockId: (blockId: string) => void;
  shapeId: TLShapeId;
  title: string | React.ReactNode;
  workspaceBoardId: string;
}

export function HtmlDocumentShapeCompanion(props: IHtmlDocumentShapeCompanion) {
  const { documentType, initialHeight, isOpen, onClose, scrollDocumentToBlockId, shapeId, title, workspaceBoardId } = props;

  switch (documentType) {
    case DocumentType.Questionnaire:
      return (
        <QuestionnaireCompanion
          initialHeight={initialHeight}
          isOpen={isOpen}
          onClose={onClose}
          scrollDocumentToBlockId={scrollDocumentToBlockId}
          shapeId={shapeId}
          title={title}
          workspaceBoardId={workspaceBoardId}
        />
      );
    default:
      return null;
  }
}
