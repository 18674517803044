import short from "short-uuid";

/**
 * Generates a short UUID.
 *
 * @returns {string} The short UUID.
 */
export function shortUuid() {
  return short(short.constants.uuid25Base36).generate();
}
