import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import type { Editor } from "@tiptap/react";
import { Menu, MenuItem } from "@mui/material";

import { Optional } from "@bigpi/cookbook";

import { ActionMenuItemBase } from "../../ActionMenus/ActionMenuItemBase.js";
import { IEditorConfig } from "../../Editor/IEditorConfig.js";
import { ToolbarButton } from "../../Toolbars/ToolbarButton.js";
import { ImageStyleWrapAlignLeftIcon } from "./Icons/ImageStyleWrapAlignLeft.js";
import { ImageStyleWrapAlignRightIcon } from "./Icons/ImageStyleWrapAlignRight.js";
import { ImageStyleAlignLeftIcon } from "./Icons/ImageStyleAlignLeft.js";
import { ImageStyleAlignCenterIcon } from "./Icons/ImageStyleAlignCenter.js";
import { ImageStyleAlignRightIcon } from "./Icons/ImageStyleAlignRight.js";
import { ImageStyleInlineIcon } from "./Icons/ImageStyleInline.js";
import { ImageActionsPlugin, ImageActionsPluginProps } from "./ImageActionsPlugin.js";
import { ImageAlignmentType } from "./ImageExtension.js";

const ITEM_NAME = "imageActions";

/**
 * An action menu for images.
 */
export class ImageActionsMenuItem extends ActionMenuItemBase {
  // *********************************************
  // Public properties
  // *********************************************/
  /**
   * @inheritdoc
   */
  readonly name = ITEM_NAME;

  // *********************************************
  // Public methods
  // *********************************************/
  /**
   * @inheritdoc
   */
  create(editor: Editor, config: IEditorConfig) {
    return <ImageActionsMenu key={ITEM_NAME} editor={editor} />;
  }
}

/**
 * Props map for image alignment menu buttons.
 */
const ImageAlignMenuButtonProps: Record<
  ImageAlignmentType,
  {
    icon: React.FunctionComponent;
    tooltip: string;
  }
> = {
  [ImageAlignmentType.ALIGN_LEFT]: {
    icon: ImageStyleWrapAlignLeftIcon,
    tooltip: `Editor.ImageAlign.WrapText.Alignments.Left`,
  },
  [ImageAlignmentType.ALIGN_RIGHT]: {
    icon: ImageStyleWrapAlignRightIcon,
    tooltip: `Editor.ImageAlign.WrapText.Alignments.Right`,
  },
  [ImageAlignmentType.BLOCK_ALIGN_LEFT]: {
    icon: ImageStyleAlignLeftIcon,
    tooltip: `Editor.ImageAlign.BreakText.Alignments.Left`,
  },
  [ImageAlignmentType.BLOCK_ALIGN_CENTER]: {
    icon: ImageStyleAlignCenterIcon,
    tooltip: `Editor.ImageAlign.BreakText.Alignments.Center`,
  },
  [ImageAlignmentType.BLOCK_ALIGN_RIGHT]: {
    icon: ImageStyleAlignRightIcon,
    tooltip: `Editor.ImageAlign.BreakText.Alignments.Right`,
  },
  [ImageAlignmentType.SIDE]: {
    icon: ImageStyleInlineIcon,
    tooltip: `Editor.ImageAlign.Inline.Tooltip`,
  },
};

/**
 * List of image alignment types for break-text.
 */
const ApplicableImageAlignBreakTextTypes: Array<ImageAlignmentType> = [
  ImageAlignmentType.BLOCK_ALIGN_LEFT,
  ImageAlignmentType.BLOCK_ALIGN_CENTER,
  ImageAlignmentType.BLOCK_ALIGN_RIGHT,
];

/**
 * Props for ImageAlignBreakTextButton.
 */
interface ImageAlignBreakTextButtonProps {
  openMenu: (event: React.MouseEvent<HTMLElement>) => void;
  closeMenu: () => void;
  anchorEl: HTMLElement | null;
  setImageAlign: (alignment: ImageAlignmentType) => void;
  selectedImageAlignType: ImageAlignmentType | null;
}

/**
 * A toolbar button for break-text image alignment.
 */
const ImageAlignBreakTextButton = (props: ImageAlignBreakTextButtonProps) => {
  const { openMenu, closeMenu, anchorEl, setImageAlign, selectedImageAlignType } = props;

  const { t } = useTranslation();

  const isBreakTextTypeSelected =
    selectedImageAlignType !== null && ApplicableImageAlignBreakTextTypes.includes(selectedImageAlignType);
  const menuButtonProps = !isBreakTextTypeSelected
    ? ImageAlignMenuButtonProps[ImageAlignmentType.BLOCK_ALIGN_LEFT]
    : ImageAlignMenuButtonProps[selectedImageAlignType];

  return (
    <>
      <ToolbarButton
        Icon={menuButtonProps.icon}
        tooltip={`${t(`Editor.ImageAlign.BreakText.Tooltip`)}: ${t(menuButtonProps.tooltip)}`}
        onClick={openMenu}
        isActive={isBreakTextTypeSelected}
      />

      <Menu
        anchorEl={anchorEl}
        id="image-align-break-text-menu"
        open={!!anchorEl}
        onClose={closeMenu}
        onClick={closeMenu}
        PaperProps={{
          elevation: 0,
          sx: {
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
          },
        }}
        MenuListProps={{
          style: { display: "inline-flex", padding: 0 },
        }}
      >
        {ApplicableImageAlignBreakTextTypes.map((alignment) => {
          return (
            <MenuItem
              key={`image-align-break-text-${alignment}-menu-button`}
              onClick={() => {
                setImageAlign(alignment);
              }}
              sx={{ padding: "6px" }}
              disableRipple
            >
              <ToolbarButton
                Icon={ImageAlignMenuButtonProps[alignment].icon}
                tooltip={t(ImageAlignMenuButtonProps[alignment].tooltip)}
                isActive={selectedImageAlignType === alignment}
              />
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

/**
 * List of image alignment types for wrap-text.
 */
const ApplicableImageAlignWrapTextTypes: Array<ImageAlignmentType> = [
  ImageAlignmentType.ALIGN_LEFT,
  ImageAlignmentType.ALIGN_RIGHT,
];

/**
 * Props for ImageAlignWrapTextButton.
 */
interface ImageAlignWrapTextButtonProps {
  openMenu: (event: React.MouseEvent<HTMLElement>) => void;
  closeMenu: () => void;
  anchorEl: HTMLElement | null;
  setImageAlign: (alignment: ImageAlignmentType) => void;
  selectedImageAlignType: ImageAlignmentType | null;
}

/**
 * A toolbar button for wrap-text image alignment.
 */
const ImageAlignWrapTextButton = (props: ImageAlignWrapTextButtonProps) => {
  const { openMenu, closeMenu, anchorEl, setImageAlign, selectedImageAlignType } = props;

  const { t } = useTranslation();

  const isWrapTextTypeSelected =
    selectedImageAlignType !== null && ApplicableImageAlignWrapTextTypes.includes(selectedImageAlignType);
  const menuButtonProps = !isWrapTextTypeSelected
    ? ImageAlignMenuButtonProps[ImageAlignmentType.ALIGN_LEFT]
    : ImageAlignMenuButtonProps[selectedImageAlignType];

  return (
    <>
      <ToolbarButton
        Icon={menuButtonProps.icon}
        tooltip={`${t(`Editor.ImageAlign.WrapText.Tooltip`)}: ${t(menuButtonProps.tooltip)}`}
        onClick={openMenu}
        isActive={isWrapTextTypeSelected}
      />

      <Menu
        anchorEl={anchorEl}
        id="image-align-wrap-text-menu"
        open={!!anchorEl}
        onClose={closeMenu}
        onClick={closeMenu}
        PaperProps={{
          elevation: 0,
          sx: {
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
          },
        }}
        MenuListProps={{
          style: { display: "inline-flex", padding: 0 },
        }}
      >
        {ApplicableImageAlignWrapTextTypes.map((alignment) => {
          return (
            <MenuItem
              key={`image-align-wrap-text-${alignment}-menu-button`}
              onClick={() => {
                setImageAlign(alignment);
              }}
              sx={{ padding: "6px" }}
              disableRipple
            >
              <ToolbarButton
                Icon={ImageAlignMenuButtonProps[alignment].icon}
                tooltip={t(ImageAlignMenuButtonProps[alignment].tooltip)}
                isActive={selectedImageAlignType === alignment}
              />
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

/**
 * Props for ImageAlignInlineButton.
 */
interface ImageAlignInlineButtonProps {
  setImageAlign: (alignment: ImageAlignmentType) => void;
  selectedImageAlignType: ImageAlignmentType | null;
}

/**
 * A toolbar button for inline image alignment.
 */
const ImageAlignInlineButton = (props: ImageAlignInlineButtonProps) => {
  const { setImageAlign, selectedImageAlignType } = props;

  const { t } = useTranslation();

  const isInlineTypeSelected = selectedImageAlignType === ImageAlignmentType.SIDE;
  const menuButtonProps = ImageAlignMenuButtonProps[ImageAlignmentType.SIDE];

  return (
    <ToolbarButton
      Icon={menuButtonProps.icon}
      tooltip={t(menuButtonProps.tooltip)}
      onClick={() => setImageAlign(ImageAlignmentType.SIDE)}
      isActive={isInlineTypeSelected}
    />
  );
};

/**
 * Props for ImageActionsMenu.
 */
export type ImageActionsProps = Omit<Optional<ImageActionsPluginProps, "pluginKey">, "element"> & {
  className?: string;
  updateDelay?: number;
};

/**
 * A React component that renders a menu for image actions.
 */
export const ImageActionsMenu = (props: ImageActionsProps) => {
  const { editor, pluginKey = "imageActions", tippyOptions = {}, updateDelay, shouldShow = null, className } = props;

  const [element, setElement] = useState<HTMLDivElement | null>(null);
  const [breakTextAnchorEl, setBreakTextAnchorEl] = useState<null | HTMLElement>(null);
  const [wrapTextAnchorEl, setWrapTextAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedImageAlignType, setSelectedImageAlignType] = useState<ImageAlignmentType>(ImageAlignmentType.BLOCK_ALIGN_CENTER);

  const setImageAlign = (alignment: ImageAlignmentType) => editor.chain().focus().setImageAlign(alignment).run();
  const isActive = (imageAlign: ImageAlignmentType) => editor.isActive("image", { imageAlign });

  if (isActive(ImageAlignmentType.BLOCK_ALIGN_LEFT) && selectedImageAlignType !== ImageAlignmentType.BLOCK_ALIGN_LEFT) {
    setSelectedImageAlignType(ImageAlignmentType.BLOCK_ALIGN_LEFT);
  } else if (
    isActive(ImageAlignmentType.BLOCK_ALIGN_CENTER) &&
    selectedImageAlignType !== ImageAlignmentType.BLOCK_ALIGN_CENTER
  ) {
    setSelectedImageAlignType(ImageAlignmentType.BLOCK_ALIGN_CENTER);
  } else if (isActive(ImageAlignmentType.BLOCK_ALIGN_RIGHT) && selectedImageAlignType !== ImageAlignmentType.BLOCK_ALIGN_RIGHT) {
    setSelectedImageAlignType(ImageAlignmentType.BLOCK_ALIGN_RIGHT);
  } else if (isActive(ImageAlignmentType.ALIGN_LEFT) && selectedImageAlignType !== ImageAlignmentType.ALIGN_LEFT) {
    setSelectedImageAlignType(ImageAlignmentType.ALIGN_LEFT);
  } else if (isActive(ImageAlignmentType.ALIGN_RIGHT) && selectedImageAlignType !== ImageAlignmentType.ALIGN_RIGHT) {
    setSelectedImageAlignType(ImageAlignmentType.ALIGN_RIGHT);
  } else if (isActive(ImageAlignmentType.SIDE) && selectedImageAlignType !== ImageAlignmentType.SIDE) {
    setSelectedImageAlignType(ImageAlignmentType.SIDE);
  }

  useEffect(() => {
    if (!element) {
      return;
    }

    if (editor.isDestroyed) {
      return;
    }

    const plugin = ImageActionsPlugin({
      updateDelay,
      editor,
      element,
      pluginKey,
      shouldShow,
      tippyOptions,
    });

    editor.registerPlugin(plugin);
    return () => {
      editor.unregisterPlugin(pluginKey);
    };
    // Register plugin when the editor is ready or the element changes.
    // We don't want to consider other props changes as they are not
    // coming from a react state or ref, which makes the menu rendering
    // unstable and non-ending process.
  }, [editor, element]);

  const openBreakTextMenu = (event: React.MouseEvent<HTMLElement>) => {
    closeWrapTextMenu();
    setBreakTextAnchorEl(event.currentTarget);
  };

  const closeBreakTextMenu = () => {
    setBreakTextAnchorEl(null);
  };

  const openWrapTextMenu = (event: React.MouseEvent<HTMLElement>) => {
    closeBreakTextMenu();
    setWrapTextAnchorEl(event.currentTarget);
  };

  const closeWrapTextMenu = () => {
    setWrapTextAnchorEl(null);
  };

  const handleInlineImageAlignment = (alignment: ImageAlignmentType) => {
    closeWrapTextMenu();
    closeBreakTextMenu();

    setImageAlign(alignment);
  };

  return (
    <div ref={setElement} className={className} style={{ visibility: "hidden" }}>
      <ImageAlignInlineButton setImageAlign={handleInlineImageAlignment} selectedImageAlignType={selectedImageAlignType} />

      <ImageAlignBreakTextButton
        openMenu={openBreakTextMenu}
        closeMenu={closeBreakTextMenu}
        anchorEl={breakTextAnchorEl}
        setImageAlign={setImageAlign}
        selectedImageAlignType={selectedImageAlignType}
      />

      <ImageAlignWrapTextButton
        openMenu={openWrapTextMenu}
        closeMenu={closeWrapTextMenu}
        anchorEl={wrapTextAnchorEl}
        setImageAlign={setImageAlign}
        selectedImageAlignType={selectedImageAlignType}
      />
    </div>
  );
};
