import { getNodeType, RawCommands } from "@tiptap/core";

import { ALLOWED_IMAGE_ALIGNMENTS, ALLOWED_IMAGE_INLINE_ALIGNMENTS, Image, ImageAlignmentType } from "./ImageExtension.js";
import { Figure } from "./FigureExtension.js";

export const setImageAlign: RawCommands["setImageAlign"] =
  (alignment: ImageAlignmentType) =>
  ({ state, editor, tr, dispatch }) => {
    const { selection } = state;
    const { $from } = selection;

    if (!ALLOWED_IMAGE_ALIGNMENTS.includes(alignment)) {
      return false;
    }

    let imageNode = $from.nodeAfter;
    if (!imageNode || imageNode.type.name !== Image.name) {
      return false;
    }

    const parent = $from.parent;
    const hasFigureAsParent = parent.type.name === Figure.name;

    if (ALLOWED_IMAGE_INLINE_ALIGNMENTS.includes(alignment)) {
      if (hasFigureAsParent) {
        // Remove figure node and keep the image node
        tr = tr.replaceWith($from.before(), $from.after(), imageNode);
      }

      tr = tr.setNodeAttribute($from.pos, "imageAlign", alignment);
      if (dispatch) {
        dispatch(tr);
      }
      return true;
    }

    if (!hasFigureAsParent) {
      // Get the start and end position of the image node in the given range
      let startPos = null;
      let endPos = null;
      tr.doc.nodesBetween($from.before(), $from.after(), (node, pos) => {
        if (node.type.name === Image.name && node.attrs.id === imageNode!.attrs.id) {
          startPos = pos;
          endPos = pos + node.nodeSize;

          imageNode = node;
        }
      });

      if (startPos === null || endPos === null) {
        return false;
      }

      // Delete the image node
      tr = tr.deleteRange(startPos, endPos);

      if (parent.childCount > 1) {
        // Split the parent node in 2 parts - first part will be from position $anchor.before()
        // to startPos and second part will be from endPos to $anchor.after()
        tr = tr.split(startPos);
      }

      // Add a new figure node with the image node
      const figureNodeType = getNodeType(Figure.name, editor.schema);
      const imageNodeType = getNodeType(Image.name, editor.schema);
      const figure = figureNodeType.create(
        {},
        imageNodeType.create({ ...imageNode.attrs, imageAlign: alignment }, imageNode.content),
      );

      // Insert the figure node at the position startPos
      tr = tr.insert(startPos, figure);

      if (dispatch) {
        dispatch(tr);
      }

      return true;
    }

    // Update the imageAlign attribute of the image node
    tr = tr.setNodeAttribute($from.pos, "imageAlign", alignment);

    if (dispatch) {
      dispatch(tr);
    }

    return true;
  };
