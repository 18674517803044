import { RawCommands } from "@tiptap/core";

import { Figure } from "./FigureExtension.js";
import { Image, ImageAlignmentType } from "./ImageExtension.js";

export const setImage: RawCommands["setImage"] =
  (options) =>
  ({ commands, tr }) => {
    return commands.insertContentAt(
      { from: tr.selection.from, to: tr.selection.to },
      {
        type: Figure.name,
        attrs: {},
        content: [
          {
            type: Image.name,
            attrs: { ...options, imageAlign: ImageAlignmentType.BLOCK_ALIGN_CENTER },
          },
        ],
      },
    );
  };
