import { gql } from "@apollo/client";
import { CreatedAtDescSortOption, UpdatedAtDescSortOption } from "@bigpi/cookbook";
import { SearchOutlined, Close } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  InputAdornment,
  OutlinedInput,
  IconButton,
  Stack,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDebounce } from "use-debounce";

import { useFilesAndDocumentsQuery } from "GraphQL/Generated/Apollo";
import { SourceList } from "./SourceList";
import { useAddToBoard } from "./useAddToBoard";

// *********************************************
// GraphQL/Interfaces
// *********************************************/
interface IAddToBoardDialogProps {
  workspaceId: string;
}

gql`
  query FilesAndDocuments(
    $workspaceId: ID!
    $offset: Int
    $limit: Int
    $fileBundleViewOrderBy: FileBundleViewOrderBy
    $workspaceFileOrderBy: WorkspaceFileOrderBy
    $documentOrderBy: DocumentOrderBy
    $fileBundleViewFilters: FileBundleViewFilters
    $workspaceFileFilters: WorkspaceFileFilters
    $documentFilters: DocumentFilters
  ) {
    fileBundleView(offset: $offset, limit: $limit, orderBy: $fileBundleViewOrderBy, filters: $fileBundleViewFilters) {
      createdAt
      id
      mimeType
      name
      type
    }
    documents(offset: $offset, limit: $limit, orderBy: $documentOrderBy, filters: $documentFilters) {
      id
      name
    }
    workspaceFiles(
      workspaceId: $workspaceId
      offset: $offset
      limit: $limit
      orderBy: $workspaceFileOrderBy
      filters: $workspaceFileFilters
    ) {
      createdAt
      id
      mimeType
      name
    }
  }
`;

const FILE_BUNDLE_VIEW_ORDER_BY = { [CreatedAtDescSortOption.field]: CreatedAtDescSortOption.sortOrder };
const WORKSPACE_FILE_ORDER_BY = { [CreatedAtDescSortOption.field]: CreatedAtDescSortOption.sortOrder };
const DOCUMENT_ORDER_BY = { [UpdatedAtDescSortOption.field]: UpdatedAtDescSortOption.sortOrder };

const CONTENT_SEARCH_DEBOUNCE_DELAY = 400;

// *********************************************
// Component
// *********************************************/
export function AddToBoardDialog(props: IAddToBoardDialogProps) {
  const { workspaceId } = props;
  // State
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [searchValue] = useDebounce(searchTerm, CONTENT_SEARCH_DEBOUNCE_DELAY);

  const { isOpen, setIsOpen } = useAddToBoard();
  const { t } = useTranslation();

  const { data, loading, error } = useFilesAndDocumentsQuery({
    variables: {
      workspaceId,
      limit: searchTerm ? 100 : 5,
      fileBundleViewFilters: {
        searchTerm: searchValue,
      },
      workspaceFileFilters: {
        searchTerm: searchValue,
      },
      documentFilters: {
        searchTerm: searchValue,
      },
      documentOrderBy: DOCUMENT_ORDER_BY,
      fileBundleViewOrderBy: FILE_BUNDLE_VIEW_ORDER_BY,
      workspaceFileOrderBy: WORKSPACE_FILE_ORDER_BY,
    },
    // Always get the latest list of files and documents
    fetchPolicy: "network-only",
    skip: !isOpen,
  });

  useEffect(() => {
    setSearchTerm("");
  }, [isOpen]);

  return (
    <Dialog open={isOpen} fullWidth maxWidth="md">
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Box>{t("Components.AddToBoard.Title")}</Box>
          <IconButton onClick={() => setIsOpen(false)}>
            <Close />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ height: "600px" }}>
        <Box
          sx={{
            backgroundColor: "#fff",
            display: "flex",
            justifyContent: "center",
            pb: 3,
            position: "sticky",
            top: "0px",
            zIndex: 2,
          }}
        >
          <FormControl fullWidth>
            <OutlinedInput
              startAdornment={
                <InputAdornment position="start">
                  <SearchOutlined />
                </InputAdornment>
              }
              placeholder={t("Components.AddToBoard.SearchPlaceholder")}
              onChange={(e) => setSearchTerm(e.target.value)}
              slotProps={{
                input: { style: { paddingTop: 8, paddingBottom: 8 } },
              }}
            />
          </FormControl>
        </Box>
        {data && <SourceList data={data} onClose={() => setIsOpen(false)} />}
      </DialogContent>
    </Dialog>
  );
}
