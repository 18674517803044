import { DocumentType, documentTypeToShapeTag, IPlugIn, PlugInManager, ShapeTag, ShapeTagProps } from "@bigpi/cookbook";
import {
  documentHtmlToYDoc,
  Editor,
  EditorEvents,
  PageMargin,
  PageOrientation,
  PageSize,
  Search,
  SearchStorage,
} from "@bigpi/editor-tiptap";
import {
  IHtmlDocumentShape,
  getHtmlDocumentShapeDefaultProps,
  htmlDocumentShapeMigrations,
  htmlDocumentShapeProps,
} from "@bigpi/tl-schema";
import { Box, CircularProgress, Fade, Typography } from "@mui/material";
import { atom, BaseBoxShapeTool, createShapeId, HTMLContainer, Migrations, useReadonly, useValue } from "@tldraw/tldraw";
import { useCallback, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDebouncedCallback } from "use-debounce";
import { v4 as uuidV4 } from "uuid";
import * as Y from "yjs";

import { BoxBaseUtil } from "BoardComponents/BaseShapes/BoxBaseUtil";
import { IHtmlDocumentShapeExternalData, useBoardDatastore } from "BoardComponents/BoardDatastore";
import { useBoardProvider } from "BoardComponents/BoardProviderContext/useBoardProvider";
import { useShapeLifecycleEventEmitter } from "BoardComponents/ShapeLifecycleManager/useShapeLifecycleEventEmitter";
import { useIsInteracting } from "BoardComponents/Tools";
import { useShapeEvents } from "BoardComponents/useShapeEvents";
import { createShapesAtEmptyPoint } from "BoardComponents/Utils/CreateShapeUtils";
import { DataUtils } from "Utils/DataUtils";
import { HtmlDocumentShapeCompanion } from "./HtmlDocumentShapeCompanion";
import { HtmlDocumentShapeEditor } from "./HtmlDocumentShapeEditor";
import { useIsDocumentCompanionEnabled } from "./useIsDocumentCompanionEnabled";

import "./HtmlDocumentShape.css";

// *********************************************
// Private constants
// *********************************************/
/**
 * The amount of time to debounce shape updates.
 */
const SHAPE_UPDATE_DEBOUNCE_TIME = 200;

/**
 * The breakpoint at which the page margins change from normal to narrow.
 */
const MARGIN_BREAKPOINT = 600;

// *********************************************
// Shape Util
// *********************************************/
/**
 * Generator for HtmlDocument shapes.
 */
export class HtmlDocumentUtil extends BoxBaseUtil<IHtmlDocumentShape> {
  // *********************************************
  // Static fields
  // *********************************************/
  static type = "htmlDocument";

  static props = htmlDocumentShapeProps;

  static migrations: Migrations = htmlDocumentShapeMigrations;

  // *********************************************
  // Protected fields
  // *********************************************/
  /**
   * The minimum height of the shape.
   */
  protected minHeight = 192;

  /**
   * The minimum width of the shape.
   */
  protected minWidth = 192;

  /**
   * The maximum height of the shape.
   */
  protected maxHeight = Infinity;

  /**
   * The maximum width of the shape.
   */
  protected maxWidth = Infinity;

  // *********************************************
  // Override methods, event handlers
  // *********************************************/
  /**
   * @inheritdoc
   */
  onEditEnd = (shape: IHtmlDocumentShape) => {
    const {
      id,
      type,
      props: { html },
    } = shape;
    if (html !== shape.props.html) {
      this.editor.updateShapes([
        {
          id,
          type,
          props: {
            html,
          },
        },
      ]);
    }
  };

  // *********************************************
  // Override methods
  // *********************************************/
  /**
   * @inheritdoc
   */
  override canScroll = (shape: IHtmlDocumentShape) => {
    return shape.props.canScroll;
  };

  /**
   * @inheritdoc
   */
  override canResize = (shape: IHtmlDocumentShape) => true;

  /**
   * @inheritdoc
   */
  override canBind = (shape: IHtmlDocumentShape) => true;

  /**
   * @inheritdoc
   */
  override canEdit = (shape: IHtmlDocumentShape) => {
    return !shape.props.asyncUpdateLock;
  };

  /**
   * @inheritdoc
   */
  override getDefaultProps(): IHtmlDocumentShape["props"] {
    return getHtmlDocumentShapeDefaultProps();
  }

  /**
   * @inheritdoc
   */
  override isAspectRatioLocked = (shape: IHtmlDocumentShape) => false;

  /**
   * @inheritdoc
   */
  override component(shape: IHtmlDocumentShape) {
    const { id, type, props, meta } = shape;
    const tldrawEditor = this.editor;
    const boardProvider = useBoardProvider();
    const shapeLifecycleEventEmitter = useShapeLifecycleEventEmitter();
    const [authError, setAuthError] = useState<string | null>(null);
    const contentRef = useRef<HTMLDivElement | null>(null);
    const editedTextRef = useRef(props.html);
    const isReadOnly = useReadonly();
    const isEditing = useValue("isEditing", () => tldrawEditor.getCurrentPageState().editingShapeId === id, [tldrawEditor, id]);
    const isInteracting = useIsInteracting(shape.id);
    const isSelected = useValue("isSelected", () => tldrawEditor.getCurrentPageState().selectedShapeIds.includes(id), [
      tldrawEditor,
      id,
    ]);
    const selectedShapeIds = useValue("selectedShapeIds", () => tldrawEditor.getCurrentPageState().selectedShapeIds, [
      tldrawEditor,
    ]);
    const [editorCurrentSelection, setEditorCurrentSelection] = useState<[number, number]>([1, 1]);
    const pageName = useValue("pageName", () => tldrawEditor.getCurrentPage().name, [tldrawEditor]);
    const { t } = useTranslation();

    // Make sure we have an entry in the datastore for this shape
    const datastore = useBoardDatastore();
    const shapeData = datastore.state.get()[id]?.get() as IHtmlDocumentShapeExternalData | undefined;
    const activeBookmarkIds = shapeData?.activeBookmarkIds?.get() || DataUtils.getImmutableEmptyArray<string>();

    const isDocumentCompanionEnabled = useIsDocumentCompanionEnabled();
    const [isCompanionOpen, setIsCompanionOpen] = useState(false);

    // Get IDs to use with Runner for collaborative editing
    const workspaceBoardId = (tldrawEditor.getInstanceState().meta.workspaceBoardId as string) || "";
    const documentId = id;
    const pageSize = PageSize.Custom;
    const pageOrientation = PageOrientation.Portrait;

    useEffect(() => {
      if (isDocumentCompanionEnabled && (isEditing || isInteracting || isSelected) && selectedShapeIds.length === 1) {
        // Show companion when shape is selected, edited, or interacted with
        setIsCompanionOpen(true);
      } else if (selectedShapeIds.length > 0) {
        // Only close the companion if another shape has been selected
        setIsCompanionOpen(false);
      }
    }, [isDocumentCompanionEnabled, isEditing, isInteracting, isSelected, selectedShapeIds]);

    let pageMargin = PageMargin.Normal;
    if (props.w < MARGIN_BREAKPOINT || props.h < MARGIN_BREAKPOINT) {
      pageMargin = PageMargin.Narrow;
    }

    // Create the datastore state with our values
    useEffect(() => {
      datastore.setShapeData(shape.id, {
        // Set the full data in the datastore
        editor: atom(`board.datastore.${shape.id}.editor`, null),
        activeBookmarkIds: atom(`board.datastore.${shape.id}.activeBookmarkIds`, []),
        scrollBookmarkIntoView: atom(`board.datastore.${shape.id}.scrollBookmarkIntoView`, () => false),
        selectedBlockIds: atom(`board.datastore.${shape.id}.selectedBlockIds`, null),
        highlightSearchResults: atom(`board.datastore.${shape.id}.highlightSearchResults`, () => 0),
        highlightActiveSearchResultByIndex: atom(`board.datastore.${shape.id}.highlightActiveSearchResultByIndex`, () => false),
        clearSearchHighlights: atom(`board.datastore.${shape.id}.clearSearchHighlights`, () => {}),
        type: "htmlDocument",
      });
    }, []);

    const onContentResize = useCallback(() => {
      let canScroll = true;

      // Check if our content fits. If it does, then return false so canvas moves for scroll events
      if (contentRef.current) {
        const { clientHeight, scrollHeight } = contentRef.current;

        // From MDN: "scrollTop is a non-rounded number, while scrollHeight and clientHeight are rounded" so need an extra pixel
        if (clientHeight >= scrollHeight) {
          canScroll = false;
        }
      }

      // Only update if the value has changed
      if (props.canScroll !== canScroll) {
        tldrawEditor.updateShapes([{ id, type, props: { canScroll } }]);
      }
    }, [tldrawEditor, id, type]);

    const onSelectionUpdate = useCallback(
      (args: EditorEvents["selectionUpdate"]) => {
        const { editor } = args;
        if (editor.isEditable) {
          const { from, to } = editor.state.selection;
          let selectedBlockIds: Array<string> | null = null;
          // Consider the selection change only if the editor is focused
          // - When the focus is shifted to companion, the selection change should not be considered
          if (editor.isFocused) {
            editor.state.doc.nodesBetween(from, to, (node) => {
              const blockId = node.attrs.id;
              if (blockId) {
                if (Array.isArray(selectedBlockIds)) {
                  selectedBlockIds.push(blockId);
                } else {
                  selectedBlockIds = [blockId];
                }
              }
            });

            // Update the selected block IDs in the datastore
            shapeData?.selectedBlockIds.set(selectedBlockIds);

            setEditorCurrentSelection([from, to]);
          }
        } else {
          // Clear the selected block IDs in the datastore
          shapeData?.selectedBlockIds.set(null);
        }
      },
      [shapeData, id],
    );

    const onUpdate = useDebouncedCallback((args: EditorEvents["update"]) => {
      onContentResize();
      if (!args.editor.isDestroyed) {
        const html = args.editor.getHTML();

        if (editedTextRef.current !== html) {
          // debounce the update to avoid too many updates
          editedTextRef.current = html;
          tldrawEditor.updateShapes([{ id, type, props: { html } }]);
        }
      }
    }, SHAPE_UPDATE_DEBOUNCE_TIME);

    useEffect(() => {
      // Make sure we force an update to the shape HTML on unmount
      return () => {
        onUpdate.flush();
      };
    }, [onUpdate]);

    const onAddIFrameToBoard = useCallback((url: string) => {
      createShapesAtEmptyPoint(
        tldrawEditor,
        [
          {
            id: createShapeId(uuidV4()),
            type: "inlineFrame",
            props: {
              sourceUrl: url,
            },
          },
        ],
        shape,
        true,
        "horizontal",
      );
    }, []);
    const onAuthError = useCallback((message: string) => setAuthError(message), []);
    const onEditorChanged = useCallback(
      (value: Editor | null) => {
        if (shapeData) {
          shapeData.editor.set(value);

          if (value) {
            // Bookmarks
            shapeData.scrollBookmarkIntoView.set((bookmarkId: string, index?: number) =>
              value.chain().scrollBookmarkIntoView(bookmarkId, index).run(),
            );

            // Board search
            shapeData.highlightSearchResults.set((searchText: string) => {
              value.chain().search(searchText).run();

              const searchStorage: SearchStorage = value.storage[Search.name];
              return searchStorage.matchingRanges.length;
            });
            shapeData.highlightActiveSearchResultByIndex.set((searchText: string, matchIndex: number) =>
              value.chain().search(searchText).navigateToMatchIndex(matchIndex).run(),
            );
            shapeData.clearSearchHighlights.set(() => {
              value.chain().clearSearch().run();
            });

            // Emit "shapeReady" event when the editor is ready and externally callable functions are available
            shapeLifecycleEventEmitter.raiseBeforeShapeReady({ shapeId: shape.id });
            shapeLifecycleEventEmitter.raiseShapeReady({ shapeId: shape.id });
            shapeLifecycleEventEmitter.raiseAfterShapeReady({ shapeId: shape.id });
          } else {
            // Bookmarks
            shapeData.scrollBookmarkIntoView.set(() => false);

            // Board search
            shapeData.highlightSearchResults.set(() => 0);
            shapeData.highlightActiveSearchResultByIndex.set(() => false);
            shapeData.clearSearchHighlights.set(() => {});
          }
        }
      },
      [shapeData],
    );

    const scrollDocumentToBlockId = useCallback(
      (blockId: string) => {
        const editor = shapeData?.editor?.get();
        if (!editor) {
          return;
        }

        editor.chain().activateBlocks([blockId]).scrollBlockIntoView(blockId, { moveCursorToBlockStart: true }).run();
      },
      [shapeData],
    );

    const { handleInputPointerDown } = useShapeEvents(shape.id);

    const handleContextMenu = useCallback(
      (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (isEditing) {
          // Prevent TLDraw context menu when editing, this will open the browser's context menu
          e.stopPropagation();
        } else if (isReadOnly) {
          // Prevent TLDraw context menu when user does not have edit permissions, this will open the browser's context menu
          e.stopPropagation();
        }

        // Else, let TLDraw handle the context menu
      },
      [isReadOnly, isEditing],
    );

    const handleScrollContainerPointerDown = useCallback(
      (e: React.PointerEvent) => {
        if (isEditing) {
          e.stopPropagation();
        }
      },
      [isEditing],
    );

    let yDocumentFragment = null;
    if (boardProvider.yDocument) {
      // Check if we have created the temporary YDocument XmlFragment for this shape
      if (boardProvider.yDocument.share.has(documentId)) {
        yDocumentFragment = boardProvider.yDocument.getXmlFragment(documentId);
      } else {
        // We need to populate the YDocument XmlFragment for this shape
        const contentYDoc = documentHtmlToYDoc(props.html, documentId);
        const encodedUpdate = Y.encodeStateAsUpdate(contentYDoc);
        Y.applyUpdate(boardProvider.yDocument, encodedUpdate);

        yDocumentFragment = boardProvider.yDocument.getXmlFragment(documentId);
      }
    }

    const shapeTag = documentTypeToShapeTag(meta.documentType);
    const shapeColor = shapeTag ? ShapeTagProps[shapeTag].color : "black";
    const haveProvider = !!boardProvider.provider;
    return (
      <>
        <HTMLContainer
          id={shape.id}
          style={{
            background: "#fff",
            border: `1px solid ${isEditing ? "transparent" : shapeColor}`,
            display: "flex",
            pointerEvents: "all",
            justifyContent: "center",
          }}
        >
          {/* Parent container that has scroll when selected/editable */}
          <div
            ref={contentRef}
            style={{
              width: "100%",
              height: "100%",
              overflow: isSelected || isInteracting ? "hidden auto" : "hidden",
              pointerEvents: isSelected || isInteracting ? "auto" : "none",
              scrollbarGutter: "stable",
            }}
            onContextMenu={handleContextMenu}
            onPointerDown={handleScrollContainerPointerDown}
          >
            {/* Child container that allows parent to handle scrollbar drag with mouse when selected - pass events when editing */}
            <div
              style={{
                width: "100%",
                height: "100%",
                pointerEvents: isEditing ? "auto" : "none",
              }}
              onPointerDown={handleInputPointerDown}
            >
              {isEditing && authError && (
                <div style={{ position: "fixed", padding: "0.5in" }}>{t("Global.Error.UnauthorizedEdit")}</div>
              )}
              {shapeTag && (
                <Box
                  sx={{
                    position: "absolute",
                    right: "-1px",
                    top: "-25px",
                    height: "25px",
                    padding: "2px 10px",
                    backgroundColor: shapeColor,
                    borderTopLeftRadius: "4px",
                    borderTopRightRadius: "4px",
                    color: "#333",
                  }}
                >
                  <Typography variant="caption">{t(`Global.ShapeTag.${shapeTag}`)}</Typography>
                </Box>
              )}
              {/* Only display editable editor when its ready */}
              {!authError && haveProvider && yDocumentFragment && (
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                  }}
                >
                  <HtmlDocumentShapeEditor
                    activeBookmarkIds={activeBookmarkIds}
                    activeSelection={editorCurrentSelection}
                    isEditing={isEditing && !props.asyncUpdateLock}
                    onAddIFrameToBoard={onAddIFrameToBoard}
                    onAuthError={onAuthError}
                    onEditorChanged={onEditorChanged}
                    onSelectionUpdate={onSelectionUpdate}
                    onUpdate={onUpdate}
                    pageMargin={pageMargin}
                    pageName={pageName}
                    pageOrientation={pageOrientation}
                    pageSize={pageSize}
                    provider={boardProvider.provider!}
                    shapeId={shape.id}
                    tldrawEditor={tldrawEditor}
                    workspaceBoardId={workspaceBoardId}
                    yDocumentFragment={yDocumentFragment}
                  />
                  {/* Display spinner when we're in async lock mode */}
                  {props.asyncUpdateLock && (
                    <Box
                      sx={{
                        justifyContent: "center",
                        height: "100%",
                        width: "100%",
                        position: "fixed",
                        scrollbarGutter: "stable",
                        border: "transparent 1px solid",
                      }}
                    >
                      {/* Show (delayed) spinner if we're in async lock or editable editor takes a while to be ready */}
                      <Fade
                        in={props.asyncUpdateLock}
                        style={{
                          transitionDelay: props.asyncUpdateLock ? "800ms" : "0ms",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            position: "fixed",
                            width: "100%",
                            height: "100%",
                            backgroundColor: "rgba(255,255,255,0.5)",
                          }}
                        >
                          <CircularProgress sx={{ alignSelf: "center" }} />
                        </Box>
                      </Fade>
                    </Box>
                  )}
                </Box>
              )}
            </div>
          </div>
        </HTMLContainer>
        <HtmlDocumentShapeCompanion
          documentType={meta.documentType ?? DocumentType.None}
          initialHeight={props.h}
          isOpen={isCompanionOpen}
          onClose={() => setIsCompanionOpen(false)}
          scrollDocumentToBlockId={scrollDocumentToBlockId}
          shapeId={shape.id}
          title={meta.name || t("Components.HtmlDocumentCompanion.DefaultTitle")}
          workspaceBoardId={workspaceBoardId}
        />
      </>
    );
  }
}

// *********************************************
// Tool
// *********************************************/
/**
 * A definition for the tool to display in the main tool palette.
 */
export class HtmlDocumentTool extends BaseBoxShapeTool {
  static id = "htmlDocument";
  static initial = "idle";
  override shapeType = "htmlDocument";
}
