import { ItemGridSize } from "@bigpi/cookbook";
import { useAuthUser } from "@frontegg/react";
import { Workspaces } from "@mui/icons-material";
import { Box, ButtonBase, Card, CardActions, CardHeader, CardMedia, Grid, Typography } from "@mui/material";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { WorkspaceTagsList } from "Components/WorkspaceTagsList/WorkspaceTagsList";
import { WorkspacesQuery } from "GraphQL/Generated/Apollo";
import { linkToId } from "RoutePaths";
import { formatCollaborators, formatWorkspaceUpdatedAt } from "Utils/WorkspaceUtils";
import { WorkspaceListMenu } from "./WorkspaceListMenu";
import { WorkspaceBoardThumbnail } from "./WorkspaceBoardThumbnail";

export interface IWorkspaceGridItemProps {
  workspaceDetails: WorkspacesQuery["workspaces"][number];
  size: ItemGridSize;
}

export function WorkspaceGridItem(props: IWorkspaceGridItemProps) {
  const { workspaceDetails, size } = props;

  const user = useAuthUser();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const navigateToWorkspace = useCallback(() => {
    navigate(linkToId("workspace", workspaceDetails.id));
  }, [navigate, workspaceDetails.id]);

  return (
    <Grid
      item
      xl={size === ItemGridSize.Small ? 3 : size === ItemGridSize.Medium ? 4 : 6}
      lg={size === ItemGridSize.Small ? 2 : size === ItemGridSize.Medium ? 3 : 4}
      md={size === ItemGridSize.Small ? 3 : size === ItemGridSize.Medium ? 4 : 6}
      xs={size === ItemGridSize.Small ? 6 : size === ItemGridSize.Medium ? 6 : 12}
      sx={{ display: "grid" }}
    >
      <Card
        variant="outlined"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          borderWidth: "2px",
          backgroundColor: "#e8eaeb",
          ":hover": {
            backgroundColor: "#d8dadb",
          },
        }}
      >
        <CardHeader
          avatar={<Workspaces sx={{ color: "rgba(0, 0, 0, 0.54)" }} />}
          title={
            <ButtonBase sx={{ textAlign: "start" }} onClick={navigateToWorkspace} disableRipple>
              <Typography
                sx={{
                  fontSize: size === ItemGridSize.Small ? "1rem" : size === ItemGridSize.Medium ? "1.1rem" : "1.2rem",
                  fontWeight: "500",
                  wordBreak: "break-word",
                }}
              >
                {workspaceDetails.name}
              </Typography>
            </ButtonBase>
          }
          action={<WorkspaceListMenu workspaceId={workspaceDetails.id} />}
        />

        <Box sx={{ width: "100%", height: "100%", display: "flex", justifyContent: "center" }}>
          <ButtonBase
            sx={{ width: "90%", height: "100%", backgroundColor: "#fff", borderRadius: 1 }}
            onClick={navigateToWorkspace}
            disableRipple
          >
            <CardMedia sx={{ width: "100%", minHeight: "100px", display: "flex", flexWrap: "wrap", alignContent: "center" }}>
              <WorkspaceBoardThumbnail workspaceBoardId={workspaceDetails.defaultWorkspaceBoard?.id} />
            </CardMedia>
          </ButtonBase>
        </Box>

        <CardActions sx={{ display: "block", bottom: 0 }}>
          <Box sx={{ display: "flex", flexWrap: "wrap" }} component="span">
            <WorkspaceTagsList tagsList={workspaceDetails.tags} chipSxProps={{ backgroundColor: "#d8dadb" }} />
          </Box>

          <Box sx={{ mt: 1 }}>
            <Typography variant="body2">{workspaceDetails.description || ""}</Typography>
          </Box>

          <Box sx={{ mt: 1, display: "flex", flexDirection: "column" }}>
            <Typography variant="caption" color="text.secondary">
              {formatCollaborators(user.id, workspaceDetails.workspaceAccessControlList, t)}
            </Typography>

            <Typography sx={{ mt: 1 }} variant="caption" color="text.secondary">
              {formatWorkspaceUpdatedAt(workspaceDetails.updatedAt, t)}
            </Typography>
          </Box>
        </CardActions>
      </Card>
    </Grid>
  );
}
