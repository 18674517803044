import { CommandActionType } from "../CommandActionType.js";
import { CommandOutputType } from "../CommandOutputType.js";
import { ICommand } from "../ICommand.js";
import { DataType, EntityType } from "../ICommandInput.js";

export const COMMAND_ACCEPT_BOARD_QUESTIONNAIRE_SUGGESTION_TEMPLATE: ICommand = {
  id: "28c72eb6-f11f-4ec9-b6f0-e8cf22121ed6",
  name: "Applies the given suggestion to the associated board shape document",
  description: "",
  action: {
    actionType: CommandActionType.AcceptWorkspaceBoardSuggestionAction,
    data: {},
  },
  inputs: [
    {
      dataType: {
        dataType: DataType.String,
        isArray: false,
        validation: "",
      },
      fieldName: "workspaceBoardSuggestionId",
      fieldNameAliases: [],
      entityType: EntityType.String,
      defaultValue: "",
      label: "",
      visible: false,
      required: true,
    },
  ],
  inputTemplate: `{ "workspaceBoardSuggestionId": "{{ workspaceBoardSuggestionId }}" }`,
  isBlocking: false,
  outputTypes: [CommandOutputType.None],
};
