import { findAll } from "domutils";
import { parseDocument } from "htmlparser2";

/**
 * Get the block IDs in order from the content HTML.
 *
 * @param contentHtml The content HTML
 *
 * @returns The block IDs in order
 */
export function getBlockIdsInOrderOfAppearance(contentHtml: string): Array<string> {
  const dom = parseDocument(contentHtml);
  const elements = findAll((elem) => {
    return !!elem.attribs["data-id"];
  }, dom.children);

  return elements.map((elem) => {
    return elem.attribs["data-id"];
  });
}
