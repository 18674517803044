import { AddToBoardType } from "@bigpi/cookbook";
import { Box, Typography, useTheme } from "@mui/material";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { DocumentListItemCompact } from "Components/DocumentListItem/DocumentListItemCompact";
import { SplitButton } from "Components/SplitButton/SplitButton";
import { FilesAndDocumentsQuery, useDocumentContentLazyQuery } from "GraphQL/Generated/Apollo";

interface IDocumentListProps {
  data: FilesAndDocumentsQuery["documents"];
  onAddToBoard: (html: string) => void;
}

export function DocumentList(props: IDocumentListProps) {
  const { data, onAddToBoard } = props;
  const { t } = useTranslation();
  const theme = useTheme();

  const [getDocumentContent] = useDocumentContentLazyQuery();

  const handleAddToBoard = useCallback(
    async (documentId: string) => {
      // Get the document content.
      const { data } = await getDocumentContent({
        variables: {
          id: documentId,
        },
      });

      if (data?.document && data.document.content?.content) {
        onAddToBoard(data.document.content.content);
      } else {
        toast.warn(t("Components.AddToBoard.Error.NoContent"));
      }
    },
    [getDocumentContent, onAddToBoard],
  );

  const onSplitButtonClick = useCallback(
    (option: string, id: string) => {
      switch (option) {
        case AddToBoardType.AddDocument:
          handleAddToBoard(id);
          break;
        default:
          break;
      }
    },
    [handleAddToBoard],
  );

  return (
    <Box>
      <Typography variant="subtitle2">{t("Components.AddToBoard.SectionHeader.Document")}</Typography>
      {data.map((row) => {
        return <DocumentListItemCompact key={row.id} row={row} secondaryAction={getSecondaryAction} />;
      })}
    </Box>
  );

  /**
   * Gets the secondary action with SplitButton.
   *
   * @param id Id of the file.
   *
   * @returns SplitButton component with AddDocument action.
   */
  function getSecondaryAction(id: string) {
    return (
      <SplitButton
        handleClick={(option) => onSplitButtonClick(option, id)}
        options={[{ value: AddToBoardType.AddDocument, label: t("Components.AddToBoard.Actions.AddDocument") }]}
        slotProps={{
          popper: { sx: { zIndex: theme.zIndex.modal } },
        }}
      />
    );
  }
}
