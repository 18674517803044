import { CommandOutputType } from "@bigpi/cookbook";
import { toast } from "react-toastify";

import { ResponsePlugInBase } from "./ResponsePlugInBase";
import { ICommandResponsePlugInInputs } from "Components/CommandManagers/WorkspaceBoard/WorkspaceBoardCommandManager";

export class ErrorResponsePlugIn extends ResponsePlugInBase {
  // *********************************************
  // Public properties
  // *********************************************/
  /**
   * @inheritdoc
   */
  name = CommandOutputType.Error;

  // *********************************************
  // Public methods
  // *********************************************/
  /**
   * @inheritdoc
   */
  public async execute(input: ICommandResponsePlugInInputs): Promise<undefined | JSX.Element> {
    const { app, commandResponse, onClose, t } = input;

    if (!app) {
      console.warn(`No app instance for command response ${commandResponse.requestId}`);
      return;
    }

    const toastId = toast.error(t("Global.Error.GenericError", { autoClose: false }));

    // The following is required to prevent autoClose of the toast, even though we set it above
    toast.update(toastId, { autoClose: false, progress: null });
  }
}
