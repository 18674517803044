import { TabContext, TabPanel, TabPanelProps } from "@mui/lab";
import { Divider } from "@mui/material";
import { TLShapeId } from "@tldraw/tldraw";
import { useEffect, useState } from "react";

import { CompanionTabActions } from "./CompanionActions";
import { CompanionContent, ICompanionContentProps } from "./CompanionContent";
import { CompanionHeader } from "./CompanionHeader";
import { CompanionTab } from "./CompanionTab";
import { CompanionTabs } from "./CompanionTabs";
import { CompanionTitle, ICompanionTitleProps } from "./CompanionTitle";
import { CompanionWindow } from "./CompanionWindow";
import { ICompanionTab } from "./ICompanionTab";

export interface ICompanionV2Props {
  initialHeight?: number;
  isOpen: boolean;
  onClose: () => void;
  shapeId: TLShapeId;

  /**
   * The optional, initial position relative to the parent shape to open the companion.
   */
  relativePosition?: {
    x: number;
    y: number;
  };

  slotProps?: {
    CompanionContent?: {
      sx?: ICompanionContentProps["sx"];
    };
    CompanionTitle?: Partial<ICompanionTitleProps>;
    TabPanel?: Partial<TabPanelProps>;
  };

  tabs: Array<ICompanionTab>;
  title: string | React.ReactNode;
}

/**
 * Manages companion windows for all shapes.
 *
 * @param props The component props.
 *
 * @returns A component containing passed in children and the common Companion element.
 */
export function CompanionV2(props: ICompanionV2Props) {
  const { initialHeight, isOpen, onClose, shapeId, relativePosition, slotProps, tabs, title } = props;
  const [activeTabId, setActiveTabId] = useState<string | undefined>(tabs.length > 0 ? tabs[0].tabId : undefined);

  useEffect(() => {
    if (!activeTabId && tabs.length > 0) {
      setActiveTabId(tabs[0].tabId);
    }
  }, [activeTabId, tabs]);

  if (!isOpen) {
    return null;
  }

  return (
    <CompanionWindow initialHeight={initialHeight} isVisible={true} shapeId={shapeId} relativePosition={relativePosition}>
      <CompanionTitle onClose={onClose} {...(slotProps ? slotProps.CompanionTitle : {})}>
        {title}
      </CompanionTitle>
      <Divider />

      {tabs.length > 1 || (tabs.length === 1 && tabs[0].actions.length > 0) ? (
        <>
          <CompanionHeader>
            <CompanionTabs
              value={activeTabId ?? tabs[0].tabId}
              onChange={(event: React.SyntheticEvent, newValue: string) => {
                // Event?
                setActiveTabId(newValue);
              }}
              sx={{ flexGrow: 1, mr: 2 }}
            >
              {tabs.map((tab) => (
                <CompanionTab key={tab.tabId} label={tab.title} value={tab.tabId} />
              ))}
            </CompanionTabs>
            <CompanionTabActions tabActions={tabs.find((tab) => tab.tabId === activeTabId)?.actions ?? []} />
          </CompanionHeader>
          <CompanionContent {...(slotProps ? slotProps.CompanionContent : {})}>
            <TabContext value={activeTabId ?? tabs[0].tabId}>
              {tabs.map((tab) => (
                <TabPanel key={tab.tabId} value={tab.tabId} sx={tab.sx}>
                  {tab.children}
                </TabPanel>
              ))}
            </TabContext>
          </CompanionContent>
        </>
      ) : (
        <CompanionContent>{tabs[0]?.children}</CompanionContent>
      )}
    </CompanionWindow>
  );
}
