import { getFileDownloadUrlByFileId } from "@bigpi/cookbook";
import { Permissions } from "@bigpi/permission";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import RefreshIcon from "@mui/icons-material/Refresh";
import ShareIcon from "@mui/icons-material/Share";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { Box, IconButton, Menu, MenuItem, ListItemIcon, ListItemText, Tooltip, Divider, Link } from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { FileAccessControlListDialog } from "Components/AccessControlList/FileAccessControlListDialog";
import { HasFileRole } from "Components/HasFileRole/HasFileRole";
import { HasPermission } from "Components/HasPermission/HasPermission";
import { DeleteFileDialog } from "Components/DeleteFileDialog/DeleteFileDialog";
import { RenameFileDialog } from "Components/RenameFileDialog/RenameFileDialog";
import { Config } from "Config";
import { ObjectRole, useReindexFilesMutation } from "GraphQL/Generated/Apollo";
import { EditFilesInWorkspaceDialog } from "./EditFilesInWorkspaceDialog";

export interface ILibraryListMenu {
  fileId: string;
}

export function LibraryListMenu(props: ILibraryListMenu) {
  const { fileId } = props;
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [aclDialogOpen, setAclDialogOpen] = React.useState(false);
  const [editInWorkspaceOpen, setEditInWorkspaceOpen] = React.useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [renameDialogOpen, setRenameDialogOpen] = React.useState(false);
  const [reindexEnabled, setReindexEnabled] = React.useState(true);

  const [ReindexFiles] = useReindexFilesMutation();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const reindexFile = async () => {
    // Disable the button to prevent multiple clicks
    setReindexEnabled(false);

    try {
      const response = await ReindexFiles({
        variables: {
          input: {
            fileIds: [fileId],
          },
        },
      });

      const fileIds = response.data?.reindexFiles.fileIds || [];
      if (fileIds.length > 0) {
        // Show success message
        toast.success(t("Components.LibraryListMenu.Reindex.Success"));
      } else {
        // Show error message
        toast.error(t("Components.LibraryListMenu.Reindex.Error"));
      }
    } catch (error) {
      console.error(error);

      // Show error message
      toast.error(t("Components.LibraryListMenu.Reindex.Error"));

      // Re-enable the button on failures, but not success
      setReindexEnabled(true);
    }
  };

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title={t("Global.Action.Actions") || ""}>
          <IconButton
            onClick={handleClick}
            size="small"
            aria-controls={open ? "file-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <MoreVertIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 24,
              height: 24,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {/* Anyone with the view access or above can download */}
        <MenuItem component={Link} href={getFileDownloadUrlByFileId(Config.assetHttpUrl, fileId)} target="_blank">
          <ListItemIcon>
            <DownloadIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={t("Global.Action.Download")} />
        </MenuItem>

        {/* Limit sharing dialog to platform admins for now */}
        <HasPermission permission={Permissions.PlatformDataWrite}>
          <MenuItem onClick={() => setAclDialogOpen(true)}>
            <ListItemIcon>
              <ShareIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={t("Global.Action.Share")} />
          </MenuItem>
        </HasPermission>

        <MenuItem onClick={() => setEditInWorkspaceOpen(true)}>
          <ListItemIcon>
            <CreateIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={t("Global.Action.NewWorkspaceFrom")} />
        </MenuItem>

        <HasFileRole fileId={fileId} roles={[ObjectRole.Manager, ObjectRole.Owner]}>
          <MenuItem onClick={() => setRenameDialogOpen(true)}>
            <ListItemIcon>
              <DriveFileRenameOutlineIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={t("Global.Action.Rename")} />
          </MenuItem>

          <Divider />
          <MenuItem onClick={() => setDeleteDialogOpen(true)}>
            <ListItemIcon>
              <DeleteIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ color: "danger.main" }} primary={t("Global.Action.Delete")} />
          </MenuItem>
        </HasFileRole>

        <HasPermission permission={Permissions.PlatformDataWrite}>
          <Divider />
          <MenuItem disabled={!reindexEnabled} onClick={reindexFile}>
            <ListItemIcon>
              <RefreshIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={t("Global.Action.ReindexFiles", { count: 1 })} />
          </MenuItem>
        </HasPermission>
      </Menu>
      {aclDialogOpen && (
        <FileAccessControlListDialog fileId={fileId} open={aclDialogOpen} onClose={() => setAclDialogOpen(false)} />
      )}
      {deleteDialogOpen && (
        <DeleteFileDialog fileId={fileId} open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)} />
      )}
      {renameDialogOpen && (
        <RenameFileDialog fileId={fileId} open={renameDialogOpen} onClose={() => setRenameDialogOpen(false)} />
      )}
      {editInWorkspaceOpen && (
        <EditFilesInWorkspaceDialog open={editInWorkspaceOpen} onClose={() => setEditInWorkspaceOpen(false)} fileIds={[fileId]} />
      )}
    </React.Fragment>
  );
}
