import {
  ContentTypes,
  CreatedAtAscSortOptionName,
  CreatedAtDescSortOptionName,
  FILE_UPLOAD_MAX_FILE_SIZE,
  getSortOption,
  ItemGridSize,
  ItemViewType,
  NameAscSortOptionName,
  NameDescSortOptionName,
  NonEmptyArray,
  ONE_MB,
  SortKeys,
} from "@bigpi/cookbook";
import { Box, Button, styled, Typography } from "@mui/material";
import { Upload } from "@mui/icons-material";
import { useCallback, useEffect, useState } from "react";
import { useDropzone, Accept, FileRejection, ErrorCode as ReactDropzoneErrorCode } from "react-dropzone";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { v4 as uuidV4 } from "uuid";

import { ContentActionBar } from "Components/ContentActionBar/ContentActionBar";
import { ContentFilterPanel } from "Components/ContentFilterPanel/ContentFilterPanel";
import { CommandContext } from "CommandContext";
import { IFileWithId } from "Components/Upload/FilesList";
import {
  FileBundleViewType,
  useUpsertLibraryPageUserPreferencesMutation,
  useLibraryPageUserPreferencesQuery,
} from "GraphQL/Generated/Apollo";
import { LIBRARY_PAGE_USER_PREFERENCES_KEY } from "GraphQL/UserPreference";
import { useDefaultPagePreferences } from "Hooks/useDefaultPagePreferences";
import { LibraryView } from "Pages/Library/LibraryView";
import {
  getGridSizeFromUserPreferences,
  getPageSizeFromUserPreferences,
  getSortOptionFromUserPreferences,
} from "Utils/UserPreferencesUtils";
import { FileActionBarButtons } from "./FileActionBarButtons";
import { LibraryUploadDialog } from "./LibraryUploadDialog";

// *********************************************
// Types/Interfaces/Constants
// *********************************************/
interface ILibraryPageUserPreferences {
  gridSize: ItemGridSize;
  viewType: ItemViewType;
  pageSize: number;
  sortOption: SortKeys;
}

// TODO: This needs to move to central location to be shared with workspaces
const FILE_UPLOAD_ACCEPTED_MIMETYPE: Accept = {
  [ContentTypes.PDF]: [".pdf"],
  [ContentTypes.DOC]: [".doc"],
  [ContentTypes.DOCX]: [".docx"],
  [ContentTypes.RTF]: [".rtf"],
  [ContentTypes.RTF_TEXT]: [".rtf"],
  [ContentTypes.RTF_X]: [".rtf"],
  [ContentTypes.RTF_RICHTEXT]: [".rtf"],
  [ContentTypes.PLAIN_TEXT]: [".txt"],
  [ContentTypes.HTML]: [".html"],
};

const FILE_UPLOAD_MAX_FILES = 50;
const SORT_OPTIONS: NonEmptyArray<SortKeys> = [
  CreatedAtDescSortOptionName,
  CreatedAtAscSortOptionName,
  NameAscSortOptionName,
  NameDescSortOptionName,
];

const PAGE_SIZE_OPTIONS: NonEmptyArray<number> = [20, 50, 100];
// Note: Please use a value from PAGE_SIZE_OPTIONS only
const DEFAULT_PAGE_SIZE = PAGE_SIZE_OPTIONS[1];

// *********************************************
// Components
// *********************************************/
/**
 * Styled input file component to hide the default input file button
 */
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export function LibraryPage() {
  const { t } = useTranslation();

  // State
  const [preferences, setPreferences] = useState<ILibraryPageUserPreferences>({
    gridSize: ItemGridSize.Medium,
    viewType: ItemViewType.List,
    sortOption: SORT_OPTIONS[0],
    pageSize: DEFAULT_PAGE_SIZE,
  });
  const [searchValue, setSearchValue] = useState("");
  const [selectedBundleIds, setSelectedBundleIds] = useState<Array<string>>([]);
  const [selectedFileIds, setSelectedFileIds] = useState<Array<string>>([]);
  const [uploadingFiles, setUploadingFiles] = useState<Array<IFileWithId>>([]);
  const [isDroppingFiles, setIsDroppingFiles] = useState(false);

  // Files query
  const defaultPagePreferences = useDefaultPagePreferences("library");
  const { data: persistedPreferences } = useLibraryPageUserPreferencesQuery({
    variables: {
      key: LIBRARY_PAGE_USER_PREFERENCES_KEY,
    },
  });

  useEffect(() => {
    // Set current application session context
    CommandContext.replaceSessionContext([]);
  }, []);

  // User preferences update mutation
  const [upsertUserPreference] = useUpsertLibraryPageUserPreferencesMutation({
    // Update cache directly since the server will not return the fully cascaded data, just the user portion
    // This ensures any default and org-level preferences are not lost
    update: (cache, data) => {
      cache.modify({
        fields: {
          userPreference(existing = {}) {
            return {
              ...existing,
              data: {
                ...existing.data,
                ...data.data?.upsertUserPreference.data,
              },
            };
          },
        },
      });
    },
  });

  // Hooks
  const onResetSelection = useCallback(() => {
    setSelectedFileIds([]);
    setSelectedBundleIds([]);
  }, []);

  const updatePreferences = useCallback(
    (preferences: Partial<ILibraryPageUserPreferences>) => {
      setPreferences((current) => {
        return { ...current, ...preferences };
      });
      onResetSelection();
      upsertUserPreference({
        variables: {
          input: {
            key: LIBRARY_PAGE_USER_PREFERENCES_KEY,
            data: preferences,
          },
        },
      });
    },
    [onResetSelection, upsertUserPreference],
  );

  useEffect(() => {
    const gridSize = getGridSizeFromUserPreferences(persistedPreferences, defaultPagePreferences, ItemGridSize.Medium);
    // View type is hardcoded for the library page at the moment
    // const viewType = getViewTypeFromUserPreferences(persistedPreferences, defaultPagePreferences, ItemViewType.List);
    const viewType = ItemViewType.List;
    const sortOption = getSortOptionFromUserPreferences(persistedPreferences, defaultPagePreferences, SORT_OPTIONS);
    const pageSize = getPageSizeFromUserPreferences(
      persistedPreferences,
      defaultPagePreferences,
      PAGE_SIZE_OPTIONS,
      DEFAULT_PAGE_SIZE,
    );

    // The preferences have changed, update the state
    setPreferences({
      gridSize,
      viewType,
      sortOption,
      pageSize,
    });
  }, [defaultPagePreferences, persistedPreferences]);

  const onSelectionChange = useCallback((type: string, id: string) => {
    if (type === FileBundleViewType.File) {
      setSelectedFileIds((prev) => {
        if (prev.includes(id)) {
          return prev.filter((i) => i !== id);
        } else {
          return [...prev, id];
        }
      });
    } else if (type === FileBundleViewType.Bundle) {
      setSelectedBundleIds((prev) => {
        if (prev.includes(id)) {
          return prev.filter((i) => i !== id);
        } else {
          return [...prev, id];
        }
      });
    }
  }, []);

  const onSearchValueChange = useCallback(
    (value: string) => {
      setSearchValue(value);
      onResetSelection();
    },
    [onResetSelection],
  );

  // Dropzone
  const { getRootProps, getInputProps } = useDropzone({
    // Allow click to open file dialog
    noClick: true,
    noKeyboard: true,

    // Accepted file types
    accept: FILE_UPLOAD_ACCEPTED_MIMETYPE,

    // Maximum file size allowed
    maxSize: FILE_UPLOAD_MAX_FILE_SIZE,

    // Maximum number of files allowed
    maxFiles: FILE_UPLOAD_MAX_FILES,

    // Called when files are dropped/selected
    onDrop: (acceptedFiles: Array<File>) => {
      setUploadingFiles((prevState) => {
        const newFiles = acceptedFiles.map((file) => {
          return {
            id: uuidV4(),
            file,
          };
        });
        return [...prevState, ...newFiles];
      });
      setIsDroppingFiles(false);
    },

    onDragEnter: () => {
      setIsDroppingFiles(true);
    },
    onDragLeave: () => {
      setIsDroppingFiles(false);
    },

    // Called when files are rejected
    onDropRejected: (fileRejections: Array<FileRejection>) => {
      const messages: Set<string> = new Set();
      fileRejections.forEach((fileRejection) => {
        fileRejection.errors.forEach((error) => {
          switch (error.code) {
            case ReactDropzoneErrorCode.FileInvalidType:
              messages.add(t("Components.LibraryUploadDialog.Errors.FileInvalidType"));
              break;
            case ReactDropzoneErrorCode.FileTooLarge:
              messages.add(
                t("Components.LibraryUploadDialog.Errors.FileTooLarge", {
                  maxInMBs: 100 / ONE_MB,
                }),
              );
              break;
            default:
              messages.add(
                t("Components.LibraryUploadDialog.Errors.TooManyFiles", {
                  maxFiles: FILE_UPLOAD_MAX_FILES,
                }),
              );
              break;
          }
        });
      });

      toast.error(Array.from(messages).join("; "));
    },

    // Allow multiple files to be uploaded
    multiple: true,
  });

  const { ref, ...rootProps } = getRootProps();

  return (
    <>
      <Helmet>
        <title>{t("Pages.Library.Title")}</title>
      </Helmet>

      <Box
        sx={{
          alignSelf: "center",
          display: "flex",
          justifyContent: "space-between",
          m: 3,
          maxWidth: "1920px",
          mb: 0,
          minWidth: "400px",
          width: "90%",
        }}
      >
        <Typography variant="h5">{t("Pages.Library.HeadingLabel")}</Typography>
        <Button component="label" role={undefined} startIcon={<Upload />} size="small" variant="contained">
          {t("Pages.Library.UploadFiles")}
          <VisuallyHiddenInput {...getInputProps()} />
        </Button>
      </Box>

      <Box
        sx={{
          alignSelf: "center",
          backgroundColor: isDroppingFiles ? "#e6efff" : "transparent",
          border: `1px solid #f1f1f1`,
          borderRadius: "16px",
          display: "flex",
          flexDirection: "column",
          m: 3,
          maxWidth: "1920px",
          mb: 10,
          minWidth: "400px",
          p: 3,
          width: "90%",
        }}
        {...rootProps}
        ref={ref}
      >
        <ContentFilterPanel
          onPaginationValueChange={(pageSize) => updatePreferences({ pageSize })}
          onSearchValueChange={onSearchValueChange}
          onSortOptionChange={(sortOption) => updatePreferences({ sortOption })}
          onTagsChange={() => {}}
          onViewTypeChange={(viewType) => updatePreferences({ viewType })}
          pageSize={preferences.pageSize}
          pageSizeOptions={PAGE_SIZE_OPTIONS}
          searchLabel={t("Pages.Library.FindLabel")}
          selectedSortOption={preferences.sortOption}
          selectedTags={[]}
          sortOptions={SORT_OPTIONS}
          tags={[]}
          viewType={preferences.viewType}
        />

        <Box sx={{ height: "35px", position: "sticky", top: "0px", zIndex: 2 }}>
          {selectedFileIds.length > 0 && (
            /* Displays the count of only selectedFileIds, when bundle is selected that includes the bundle related files count */
            <ContentActionBar count={selectedFileIds.length} onClose={onResetSelection}>
              <FileActionBarButtons fileIds={selectedFileIds} onClose={onResetSelection} bundleIds={selectedBundleIds} />
            </ContentActionBar>
          )}
        </Box>
        <LibraryView
          gridSize={preferences.gridSize}
          viewType={preferences.viewType}
          onSelectionChange={onSelectionChange}
          selectedFileIds={selectedFileIds}
          searchValue={searchValue}
          onGridSizeChange={(gridSize) => updatePreferences({ gridSize })}
          selectedSortOption={getSortOption(preferences.sortOption)}
          pageSize={preferences.pageSize}
        />
      </Box>
      {uploadingFiles.length > 0 && (
        <Box
          {...rootProps}
          ref={ref}
          sx={{
            backgroundColor: isDroppingFiles ? "#e6efff" : "transparent",
          }}
        >
          <LibraryUploadDialog
            onClose={() => setUploadingFiles([])}
            selectedFiles={uploadingFiles}
            onRemoveFile={(id) => setUploadingFiles(uploadingFiles.filter((file) => file.id !== id))}
          />
        </Box>
      )}
    </>
  );
}
