import { useApolloClient } from "@apollo/client";
import { FacetDisplaySortType, DataGridColumnFormat, AnalysisFeedbackDataItemType, StandardHtmlColors } from "@bigpi/cookbook";
import {
  getTopicDiscussionAnalysisShapeDefaultProps,
  IDataGridColumnDef,
  IDataGridPreferences,
  ITopicDiscussionAnalysisFacets,
  ITopicDiscussionAnalysisPreferences,
  ITopicDiscussionAnalysisSelection,
  ITopicDiscussionAnalysisShape,
  topicDiscussionAnalysisShapeMigrations,
  topicDiscussionAnalysisShapeProps,
} from "@bigpi/tl-schema";
import { useAuthUser } from "@frontegg/react";
import { Grid, Checkbox, FormControl, InputLabel, Select, MenuItem, ListItemText, Typography } from "@mui/material";
import {
  atom,
  createShapeId,
  HTMLContainer,
  Migrations,
  SVGContainer,
  TLShape,
  TLShapeId,
  TLShapeProps,
  useValue,
} from "@tldraw/tldraw";
import * as d3 from "d3";
import Handlebars from "handlebars";
import { TFunction } from "i18next";
import { useCallback, useEffect, useState, useRef } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import { v4 as uuidV4 } from "uuid";

import { ITopicDiscussionAnalysisConfig } from "BoardComponents/Analyses/DataFrameConfigs";
import {
  FieldFacetsManageStateType,
  useApplyBounds,
  useFieldDistinctValuesQuery,
  useFieldFacetsManager,
  useFieldGroupsQuery,
  useIsChildEditing,
  useIsChildSelected,
  useQueryValidFacets,
  useSelectedDate,
  useShortcutRelatedRanges,
} from "BoardComponents/Analyses/Hooks";
import { transformToToolbarSelectOptions } from "BoardComponents/Analyses/Utils/AnalysisToolbarDataFormatting";
import { ALLOWED_ANALYSES_CHILD_STANDARD_SHAPE_TYPES, DataframeBaseUtil } from "BoardComponents/BaseShapes/DataframeBaseUtil";
import {
  ITopicDiscussionAnalysisShapeExternalData,
  ITopicDiscussionExampleResult,
  useBoardDatastore,
} from "BoardComponents/BoardDatastore";
import { useCompanionManager } from "BoardComponents/Companion/useCompanionManager";
import { DashedOutlineBox } from "BoardComponents/DashedOutlineBox/DashedOutlineBox";
import { DataGridColumnFormatMapping } from "BoardComponents/DataFormatting/DataFormatting";
import { DataframeBackground } from "BoardComponents/DataframeBackground/DataframeBackground";
import {
  AnalysisPreferencesDialog,
  IAnalysisPreferencesDialogState,
} from "Components/AnalysisPreferencesDialog/AnalysisPreferencesDialog";
import { AnalysisToolbar } from "Components/AnalysisToolbar/AnalysisToolbar";
import { useIsInteracting } from "BoardComponents/Tools";
import { useIsChildInteracting } from "BoardComponents/Tools";
import { useShapeEvents } from "BoardComponents/useShapeEvents";
import { AnalysisFeedbackDialog } from "Components/AnalysisFeedbackDialog/AnalysisFeedbackDialog";
import { SplitButton, SplitButtonProps } from "Components/SplitButton/SplitButton";
import {
  ITopicDiscussionSummaryMasterDetailsRef,
  TopicDiscussionSummaryMasterDetails,
} from "Components/TopicDiscussionSummaryMasterDetails/TopicDiscussionSummaryMasterDetails";
import { TopicDiscussionSummaryOverview } from "Components/TopicDiscussionSummaryOverview/TopicDiscussionSummaryOverview";
import { addChartsToBoard } from "Components/TopicDiscussionSummaryOverview/TopicDiscussionSummaryAddToBoard";
import {
  useGetConfigDataQuery,
  useTopicDiscussionExamplesLazyQuery,
  useTopicDiscussionExampleAggregateQuery,
} from "GraphQL/Generated/Apollo";
import { ChartUtils } from "Utils/ChartUtils";
import { DataUtils } from "Utils/DataUtils";
import { AnalysisToolbarActions } from "../AnalysisToolbarActions";
import { useTopicDiscussionCompanionOverlay } from "../useTopicDiscussionCompanionOverlay";
import { topicDiscussionAnalysisFieldsConfig } from "./TopicDiscussionAnalysisFieldsConfig";
import { getFilteredTopicDiscussionExamplesData, getTopicDiscussionExamplesData } from "./topicDiscussionExamplesDataUtils";

import "./TopicDiscussionAnalysisShape.css";

// *********************************************
// Private constants
// *********************************************/
const ALLOWED_CHILD_SHAPE_TYPES = [
  ...ALLOWED_ANALYSES_CHILD_STANDARD_SHAPE_TYPES,

  // Custom shapes
  "dataGrid",
  "groupBubbleChart",
  "lockedText",
];

const CONFIG_KEY = "topic-discussion-analysis-config";
const EVENT_DATE_FIELD_NAME = "eventDate";
const OVERLAY_WINDOW_PADDING = 50;

// Overview chart config
const speakersOverviewChartConfig = {
  xField: "eventDate",
  yField: "label",
  fyField: "fullName",
};

const topicOverviewChartConfig = {
  xField: "eventDate",
  fyField: "question",
  groupReducer: (data: Array<Record<string, any>>) => {
    return data.length;
  },
};

const themeOverviewChartConfig = {
  xField: "eventDate",
  fyField: "topic",
  groupReducer: (data: Array<Record<string, any>>) => {
    return data.length;
  },
};

const overlayDetailFieldsConfig = (t: TFunction<"translation", undefined>): Array<IDataGridColumnDef> => [
  {
    field: "question",
    flex: 4,
    headerName: t("Components.TopicDiscussionSummaryMasterDetails.GridLabel.Discussion"),
    format: DataGridColumnFormat.String,
  },
  {
    field: "eventDate",
    flex: 1,
    headerName: t("Components.TopicDiscussionSummaryMasterDetails.GridLabel.EventDate"),
    format: DataGridColumnFormat.Date,
  },
  {
    field: "speakers",
    flex: 2,
    headerName: t("Components.TopicDiscussionSummaryMasterDetails.GridLabel.Speakers"),
    format: DataGridColumnFormat.Speakers,
  },
  {
    field: "section",
    flex: 2,
    headerName: t("Components.TopicDiscussionSummaryMasterDetails.GridLabel.Section"),
    format: DataGridColumnFormat.String,
  },
];

const overlayDetailGridPreferences: IDataGridPreferences = {
  sortModel: [
    {
      field: "eventDate",
      sort: "desc",
    },
    {
      field: "section",
      sort: "asc",
    },
  ],
};

const getAvailableColors = (t: TFunction<"translation", undefined>) => [
  { label: t("Components.Charts.Colors.Sky"), key: StandardHtmlColors.sky },
  { label: t("Components.Charts.Colors.Willow"), key: StandardHtmlColors.willow },
  { label: t("Components.Charts.Colors.Wisteria"), key: StandardHtmlColors.wisteria },
  { label: t("Components.Charts.Colors.Saffron"), key: StandardHtmlColors.saffron },
  { label: t("Components.Charts.Colors.Heather"), key: StandardHtmlColors.heather },
  { label: t("Components.Charts.Colors.Coral"), key: StandardHtmlColors.coral },
  { label: t("Components.Charts.Colors.Pine"), key: StandardHtmlColors.pine },
  { label: t("Components.Charts.Colors.Pacific"), key: StandardHtmlColors.pacific },
  { label: t("Components.Charts.Colors.Berry"), key: StandardHtmlColors.berry },
  { label: t("Components.Charts.Colors.Cayenne"), key: StandardHtmlColors.cayenne },
  { label: t("Components.Charts.Colors.Jade"), key: StandardHtmlColors.jade },
  { label: t("Components.Charts.Colors.Patina"), key: StandardHtmlColors.patina },
  { label: t("Components.Charts.Colors.Midnight"), key: StandardHtmlColors.midnight },
];

// *********************************************
// Shape Util
// *********************************************/
/**
 * Generator for TopicDiscussionAnalysis shapes.
 */
export class TopicDiscussionAnalysisUtil extends DataframeBaseUtil<ITopicDiscussionAnalysisShape> {
  // *********************************************
  // Static fields
  // *********************************************/
  static type = "topicDiscussionAnalysis";

  static migrations: Migrations = topicDiscussionAnalysisShapeMigrations;

  static props = topicDiscussionAnalysisShapeProps;

  // *********************************************
  // Override methods, event handlers
  // *********************************************/
  /**
   * Listens the children change event & adjusts the shape size.
   *
   * @param shape Shape on which the children changed
   */
  override onChildrenChange = (shape: ITopicDiscussionAnalysisShape) => {
    const children = this.editor.getSortedChildIdsForParent(shape.id);

    // Remove the data frame if there are no children
    if (children.length === 0) {
      if (this.editor.getFocusedGroupId() === shape.id) {
        this.editor.popFocusedGroupId();
      }
      this.editor.deleteShapes([shape.id]);
    }
  };

  // *********************************************
  // Override methods
  // *********************************************/
  /**
   * @inheritdoc
   */
  override isAspectRatioLocked = (shape: ITopicDiscussionAnalysisShape) => false;

  /**
   * @inheritdoc
   */
  override canResize = (shape: ITopicDiscussionAnalysisShape) => false;

  /**
   * @inheritdoc
   */
  override canBind = (shape: ITopicDiscussionAnalysisShape) => true;

  /**
   * @inheritdoc
   */
  override canEdit = (shape: ITopicDiscussionAnalysisShape) => true;

  /**
   * @inheritdoc
   */
  override canReceiveNewChildrenOfType = (_shape: ITopicDiscussionAnalysisShape, type: TLShape["type"]) => {
    return ALLOWED_CHILD_SHAPE_TYPES.includes(type);
  };

  /**
   * @inheritdoc
   */
  override canScroll = (shape: ITopicDiscussionAnalysisShape) => false;

  /**
   * @inheritdoc
   */
  hideSelectionBoundsBg = () => false;

  /**
   * @inheritdoc
   */
  hideSelectionBoundsFg = () => true;

  /**
   * @inheritdoc
   */
  override getDefaultProps(): ITopicDiscussionAnalysisShape["props"] {
    return getTopicDiscussionAnalysisShapeDefaultProps();
  }

  /**
   * @inheritdoc
   */
  override component(shape: ITopicDiscussionAnalysisShape) {
    const tldrawEditor = this.editor;
    const zoomLevel = tldrawEditor.getZoomLevel();
    const { id, props } = shape;
    const { t } = useTranslation();
    const apolloClient = useApolloClient();
    const selectedFacetValuesProp = props.selectedFacetValues as ITopicDiscussionAnalysisFacets;
    const boundsFacetValuesProp = props.boundsFacetValues as ITopicDiscussionAnalysisFacets;
    const selection = props.selection as ITopicDiscussionAnalysisSelection;
    const toolbar = props.toolbar;
    const analysisPreferences = props.preferences.analysis;
    const workspaceBoardId = (tldrawEditor.getInstanceState().meta.workspaceBoardId as string) || "";
    const availableColors = getAvailableColors(t);

    const bounds = this.getGeometry(shape).getBounds();
    const isEditing = useValue("isEditing", () => tldrawEditor.getCurrentPageState().editingShapeId === id, [tldrawEditor, id]);
    const isHovered = useValue("isHovered", () => tldrawEditor.getCurrentPageState().hoveredShapeId === id, [tldrawEditor, id]);
    const isInteracting = useIsInteracting(id);
    const isChildInteracting = useIsChildInteracting(id);
    const isSelected = useValue("isSelected", () => tldrawEditor.getCurrentPageState().selectedShapeIds.includes(id), [
      tldrawEditor,
      id,
    ]);
    const isChildSelected = useIsChildSelected(shape.id);
    const isChildEditing = useIsChildEditing(shape.id);

    const companionManager = useCompanionManager();
    companionManager.on("companionClosed", () => {
      setSelectedTheme(null);
      setSelectedTicker(null);
      setSelectedShapeId(null);
    });

    // Config & data
    const [config, setConfig] = useState({} as ITopicDiscussionAnalysisConfig);
    // Date range shortcuts
    const dateRangeShortcuts = useShortcutRelatedRanges(
      config.dateShortcuts ? config.dateShortcuts[EVENT_DATE_FIELD_NAME] : undefined,
    );

    // Transforms date shortcut
    const selectedFacetValuesSelectedDate = useSelectedDate(
      dateRangeShortcuts,
      selectedFacetValuesProp.eventDateShortcut,
      selectedFacetValuesProp.eventDate,
    );
    const boundsFacetValuesSelectedDate = useSelectedDate(
      dateRangeShortcuts,
      boundsFacetValuesProp.eventDateShortcut,
      boundsFacetValuesProp.eventDate,
    );

    const selectedFacetValues = useValue(
      "selectedFacetValues",
      () => {
        return {
          ...selectedFacetValuesProp,
          eventDate: selectedFacetValuesSelectedDate,
        };
      },
      [selectedFacetValuesSelectedDate, selectedFacetValuesProp],
    );

    const boundsFacetValues = useValue(
      "boundsFacetValues",
      () => {
        return {
          ...boundsFacetValuesProp,
          eventDate: boundsFacetValuesSelectedDate,
        };
      },
      [boundsFacetValuesSelectedDate, boundsFacetValuesProp],
    );

    // Merge boundsFacets with facets
    const mergedFacets = useApplyBounds(boundsFacetValues, selectedFacetValues);

    // Validate the facets to fit for query
    const validMergedFacetValues = useQueryValidFacets(mergedFacets, topicDiscussionAnalysisFieldsConfig);
    const validBoundsFacetValues = useQueryValidFacets(boundsFacetValues, topicDiscussionAnalysisFieldsConfig);

    // Isolates facets for each field
    const fieldIsolatedFacets = useValue(
      "fieldIsolatedFacets",
      () => {
        const isolatedFacets: Record<string, ITopicDiscussionAnalysisFacets> = {};
        toolbar?.availableFields?.forEach((field) => {
          isolatedFacets[field] = {
            ...validMergedFacetValues,
            [field]: validBoundsFacetValues[field],
          };
        });
        return isolatedFacets;
      },
      [toolbar?.availableFields, validMergedFacetValues, validBoundsFacetValues],
    );

    const user = useAuthUser();
    const { data: configData, loading: configLoading } = useGetConfigDataQuery({
      variables: {
        key: `${CONFIG_KEY}`,
        organizationId: user?.tenantId,
      },
    });

    const [fetchTopicDiscussionExamples] = useTopicDiscussionExamplesLazyQuery();

    const { handleInputPointerDown } = useShapeEvents(shape.id);

    // Distinct values & field groups queries
    const fieldConfigAllFields = useValue(
      "fieldConfigAllFields",
      () => {
        return topicDiscussionAnalysisFieldsConfig.map((fieldConfig) => fieldConfig.field);
      },
      [topicDiscussionAnalysisFieldsConfig],
    );
    const { data: queryAllDistinctValues } = useFieldDistinctValuesQuery<ITopicDiscussionAnalysisFacets>(
      shape.type,
      fieldConfigAllFields,
      {},
      {},
      topicDiscussionAnalysisFieldsConfig,
    );
    const { data: queryAggregationResult } = useTopicDiscussionExampleAggregateQuery({
      variables: {
        facets: validBoundsFacetValues,
      },
      skip: !config || Object.keys(config).length === 0,
    });
    const { data: queryFieldGroupsResult } = useFieldGroupsQuery<ITopicDiscussionAnalysisFacets>(
      shape.type,
      toolbar?.availableFields,
      fieldIsolatedFacets,
      topicDiscussionAnalysisFieldsConfig,
    );

    // Format all distinct values
    const allDistinctValues = useValue(
      "allDistinctValues",
      () => {
        if (!queryAllDistinctValues) {
          return {} as Record<keyof ITopicDiscussionAnalysisFacets, any>;
        }
        const formattedDistinctValues = transformToToolbarSelectOptions(
          shape.type,
          queryAllDistinctValues,
          topicDiscussionAnalysisFieldsConfig,
        );

        return formattedDistinctValues;
      },
      [queryAllDistinctValues, shape, topicDiscussionAnalysisFieldsConfig],
    );

    // Formats bounds distinct values
    const boundsDistinctValues = useValue(
      "boundsDistinctValues",
      () => {
        if (!queryFieldGroupsResult) {
          return {} as Record<keyof ITopicDiscussionAnalysisFacets, any>;
        }

        const formattedBoundsDistinctValues = transformToToolbarSelectOptions(
          shape.type,
          queryFieldGroupsResult,
          topicDiscussionAnalysisFieldsConfig,
        );

        return formattedBoundsDistinctValues;
      },
      [queryFieldGroupsResult, shape, topicDiscussionAnalysisFieldsConfig],
    );

    // Facets update
    const fieldFacetsManager = useFieldFacetsManager(
      tldrawEditor,
      shape,
      selectedFacetValues,
      this.updateSelectedFacetValues,
      topicDiscussionAnalysisFieldsConfig,
    );
    const boundsFacetsManager = useFieldFacetsManager(
      tldrawEditor,
      shape,
      boundsFacetValues,
      this.updateBoundsFacetValues,
      topicDiscussionAnalysisFieldsConfig,
      false,
    );

    // Refs
    // Overlay chart refs
    const themePlotRef = useRef<HTMLDivElement | null>(null);
    const topicPlotRef = useRef<HTMLDivElement | null>(null);
    const speakersPlotRef = useRef<HTMLDivElement | null>(null);

    // State
    const [isFeedbackDialogOpen, setIsFeedbackDialogOpen] = useState<boolean>(false);
    const [isPreferencesDialogOpen, setIsPreferencesDialogOpen] = useState<boolean>(false);
    const [dataLoading, setDataLoading] = useState<boolean>(false);
    const [data, setData] = useState<Array<ITopicDiscussionExampleResult>>(
      DataUtils.getImmutableEmptyArray<ITopicDiscussionExampleResult>(),
    );
    const [filteredData, setFilteredData] = useState<Array<ITopicDiscussionExampleResult>>(data);
    const [toolbarContainerRef, setToolbarContainerRef] = useState<HTMLDivElement | null>(null);

    const childShapeTypes = useValue(
      "childShapeTypes",
      () => {
        const childIds = this.editor.getSortedChildIdsForParent(shape.id);
        return childIds.map((childId) => this.editor.getShape(childId)!.type);
      },
      [tldrawEditor, id],
    );

    // Tickers list also includes category, that helps to filter the tickers list for xDomainValues
    const [availableShapesMap, setAvailableShapesMap] = useState<Record<string, string>>({});
    const [selectedTheme, setSelectedTheme] = useState<string | null>(null);
    const [selectedShapeId, setSelectedShapeId] = useState<TLShapeId | null>(null);
    const [selectedTicker, setSelectedTicker] = useState<string | null>(null);
    const [isConfigSyncWithShapeProps, setIsConfigSyncWithShapeProps] = useState<boolean>(false);

    const topicDiscussionSummaryMasterDetailsRef = useRef<ITopicDiscussionSummaryMasterDetailsRef | null>(null);

    // Make sure we have an entry in the datastore for this shape
    const datastore = useBoardDatastore();

    // Set config data to local state
    useEffect(() => {
      if (configData?.Config?.data) {
        setConfig({
          ...JSON.parse(configData?.Config?.data || "{}"),
        } as ITopicDiscussionAnalysisConfig);
      }
    }, [configData, props.toolbar]);

    // Create the datastore state with our values
    useEffect(() => {
      if (!datastore.hasShapeData(shape.id)) {
        datastore.setShapeData(shape.id, {
          // Set the full data in the datastore
          allData: atom(`board.datastore.${shape.id}.allData`, data),
          config: atom(`board.datastore.${shape.id}.config`, config),
          configLoading: atom(`board.datastore.${shape.id}.configLoading`, configLoading),
          dataGridSelectedIds: atom(`board.datastore.${shape.id}.dataGridSelectedIds`, []),
          dataLoading: atom(`board.datastore.${shape.id}.dataLoading`, dataLoading),
          facets: atom(`board.datastore.${shape.id}.facets`, selectedFacetValues),
          filteredData: atom(`board.datastore.${shape.id}.filteredData`, data),
          getToolbar: atom(`board.datastore.${shape.id}.getToolbar`, () => null),
          onGroupBubbleChartAxisSelection: atom(`board.datastore.${shape.id}.onGroupBubbleChartAxisSelection`, () => null),
          onGroupBubbleChartExpandedGroupsChange: atom(
            `board.datastore.${shape.id}.onGroupBubbleChartExpandedGroupsChange`,
            () => null,
          ),
          onSelectedIdsChanged: atom(`board.datastore.${shape.id}.onSelectedIdsChanged`, () => null),
          onUpdatePreferences: atom(`board.datastore.${shape.id}.onUpdatePreferences`, () => null),
          preferences: atom(`board.datastore.${shape.id}.preferences`, props.preferences),
          scales: atom(`board.datastore.${shape.id}.scales`, {}),
          selection: atom(`board.datastore.${shape.id}.selection`, selection),
          xDomainValues: atom(`board.datastore.${shape.id}.xDomainValues`, []),
          yAxisTickFormat: atom(`board.datastore.${shape.id}.yAxisTickFormat`, () => ""),
          type: "topicDiscussionAnalysis",
        });
      }
    }, []);

    const fetchTopicDiscussionExamplesData = useCallback(async () => {
      setDataLoading(true);

      // Any changes in the process of getting the data must be handled in
      // the `getTopicDiscussionExamplesData` function since this function
      // is also used by the `BoardSearchManager` internally to get the filtered analysis data.
      return getTopicDiscussionExamplesData(apolloClient, validMergedFacetValues).finally(() => {
        setDataLoading(false);
      });
    }, [apolloClient, validMergedFacetValues]);

    // Fetch the data and set it in the state
    useEffect(() => {
      if (config && Object.keys(config).length > 0 && isConfigSyncWithShapeProps) {
        fetchTopicDiscussionExamplesData().then((topicDiscussionExamplesData) => {
          setData(topicDiscussionExamplesData);
        });
      }
    }, [isConfigSyncWithShapeProps, config, fetchTopicDiscussionExamplesData]);

    const fetchFilteredTopicDiscussionExamplesData = useCallback(async () => {
      // Any changes in the process of getting the filtered data must be handled in
      // the `getFilteredTopicDiscussionExamplesData` function since this function
      // is also used by the `BoardSearchManager` to get the filtered analysis data.
      return getFilteredTopicDiscussionExamplesData(apolloClient, validMergedFacetValues, selection);
    }, [apolloClient, validMergedFacetValues, selection]);

    // Fetch the filtered data and set it in the state
    useEffect(() => {
      if (config && Object.keys(config).length > 0 && isConfigSyncWithShapeProps) {
        fetchFilteredTopicDiscussionExamplesData().then((filteredTopicDiscussionExamplesData) => {
          setFilteredData(filteredTopicDiscussionExamplesData);
        });
      }
    }, [isConfigSyncWithShapeProps, config, fetchFilteredTopicDiscussionExamplesData]);

    useEffect(() => {
      this._updateTitleLabel(t, shape, selectedFacetValues, selection);
    }, [t, shape, selectedFacetValues, selection]);

    useEffect(() => {
      if (config && config.groupBubbleChart) {
        const { groupBubbleChart } = config;
        const { fields } = groupBubbleChart;
        const storeData = datastore.state.get()[shape.id].get() as ITopicDiscussionAnalysisShapeExternalData;
        const xDomainValues = this._getXDomainValues(data, fields.xField, fields.sortField);
        storeData.xDomainValues?.set(xDomainValues);
      }
    }, [data, config, queryFieldGroupsResult]);

    // Update the datastore config when it changes
    useEffect(() => {
      const shapeTypeLabelsMap: Record<string, string> = {};
      config?.allowedChildShapes?.forEach((shape) => {
        shapeTypeLabelsMap[shape.type] = t(`Components.ChartNames.${shape.label}`);
      });
      setAvailableShapesMap(shapeTypeLabelsMap);
      const storeData = datastore.state.get()[shape.id].get() as ITopicDiscussionAnalysisShapeExternalData;
      storeData.config.set(config);

      // Insert child shapes
      const childShapes = this.editor.getSortedChildIdsForParent(shape.id);
      if (config && Object.keys(config).length > 0 && childShapes.length === 0) {
        // Set initial state from the config
        this.editor.updateShapes([
          {
            id: shape.id,
            type: shape.type,
            props: {
              preferences: {
                ...(config.initialState?.preferences || {}),
              },
              selectedFacetValues: {
                ...config.initialState?.selectedFacetValues,
                ...selectedFacetValues,
              },
              boundsFacetValues: {
                ...(config.initialState?.boundsFacetValues || {}),
                ...boundsFacetValues,
              },
              toolbar: {
                ...config?.toolbar,
                ...toolbar,
              },
            },
          },
        ]);
        this._initialRenderOfChildren(shape, config, t);
        setIsConfigSyncWithShapeProps(true);
      } else if (config && Object.keys(config).length > 0 && childShapes.length > 0) {
        setIsConfigSyncWithShapeProps(true);
      }
    }, [config, selectedFacetValues, boundsFacetValues, toolbar]);

    // Update the datastore facets when they change
    useEffect(() => {
      const storeData = datastore.state.get()[shape.id].get() as ITopicDiscussionAnalysisShapeExternalData;
      storeData.facets.set(selectedFacetValues);
    }, [selectedFacetValues]);

    const {
      onTopicDiscussionSummaryMasterDetailsAddDocumentToBoard,
      onTopicDiscussionSummaryMasterDetailsAddToBoard,
      onTopicDiscussionSummaryMasterDetailsDownloadCsv,
      onTopicDiscussionSummaryMasterDetailsExportToExcel,
    } = useTopicDiscussionCompanionOverlay({
      config,
      detailColumnsConfig: overlayDetailFieldsConfig(t),
      detailGridPreferences: overlayDetailGridPreferences,
      facets: selectedFacetValues,
      filteredData,
      onAddItemsToBoard: this.onAddItemsToBoard.bind(this),
      shape,
      topicDiscussionSummaryMasterDetailsRef,
    });

    useEffect(() => {
      const selectedShape = selectedShapeId ? this.editor.getShape(selectedShapeId) : undefined;
      if (selectedTheme && selectedTicker && selectedShapeId && selectedShape) {
        const detailColumnsConfig = overlayDetailFieldsConfig(t);
        const titleText = t("Components.TopicDiscussionAnalysisCompanionOverlay.TitleLabel", {
          theme: selectedTheme,
          ticker: selectedTicker,
        });
        companionManager.showCompanion(
          selectedShapeId,
          {
            tabs: [
              {
                tabId: "overview",
                title: t("Components.TopicDiscussionAnalysisCompanionOverlay.TabLabel.Overview"),
                children: (
                  <TopicDiscussionSummaryOverview
                    config={{
                      topicChartConfig: topicOverviewChartConfig,
                      speakerChartConfig: speakersOverviewChartConfig,
                      themeChartConfig: themeOverviewChartConfig,
                    }}
                    fetchData={fetchTopicDiscussionExamples}
                    plotRefs={{
                      themePlotRef,
                      topicPlotRef,
                      speakersPlotRef,
                    }}
                    selectedDate={selectedFacetValues.eventDate}
                    selectedTheme={selectedTheme}
                    selectedTicker={selectedTicker}
                    topicDiscussionSummaryDetails={filteredData}
                  />
                ),
                actions: [
                  {
                    title: t("Global.Action.AddToBoard"),
                    onAction: () =>
                      addChartsToBoard(tldrawEditor, user?.accessToken, workspaceBoardId, titleText, [
                        {
                          label: t("Components.TopicDiscussionSummaryOverview.ThemeDiscussionDepth"),
                          ref: themePlotRef,
                        },
                        {
                          label: t("Components.TopicDiscussionSummaryOverview.TopicDiscussionDepth"),
                          ref: topicPlotRef,
                        },
                        {
                          label: t("Components.TopicDiscussionSummaryOverview.Speakers"),
                          ref: speakersPlotRef,
                        },
                      ]),
                    value: "AddToBoard",
                  },
                ],
              },
              {
                tabId: "details",
                title: t("Components.TopicDiscussionAnalysisCompanionOverlay.TabLabel.Details"),
                children: (
                  <TopicDiscussionSummaryMasterDetails
                    topicDiscussionSummaryDetails={filteredData}
                    columns={detailColumnsConfig}
                    preferences={overlayDetailGridPreferences}
                    ref={topicDiscussionSummaryMasterDetailsRef}
                  />
                ),
                actions: [
                  {
                    title: t("Global.Action.AddToBoard"),
                    onAction: () => onTopicDiscussionSummaryMasterDetailsAddToBoard(titleText),
                    value: "AddToBoard",
                  },
                  {
                    title: t("Global.Action.AddToBoardAsDocument"),
                    onAction: () => onTopicDiscussionSummaryMasterDetailsAddDocumentToBoard(),
                    value: "AddToBoardAsDocument",
                  },
                  {
                    title: t("Components.Charts.DownloadCsv", { count: filteredData.length }),
                    onAction: () => onTopicDiscussionSummaryMasterDetailsDownloadCsv(),
                    value: "DownloadCsv",
                  },
                  // {
                  //   title: t("Global.Action.ExportToExcel"),
                  //   onAction: () => onTopicDiscussionSummaryMasterDetailsExportToExcel(),
                  // },
                ],
              },
            ],
            title: (
              <Typography
                variant="h5"
                sx={{ flexGrow: 1, maxWidth: "870px", overflow: "hidden", textOverflow: "ellipsis", textWrap: "nowrap" }}
              >
                {titleText}
              </Typography>
            ),
          },
          {
            relativePosition: {
              x:
                (this.editor?.getPointInShapeSpace(selectedShape, this.editor?.inputs.currentPagePoint).x || 0) +
                OVERLAY_WINDOW_PADDING,
              y:
                (this.editor?.getPointInShapeSpace(selectedShape, this.editor?.inputs.currentPagePoint).y || 0) +
                OVERLAY_WINDOW_PADDING,
            },
          },
        );
      }
    }, [
      companionManager,
      fetchTopicDiscussionExamples,
      selectedFacetValues.eventDate,
      onTopicDiscussionSummaryMasterDetailsAddToBoard,
      selectedShapeId,
      onTopicDiscussionSummaryMasterDetailsAddDocumentToBoard,
      onTopicDiscussionSummaryMasterDetailsDownloadCsv,
      onTopicDiscussionSummaryMasterDetailsExportToExcel,
      selectedTheme,
      selectedTicker,
      filteredData,
      t,
      tldrawEditor,
      user,
      workspaceBoardId,
      themePlotRef,
      topicPlotRef,
      speakersPlotRef,
    ]);

    // Update the datastore data when it changes
    useEffect(() => {
      const storeData = datastore.state.get()[shape.id].get() as ITopicDiscussionAnalysisShapeExternalData;
      storeData.allData.set(data);
    }, [data]);

    // Update the datastore dataLoading when it changes
    useEffect(() => {
      const storeData = datastore.state.get()[shape.id].get() as ITopicDiscussionAnalysisShapeExternalData;
      storeData.dataLoading?.set(dataLoading);
    }, [dataLoading]);

    // Update the datastore configLoading when it changes
    useEffect(() => {
      const storeData = datastore.state.get()[shape.id].get() as ITopicDiscussionAnalysisShapeExternalData;
      storeData.configLoading?.set(configLoading);
    }, [configLoading]);

    // Update the datastore scales when data/config change
    useEffect(() => {
      const storeData = datastore.state.get()[shape.id].get() as ITopicDiscussionAnalysisShapeExternalData;
      storeData.scales?.set({
        // The barchart scale should always be based on the full data, so that it doesn't change when the user filters
        barChartScale: [queryAggregationResult?.barChartScale?.min, queryAggregationResult?.barChartScale?.max],
        bubbleChartScale: [queryAggregationResult?.bubbleChartScale?.min, queryAggregationResult?.bubbleChartScale?.max],
      });
    }, [queryAggregationResult]);

    // Update the datastore filtered data when it changes
    useEffect(() => {
      const storeData = datastore.state.get()[shape.id].get() as ITopicDiscussionAnalysisShapeExternalData;
      storeData.filteredData?.set(filteredData);
      storeData.dataGridSelectedIds?.set([]);
    }, [filteredData]);

    const onSelectedIdsChanged = useCallback(
      (selectedIds: Array<string>) => {
        const storeData = datastore.state.get()[shape.id].get() as ITopicDiscussionAnalysisShapeExternalData;
        storeData.dataGridSelectedIds?.set(selectedIds);
      },
      [datastore, shape],
    );

    // Update the datastore onSelectedIdsChanged method when it changes
    useEffect(() => {
      const storeData = datastore.state.get()[shape.id].get() as ITopicDiscussionAnalysisShapeExternalData;
      storeData.onSelectedIdsChanged?.set(onSelectedIdsChanged);
    }, [datastore, onSelectedIdsChanged]);

    const onUpdatePreferences = useCallback(
      (key: string, newPreferences: Record<string, any>) => {
        tldrawEditor.updateShapes([
          {
            id: shape.id,
            type: shape.type,
            props: {
              preferences: {
                ...shape.props.preferences,
                [key]: newPreferences,
              },
            },
          },
        ]);
      },
      [shape, tldrawEditor],
    );

    // Update the datastore onUpdatePreferences method when it changes
    useEffect(() => {
      const storeData = datastore.state.get()[shape.id].get() as ITopicDiscussionAnalysisShapeExternalData;
      storeData.onUpdatePreferences?.set(onUpdatePreferences);
    }, [datastore, onUpdatePreferences]);

    // Update the datastore preferences when they change
    useEffect(() => {
      const storeData = datastore.state.get()[shape.id].get() as ITopicDiscussionAnalysisShapeExternalData;
      storeData.preferences?.set(props.preferences || {});
    }, [datastore, props.preferences]);

    useEffect(() => {
      const storeData = datastore.state.get()[shape.id].get() as ITopicDiscussionAnalysisShapeExternalData;
      storeData.selection?.set(selection);

      // Close the companion overlay when no bubble selected or if facets changed
      if ((!selection?.selectedXAxis || (!selection?.selectedGroup && !selection?.selectedItem)) && companionManager.isOpen) {
        companionManager.raiseCompanionClosed();
      }
    }, [selection]);

    const onShowFeedbackDialog = useCallback(() => {
      setIsFeedbackDialogOpen(true);
    }, []);

    const onCloseFeedbackDialog = useCallback(() => {
      setIsFeedbackDialogOpen(false);
    }, []);

    const onShowPreferencesDialog = useCallback(() => {
      setIsPreferencesDialogOpen(true);
    }, []);

    const onClosePreferencesDialog = useCallback(() => {
      setIsPreferencesDialogOpen(false);
    }, []);

    // Create a callback to get the toolbar for child shapes to show
    const getToolbar = useCallback(() => {
      if (toolbarContainerRef === null) {
        return null;
      }

      // Take anlaysis preferences & facet sort
      const facetSort = analysisPreferences?.facetSort || [];
      const facetSortMap =
        facetSort.reduce(
          (acc, sort) => {
            if (sort && sort.field && sort.sort) {
              acc[sort.field] = sort.sort;
            }
            return acc;
          },
          {} as Record<string, FacetDisplaySortType>,
        ) || {};

      // Takes data store
      const storeData = datastore.state.get()[shape.id].get() as ITopicDiscussionAnalysisShapeExternalData;
      const dataGridSelectedIds = storeData.dataGridSelectedIds?.get() || [];
      // Split button options
      const options: SplitButtonProps["options"] = [
        {
          value: AnalysisToolbarActions.Reset,
          label: t("Components.Charts.Reset"),
          disabled: !selection.selectedXAxis && !selection.selectedGroup && !selection.selectedItem,
        },
        {
          value: AnalysisToolbarActions.DownloadCsv,
          label: t("Components.Charts.DownloadCsv", { count: dataGridSelectedIds.length || filteredData.length }),
        },
      ];
      const addToDocument = config.dataGrid?.addToDocument;
      if (addToDocument) {
        options.push({
          value: AnalysisToolbarActions.AddToDocument,
          label: t("Components.Charts.AddItems", { count: dataGridSelectedIds.length || filteredData.length }),
        });
      }

      options.push(
        {
          value: AnalysisToolbarActions.ProvideFeedback,
          label: t("Components.Charts.ProvideFeedback"),
          disabled: dataGridSelectedIds.length === 0 || filteredData.length === 0,
        },
        {
          value: AnalysisToolbarActions.Preferences,
          label: `${t("Components.Analyses.Common.PreferencesDialog.Preferences")}...`,
        },
      );

      return createPortal(
        <AnalysisToolbar<ITopicDiscussionAnalysisFacets>
          fields={toolbar?.availableFields || []}
          selectedFacetValues={selectedFacetValues}
          distinctValues={boundsDistinctValues as Record<keyof ITopicDiscussionAnalysisFacets, any>}
          dateRangeShortcuts={dateRangeShortcuts}
          fieldsConfig={topicDiscussionAnalysisFieldsConfig}
          fieldsSort={facetSortMap}
          onFieldChange={fieldFacetsManager.onFieldChange}
          onSortChange={_onFacetSortChange.bind(undefined, analysisPreferences)}
        >
          {analysisPreferences?.showDataToDisplayInToolbar && (
            <Grid key="availableShapes" item sx={{ mr: 6, padding: "10px", width: 150 }}>
              <FormControl sx={{ width: 140 }}>
                <InputLabel id={`available-shapes-${shape.id}`}>{t("Components.Charts.DataToDisplay")}</InputLabel>
                <Select
                  sx={{ width: 150 }}
                  id={`available-shapes-${shape.id}`}
                  label={t("Components.Charts.DataToDisplay")}
                  multiple
                  value={childShapeTypes || DataUtils.getImmutableEmptyArray<string>()}
                  onChange={(event) => {
                    this._onAvailableShapesChange(
                      t,
                      shape,
                      event.target.value as Array<string>,
                      childShapeTypes,
                      selectedFacetValues,
                      selection,
                      config,
                    );
                    if (companionManager.isOpen) {
                      companionManager.raiseCompanionClosed();
                    }
                  }}
                  renderValue={(selected) =>
                    t("Components.Charts.SelectedOfTotal", {
                      selected: selected.length,
                      total: config.allowedChildShapes?.length,
                    })
                  }
                  MenuProps={{
                    classes: { paper: "analysis-select-menu" },
                    MenuListProps: { classes: { root: "analysis-select-menu-list" } },
                  }}
                >
                  {config.allowedChildShapes?.map((allowedShape) => {
                    return (
                      <MenuItem
                        key={allowedShape.type}
                        value={allowedShape.type}
                        classes={{ selected: "analysis-select-menu-item" }}
                      >
                        <Checkbox checked={childShapeTypes.includes(allowedShape.type)} />
                        <ListItemText primary={t(`Components.ChartNames.${allowedShape.label}`)} />
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          )}
          <Grid key="actions" item sx={{ padding: "10px" }}>
            <SplitButton
              options={options}
              handleClick={(option: string) => {
                if (AnalysisToolbarActions.Preferences === option) {
                  onShowPreferencesDialog();
                } else {
                  this.onSplitButtonClick(
                    shape,
                    config,
                    filteredData,
                    selectedFacetValues,
                    dataGridSelectedIds,
                    t,
                    apolloClient,
                    option,
                    onShowFeedbackDialog,
                  );
                }

                if (option === AnalysisToolbarActions.Reset && companionManager.isOpen) {
                  companionManager.raiseCompanionClosed();
                }
              }}
            />
          </Grid>
        </AnalysisToolbar>,
        toolbarContainerRef,
      );
    }, [
      analysisPreferences,
      apolloClient,
      availableShapesMap,
      boundsDistinctValues,
      childShapeTypes,
      config,
      datastore,
      dateRangeShortcuts,
      filteredData,
      props,
      selectedFacetValues,
      selection,
      t,
      toolbarContainerRef,
    ]);

    // Update the datastore with the getToolbar callback
    useEffect(() => {
      const storeData = datastore.state.get()[shape.id].get() as ITopicDiscussionAnalysisShapeExternalData;
      storeData.getToolbar.set(getToolbar);
    }, [getToolbar]);

    const yAxisTickFormat = useCallback(
      (label: string) => {
        return ChartUtils.formatOverflowAxisLabel(label, config.fontSize, config.analysis?.approximateMarginLeft);
      },
      [config.fontSize],
    );

    // Update the yAxisTickFormat callback
    useEffect(() => {
      const storeData = datastore.state.get()[shape.id].get() as ITopicDiscussionAnalysisShapeExternalData;
      storeData.yAxisTickFormat?.set(yAxisTickFormat);
    }, [yAxisTickFormat]);

    const onGroupBubbleChartExpandedGroupsChange = useCallback(
      (expandedGroups: Array<string>) => {
        const storeData = datastore.state.get()[shape.id].get() as ITopicDiscussionAnalysisShapeExternalData;
        const { facetFields } = config.groupBubbleChart;
        this._updateSelection(shape, {
          ...storeData.selection?.get(),
          [facetFields.expandedGroupsFacetField]: expandedGroups,
        });
      },
      [config, datastore],
    );

    // Update the onGroupBubbleChartExpandedGroupsChange callback
    useEffect(() => {
      const storeData = datastore.state.get()[shape.id].get() as ITopicDiscussionAnalysisShapeExternalData;
      storeData.onGroupBubbleChartExpandedGroupsChange?.set(onGroupBubbleChartExpandedGroupsChange);
    }, [onGroupBubbleChartExpandedGroupsChange]);

    const onGroupBubbleChartAxisSelection = useCallback(
      (selectedShapeId: TLShapeId, axisSelection: { xAxis?: string | null; yGroup?: string; yItem?: string }) => {
        if (
          // When both "yGroup" and "xAxis" are selected, it means that a bubble is selected
          axisSelection.yGroup &&
          axisSelection.yGroup.trim().length > 0 &&
          axisSelection.xAxis &&
          axisSelection.xAxis.trim().length > 0
        ) {
          setSelectedTheme(axisSelection.yGroup);
          setSelectedTicker(axisSelection.xAxis);
          setSelectedShapeId(selectedShapeId);
        } else if (companionManager.isOpen) {
          companionManager.raiseCompanionClosed();
        }

        const storeData = datastore.state.get()[shape.id].get() as ITopicDiscussionAnalysisShapeExternalData;
        const { facetFields } = config.groupBubbleChart;
        this._updateSelection(shape, {
          ...storeData.selection?.get(),
          [facetFields.xAxisFacetField]: axisSelection.xAxis,
          [facetFields.yGroupFacetField]: axisSelection.yGroup,
          [facetFields.yItemFacetField]: axisSelection.yItem,
        });
      },
      [config, datastore],
    );

    // Update the onGroupBubbleChartAxisSelection callback
    useEffect(() => {
      const storeData = datastore.state.get()[shape.id].get() as ITopicDiscussionAnalysisShapeExternalData;
      storeData.onGroupBubbleChartAxisSelection?.set(onGroupBubbleChartAxisSelection);
    }, [onGroupBubbleChartAxisSelection]);

    // These are used by the feedback dialog
    const storeData = datastore?.state.get()[shape.id]?.get() as ITopicDiscussionAnalysisShapeExternalData;
    const dataGridSelectedIds = storeData?.dataGridSelectedIds?.get() || [];
    return (
      <>
        {isEditing || isSelected || isChildSelected ? (
          <SVGContainer>
            <DashedOutlineBox className="tl-group" bounds={bounds} zoomLevel={zoomLevel} />
          </SVGContainer>
        ) : null}
        <DataframeBackground
          enableBackgroundColor={!!props.enableBackground}
          enableBorder={!isHovered && !isEditing && !isSelected && !isChildSelected && !!props.enableBackground}
          bounds={bounds}
        />
        <HTMLContainer
          className="topic-discussion-analysis-shape"
          style={{
            background: "transparent",
            pointerEvents: "all",
          }}
        >
          <div onPointerDown={handleInputPointerDown}>
            <div
              ref={setToolbarContainerRef}
              style={{
                marginTop: -100,
                display: "flex",
                justifyContent: "center",
                position: "absolute",
                top: bounds.y,
                left: bounds.midX,
              }}
            />
            {(isEditing || isInteracting || isChildEditing || isChildInteracting) && getToolbar()}
            <AnalysisFeedbackDialog
              dataItemIds={dataGridSelectedIds}
              dataItemType={AnalysisFeedbackDataItemType.TopicDiscussionExample}
              onClose={onCloseFeedbackDialog}
              open={isFeedbackDialogOpen}
            />
            {isPreferencesDialogOpen && (
              <AnalysisPreferencesDialog<ITopicDiscussionAnalysisFacets>
                dateRangeShortcuts={dateRangeShortcuts}
                distinctValues={allDistinctValues as Record<keyof ITopicDiscussionAnalysisFacets, any>}
                selectedFacetValues={boundsFacetsManager.finalFacets}
                fields={props.selectedBoundsFields || ([] as Array<keyof ITopicDiscussionAnalysisFacets>)}
                fieldsConfig={topicDiscussionAnalysisFieldsConfig}
                onCancelPreferences={() => {
                  onClosePreferencesDialog();
                  boundsFacetsManager.onCancelFacets();
                }}
                onFieldChange={boundsFacetsManager.onFieldChange}
                open={isPreferencesDialogOpen}
                onClose={onClosePreferencesDialog}
                onSavePreferences={(toolbarPreferences) => {
                  onClosePreferencesDialog();
                  boundsFacetsManager.onSaveFacets();
                  this._saveToolbarPreferences(shape, toolbarPreferences, fieldFacetsManager);
                }}
                toolbarFields={toolbar?.availableFields || []}
                toolbarFieldFacets={selectedFacetValues}
                // Options props
                availableColors={availableColors}
                isBackgroundEnabled={props.enableBackground || false}
                isDataToDisplayInToolbarEnabled={analysisPreferences?.showDataToDisplayInToolbar || false}
                isSubItemsEnabled={analysisPreferences?.showQuestions || false}
                startColor={analysisPreferences?.startColor}
                subItemsLabel={t("Components.Charts.ShowItems", { label: t("Components.Charts.DefaultItemsLabel") })}
              />
            )}
          </div>
        </HTMLContainer>
      </>
    );

    // *********************************************
    // Render private methods
    // *********************************************/
    /**
     * Updates facet sort preferences for the given field.
     *
     * @param analysisPreferences Analysis preferences stored on shape props
     * @param fieldId Field id to update the facet sort updates
     * @param sort Latest sort
     */
    function _onFacetSortChange(
      analysisPreferences: ITopicDiscussionAnalysisPreferences["analysis"],
      fieldId: string,
      sort: FacetDisplaySortType,
    ) {
      const facetSort = [...(analysisPreferences?.facetSort || [])];
      let fieldIndex = facetSort.findIndex((sortOption) => sortOption.field === fieldId);
      if (fieldIndex > -1) {
        facetSort.splice(fieldIndex, 1, {
          field: fieldId,
          sort,
        });
      } else {
        facetSort.push({
          field: fieldId,
          sort,
        });
      }
      onUpdatePreferences("analysis", {
        ...analysisPreferences,
        facetSort,
      });
    }
  }

  // *********************************************
  // Protected methods, event handlers
  // *********************************************/
  /**
   * Clears the facets from axis(x/y) selection.
   *
   * @param shape The shape to update.
   */
  protected onClearSelection(shape: ITopicDiscussionAnalysisShape) {
    const selection = shape.props.selection;
    const newSelection: ITopicDiscussionAnalysisSelection = {
      ...selection,
      ...this.clearSelectionAttributes(),
    };

    this._updateSelection(shape, newSelection);
  }

  // *********************************************
  // Private methods, event handlers
  // *********************************************/
  /**
   * Initial creation of child shapes based on the config.
   *
   * @param shape Current shape
   * @param config Config to use
   * @param t Translation function
   */
  private _initialRenderOfChildren(
    shape: ITopicDiscussionAnalysisShape,
    config: ITopicDiscussionAnalysisConfig,
    t: TFunction<"translation", undefined>,
  ) {
    const allShapes = config.allowedChildShapes.map((allowedShape) => allowedShape.type);
    this._createChildShapes(shape, config.initialShapes || allShapes, config, t);
  }

  /**
   * Checks the existing child shapes & alters (delete/create) based on the latest user selection.
   *
   * @param shape Current shape.
   * @param value The latest available shapes slected from the dropdown.
   * @param childShapeTypes The current existing child shape types.
   */
  private _onAvailableShapesChange(
    t: TFunction<"translation", undefined>,
    shape: ITopicDiscussionAnalysisShape,
    value: Array<string>,
    childShapeTypes: Array<string>,
    facets: ITopicDiscussionAnalysisFacets,
    selection: ITopicDiscussionAnalysisSelection,
    config: ITopicDiscussionAnalysisConfig,
  ) {
    let shapeTypesToCreate: Array<string> = [];
    let shapeTypesToDelete: Array<string> = [];
    if (value.length === 0) {
      shapeTypesToDelete = childShapeTypes;
    } else if (childShapeTypes.length === 0) {
      shapeTypesToCreate = value;
    } else {
      value.forEach((v) => {
        if (!childShapeTypes.includes(v)) {
          shapeTypesToCreate.push(v);
        }
      });
      childShapeTypes.forEach((v) => {
        if (!value.includes(v)) {
          shapeTypesToDelete.push(v);
        }
      });
    }
    const shapeIdsToDelete = this.editor.getSortedChildIdsForParent(shape.id).filter((child) => {
      const childShape = this.editor.getShape(child);
      if (!childShape) {
        return false;
      }
      return shapeTypesToDelete.includes(childShape?.type);
    });
    this._createChildShapes(shape, shapeTypesToCreate, config, t);
    this.editor.deleteShapes(shapeIdsToDelete);
    if (shapeTypesToCreate.includes("lockedText")) {
      this._updateTitleLabel(t, shape, facets, selection);
    }
  }

  // *********************************************
  // Private methods
  // *********************************************/
  /**
   * Creates shapes for the given shape types.
   *
   * @param shape Current shape.
   * @param shapeTypes Shape types to create.
   */
  private _createChildShapes(
    shape: ITopicDiscussionAnalysisShape,
    shapeTypes: Array<string>,
    config: ITopicDiscussionAnalysisConfig,
    t: TFunction<"translation", undefined>,
  ) {
    const { id } = shape;
    const tldrawEditor = this.editor;
    const latestShape = tldrawEditor.getShape(id) as ITopicDiscussionAnalysisShape;
    // Takes latest props
    const preferences = latestShape?.props?.preferences;

    const bounds = this.getGeometry(shape).getBounds();
    const childShapeIds = this.editor.getSortedChildIdsForParent(id);
    const childShapes = childShapeIds.map((childShapeId) => this.editor.getShape(childShapeId));
    const allowedShapesInOrder = config.allowedChildShapes?.map((allowedShape) => allowedShape.type) || [];
    const betweenSpacing = 50;
    // Follows the order as per given config.allowedChildShapes
    const shapesToCreate = shapeTypes.map((shapeType) => {
      let y = bounds.y;
      if (childShapes.length > 0) {
        const shapeIndex = allowedShapesInOrder.indexOf(shapeType);
        const previousShape = childShapes.find((shape) => shape?.type === allowedShapesInOrder[shapeIndex - 1]);
        const nextShape = childShapes.find((shape) => shape?.type === allowedShapesInOrder[shapeIndex + 1]);

        if (previousShape) {
          const shapeProps = previousShape.props as Partial<TLShapeProps>;
          y = previousShape.y + (shapeProps.h || 0) + betweenSpacing;
        } else if (nextShape) {
          const shapeProps = nextShape.props as Partial<TLShapeProps>;
          y = nextShape.y - (shapeProps.h || 0) - betweenSpacing;
        }
      }

      const shapeId = createShapeId(uuidV4());

      let props: Record<string, any> = {};
      if (shapeType === "dataGrid") {
        props["config"] = {
          ...config.dataGrid,
          fontSize: config.fontSize,
          autoResize: true,
        };
        props["preferences"] = { ...preferences.dataGrid };
      } else if (shapeType === "lockedText") {
        props = { text: t("Components.Analyses.TopicDiscussionAnalysis.DefaultLabel"), align: "start" };
      }

      return {
        id: shapeId,
        parentId: id,
        type: shapeType,
        props,
        x: bounds.x,
        y: y,
      };
    });

    if (childShapeIds.length > 0) {
      this.editor.createShapes(shapesToCreate);
    } else {
      let previousShape: TLShape | undefined;
      // Create child shapes with y position based on previous shape
      shapesToCreate.forEach((shape) => {
        const latestApp = this.editor.createShapes([
          {
            ...shape,
            y: previousShape ? previousShape.y + betweenSpacing : 0,
          },
        ]);

        previousShape = latestApp.getShape(shape.id);
      });
    }
  }

  /**
   * Uses facets data to update the data frame title label.
   *
   * @param shape Current parent data frame shape.
   * @param facets Facets that changed.
   * @returns
   */
  private _updateTitleLabel(
    t: TFunction<"translation", undefined>,
    shape: ITopicDiscussionAnalysisShape,
    facets: ITopicDiscussionAnalysisFacets,
    selection: ITopicDiscussionAnalysisSelection,
  ) {
    const lockedTextShapeId = this.editor.getSortedChildIdsForParent(shape.id).find((childId) => {
      const childShape = this.editor.getShape(childId);
      return childShape?.type === "lockedText";
    });
    if (!lockedTextShapeId) {
      return;
    }
    const lockedTextShape = this.editor.getShape(lockedTextShapeId);
    const textLabel = this._formatTitleLabelText(t, facets, selection);

    if (!lockedTextShape) {
      return;
    }

    this.editor.updateShapes([
      {
        id: lockedTextShape.id,
        type: lockedTextShape.type,
        props: {
          text: textLabel,
        },
      },
    ]);
  }

  /**
   * Formats the title label text based on the facets.
   *
   * @param facets Active facets.
   *
   * @returns Formatted title label text.
   */
  private _formatTitleLabelText(
    t: TFunction<"translation", undefined>,
    facets: ITopicDiscussionAnalysisFacets,
    selection: ITopicDiscussionAnalysisSelection,
  ) {
    const template = Handlebars.compile(t("Components.Analyses.TopicDiscussionAnalysis.LabelTemplate"));

    // Registers the helpers
    Handlebars.registerHelper("formatCategories", (categories) => {
      return categories.map((category: string) => category.split(" - ")[1]).join(", ");
    });

    // Adds helpers to Handlebars
    Handlebars.registerHelper("hasEventDates", (eventDate: any, options: any) => {
      if (eventDate.from || eventDate.to) {
        return DataGridColumnFormatMapping[DataGridColumnFormat.DateRange](eventDate, t);
      } else {
        return;
      }
    });

    return template({
      ...facets,
      ...selection,
    });
  }

  /**
   * Updates the latest facets.
   *
   * @param shape Shape to update.
   * @param newFacets Latest facets to update.
   */
  private _updateFacets(shape: ITopicDiscussionAnalysisShape, newFacets: ITopicDiscussionAnalysisFacets) {
    this.editor.updateShapes([
      {
        id: shape.id,
        type: shape.type,
        props: {
          selectedFacetValues: {
            ...newFacets,
          },
        },
      },
    ]);
  }

  /**
   * Gives the clear selection related attributes.
   *
   * @returns Object with cleared selection attributes.
   */
  clearSelectionAttributes() {
    return {
      selectedXAxis: "",
      selectedGroup: "",
      selectedItem: "",
    };
  }

  /**
   * Updates selection for the given shape.
   *
   * @param shape Shape to update
   * @param selection New selection to update
   */
  private _updateSelection(shape: ITopicDiscussionAnalysisShape, selection: ITopicDiscussionAnalysisSelection) {
    this.editor.updateShapes([
      {
        id: shape.id,
        type: shape.type,
        props: {
          selection: {
            ...selection,
          },
        },
      },
    ]);
  }

  /**
   * Takes preferences from the dialog & updates to shape props.
   *
   * @param shape Shape to update
   * @param prefereces Selected preferences
   * @param fieldFacetsManager Field facet manager which helps to change the facet values
   */
  private _saveToolbarPreferences(
    shape: ITopicDiscussionAnalysisShape,
    prefereces: IAnalysisPreferencesDialogState,
    fieldFacetsManager: FieldFacetsManageStateType<ITopicDiscussionAnalysisFacets>,
  ) {
    const oldToolbarFields = shape.props.toolbar?.availableFields;
    const removedFields = oldToolbarFields?.filter((field) => !prefereces.toolbarFields.includes(field));
    this.editor.updateShapes([
      {
        id: shape.id,
        type: shape.type,
        props: {
          enableBackground: prefereces.isBackgroundEnabled,
          selectedBoundsFields: prefereces.toolbarBoundsFields,
          toolbar: {
            availableFields: prefereces.toolbarFields,
          },
          preferences: {
            ...shape.props.preferences,
            analysis: {
              ...shape.props.preferences.analysis,
              startColor: prefereces.startColor,
              showQuestions: prefereces.isSubItemsEnabled,
              showDataToDisplayInToolbar: prefereces.isDataToDisplayInToolbarEnabled,
            },
          },
        },
      },
    ]);

    fieldFacetsManager.onClearSelectedFacetValues(removedFields as Array<keyof ITopicDiscussionAnalysisFacets>);
  }

  /**
   * Extracts the x-axis domain values from the data by using xField & sorts them in the given field order.
   *
   * @param data Analysis data on which we can derive the x domain values.
   * @param xField Data field to take values from.
   * @param sortField Sort field to sort the domain values.
   * @returns Sorted x domain values
   */
  private _getXDomainValues(data: Array<Record<string, any>>, xField: string, sortField?: string) {
    const sortedData = [...data].sort(
      (a, b) => (sortField ? d3.ascending(a[sortField], b[sortField]) : 0) || d3.ascending(a[xField], b[xField]),
    );
    const xDomainValues = d3.group(sortedData, (d) => d[xField]);
    return [...xDomainValues.keys()];
  }
}
