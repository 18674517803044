import { UniqueID as TipTapUniqueID, UniqueIDOptions as TipTapUniqueIDOptions } from "@tiptap-pro/extension-unique-id";
import { Plugin, PluginKey } from "@tiptap/pm/state";
import { Decoration, DecorationSet } from "@tiptap/pm/view";

import { scrollEditorNodeIntoView } from "../../Utils/ScrollEditorNodeIntoView.js";

export interface IScrollBlockIntoViewOptions {
  /**
   * If true, and the editor is in editable mode, the cursor will be moved to
   * the start of the block after scrolling.
   */
  moveCursorToBlockStart?: boolean;
}

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    uniqueID: {
      /**
       * Replace the active block ids with the given block ids which will
       * visually highlight the blocks with the given ids.
       */
      activateBlocks: (blockIds: Array<string>) => ReturnType;
      /**
       * Removes visual highlight from all blocks.
       */
      clearActiveBlock: () => ReturnType;
      /**
       * Scrolls the block with the given id into view.
       */
      scrollBlockIntoView: (blockId: string, options: IScrollBlockIntoViewOptions | null) => ReturnType;
    };
  }
}

export interface UniqueIDOptions extends TipTapUniqueIDOptions {
  highlightClass: string;
}

export interface UniqueIDStorage {
  activeBlockIds: Array<string>;
}

export const UniqueID = TipTapUniqueID.extend<UniqueIDOptions, UniqueIDStorage>({
  addOptions() {
    return {
      ...this.parent?.(),
      highlightClass: "highlight-block",
    };
  },

  addStorage() {
    return {
      ...this.parent?.(),
      activeBlockIds: [],
    };
  },

  addCommands() {
    return {
      ...this.parent?.(),

      activateBlocks: (blockIds: Array<string>) => () => {
        this.storage.activeBlockIds = blockIds;
        return true;
      },

      clearActiveBlock: () => () => {
        this.storage.activeBlockIds = [];
        return true;
      },

      scrollBlockIntoView:
        (blockId: string, options: IScrollBlockIntoViewOptions | null) =>
        ({ chain, state, editor }) => {
          if (blockId.trim().length === 0) {
            return true;
          }

          const { doc } = state;

          let scrolled = false;
          doc.descendants((node, pos) => {
            if (!scrolled) {
              const idAttr: string = node.attrs["id"];
              if (idAttr && idAttr.trim() === blockId.trim()) {
                // "pos" refers to position right before the node, so we need to
                // add 1 to scroll the node into view.
                scrollEditorNodeIntoView(editor, pos + 1);

                // Move cursor at the start of the block if the editor is in editable mode.
                if (editor.isEditable && options?.moveCursorToBlockStart) {
                  chain().focus(null, { scrollIntoView: false }).setTextSelection(pos).run();
                }

                scrolled = true;
              }
            }
          });

          return true;
        },
    };
  },

  addProseMirrorPlugins() {
    return [
      ...(this.parent?.() || []),
      new Plugin({
        key: new PluginKey("highlightActiveBlock"),
        props: {
          decorations: ({ doc }) => {
            const decorations: Decoration[] = [];
            doc.descendants((node, pos) => {
              const blockId = node.attrs["id"];
              if (!blockId) {
                // Skip if the node doesn't have an id
                return;
              }

              // Check if the node has an active block ID
              const isActiveBlock = this.storage.activeBlockIds.includes(blockId);
              if (isActiveBlock) {
                // Add a decoration to highlight the node
                const decoration = Decoration.node(pos, pos + node.nodeSize, {
                  class: this.options.highlightClass,
                });
                decorations.push(decoration);
              }
            });

            return DecorationSet.create(doc, decorations);
          },
        },
      }),
    ];
  },
});
