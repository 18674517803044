import { Image as TipTapImage, ImageOptions as TipTapImageOptions } from "@tiptap/extension-image";
import { mergeAttributes } from "@tiptap/react";

import { getBaseClassWithoutImageAlignment, getImageAlignment } from "../../Utils/CommandUtil.js";
import { getNumericValueAttribute, getStringValueAttribute } from "../../Utils/ExtensionUtil.js";
import { dropImagePlugin } from "./DropImagePlugin.js";
import { setImage } from "./SetImageCommand.js";
import { setImageAlign } from "./SetImageAlignCommand.js";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    imageAlign: {
      /**
       * Set the image align attribute
       */
      setImageAlign: (alignment: ImageAlignmentType) => ReturnType;
    };
  }
}

export enum ImageAlignmentType {
  // Inline alignments
  ALIGN_LEFT = "align-left",
  ALIGN_RIGHT = "align-right",
  SIDE = "side",

  // Block alignments
  BLOCK_ALIGN_LEFT = "block-align-left",
  BLOCK_ALIGN_RIGHT = "block-align-right",
  BLOCK_ALIGN_CENTER = "block-align-center",
}

export const ALLOWED_IMAGE_INLINE_ALIGNMENTS = [
  ImageAlignmentType.ALIGN_LEFT,
  ImageAlignmentType.ALIGN_RIGHT,
  ImageAlignmentType.SIDE,
];

export const ALLOWED_IMAGE_BLOCK_ALIGNMENTS = [
  ImageAlignmentType.BLOCK_ALIGN_LEFT,
  ImageAlignmentType.BLOCK_ALIGN_RIGHT,
  ImageAlignmentType.BLOCK_ALIGN_CENTER,
];

export const ALLOWED_IMAGE_ALIGNMENTS = [...ALLOWED_IMAGE_BLOCK_ALIGNMENTS, ...ALLOWED_IMAGE_INLINE_ALIGNMENTS];

export interface ImageOptions extends TipTapImageOptions {
  alignments: Array<ImageAlignmentType>;
  defaultBlockAlignment: ImageAlignmentType;
  defaultInlineAlignment: ImageAlignmentType;
  uploadImage?: (image: File) => Promise<string>;
}

export const Image = TipTapImage.extend<ImageOptions, any>({
  // Image is an inline node
  inline: true,

  selectable: true,

  // Image is part of the "inline" group i.e. if a node defines "inline" in the content
  // expression then an "image" node can be a child of that node
  group: "inline",

  draggable: false,

  addOptions() {
    return {
      ...this.parent?.(),
      uploadImage: (image: File) => Promise.resolve(""),
      alignments: ALLOWED_IMAGE_ALIGNMENTS,
      defaultBlockAlignment: ImageAlignmentType.BLOCK_ALIGN_CENTER,
      defaultInlineAlignment: ImageAlignmentType.ALIGN_LEFT,
    };
  },

  addAttributes() {
    return {
      ...this.parent?.(),
      height: getNumericValueAttribute("height", null),
      width: getNumericValueAttribute("width", null),
      alt: {
        default: "",
        parseHTML: (element) => {
          return element.hasAttribute("alt") && typeof element.getAttribute("alt") === "string"
            ? element.getAttribute("alt")
            : "";
        },
      },
      src: getStringValueAttribute("src", null),
      imageAlign: {
        default: this.options.defaultBlockAlignment,
        parseHTML: (element) => {
          // Class names are in "image-style-{alignment}" pattern
          const imageAlign = getImageAlignment(element.className);
          const isBlockImage = element.parentNode?.nodeName === "FIGURE";
          const defaultAlignment = isBlockImage ? this.options.defaultBlockAlignment : this.options.defaultInlineAlignment;

          // Use default alignment if -
          // 1. If the alignment is not set OR
          // 2. If an inline alignment is set for a block image OR
          // 3. If a block alignment is set for an inline image
          if (
            !imageAlign ||
            (imageAlign && isBlockImage && !ALLOWED_IMAGE_BLOCK_ALIGNMENTS.includes(imageAlign as ImageAlignmentType)) ||
            (imageAlign && !isBlockImage && !ALLOWED_IMAGE_INLINE_ALIGNMENTS.includes(imageAlign as ImageAlignmentType))
          ) {
            return defaultAlignment;
          }

          return imageAlign;
        },
        renderHTML: (attributes) => {
          return { class: `image-style-${attributes.imageAlign}` };
        },
      },
      class: {
        default: null,
        parseHTML: (element: HTMLElement) => {
          const classAttr = element.hasAttribute("class") ? element.getAttribute("class") : null;

          // Keep all classes except for those that start with "image-style-"
          return getBaseClassWithoutImageAlignment(classAttr);
        },
      },
    };
  },

  renderHTML({ HTMLAttributes }) {
    return ["img", mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)];
  },

  addCommands() {
    return {
      setImage,
      setImageAlign,
    };
  },

  addProseMirrorPlugins() {
    return [dropImagePlugin(this.options.uploadImage)];
  },
});
