import { TFunction } from "i18next";

import { UsersQuery } from "GraphQL/Generated/Apollo";

/**
 * Gets the tooltip text of the reactions.
 *
 * @param reactionUserIds Reacted user ids.
 * @param users All users with their details.
 * @param currentUserId Current user id to check if the user is included in the reaction users.
 * @param t Translation function.
 *
 * @returns Returns tooltip text of the reactions.
 */
export function getReactionsTooltipText(
  reactionUserIds: Array<string>,
  users: UsersQuery["users"],
  currentUserId: string,
  t: TFunction,
): string {
  const isCurrentUserIncluded = reactionUserIds.includes(currentUserId);
  const otherUsers = reactionUserIds.filter((userId) => userId !== currentUserId);
  const otherUserNames = otherUsers.map((userId) => {
    const user = users?.find((user) => user.id === userId);
    return user?.name || t("Components.UserReactions.Unknown");
  });

  let tooltipText = "";
  if (reactionUserIds.length === 1 && isCurrentUserIncluded) {
    tooltipText = t("Components.UserReactions.JustYou");
  } else if (reactionUserIds.length > 1 && isCurrentUserIncluded) {
    tooltipText = t("Components.UserReactions.YouAndOthers", { val: otherUserNames });
  } else {
    tooltipText = t("Components.UserReactions.Others", { val: otherUserNames });
  }

  return tooltipText;
}
