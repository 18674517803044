import { CommandActionType } from "../CommandActionType.js";
import { CommandOutputType } from "../CommandOutputType.js";
import { ICommand } from "../ICommand.js";
import { DataType, EntityType } from "../ICommandInput.js";

export const COMMAND_REVERT_BOARD_QUESTIONNAIRE_SUGGESTION_TEMPLATE: ICommand = {
  id: "2c443de6-7a40-4dbe-8bfa-f7633731635f",
  name: "Reverts the applied suggestion from the associated board shape document",
  description: "",
  action: {
    actionType: CommandActionType.RevertWorkspaceBoardSuggestionAction,
    data: {},
  },
  inputs: [
    {
      dataType: {
        dataType: DataType.String,
        isArray: false,
        validation: "",
      },
      fieldName: "workspaceBoardSuggestionId",
      fieldNameAliases: [],
      entityType: EntityType.String,
      defaultValue: "",
      label: "",
      visible: false,
      required: true,
    },
  ],
  inputTemplate: `{ "workspaceBoardSuggestionId": "{{ workspaceBoardSuggestionId }}" }`,
  isBlocking: false,
  outputTypes: [CommandOutputType.None],
};
