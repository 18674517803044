/**
 * Represents the "special" selection config of the tags filter.
 */
export enum TagsFilterSelection {
  /**
   * All tags are selected.
   */
  All = "All",

  /**
   * No tags are selected.
   */
  None = "None",
}
