import { IUserReaction } from "@bigpi/cookbook";
import { useAuthUser } from "@frontegg/react";
import { Chip, Tooltip, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useUsersQuery, SortOrder } from "GraphQL/Generated/Apollo";
import { getReactionsTooltipText } from "./Utils";

export interface IUserReactionsProps {
  reactions: Array<IUserReaction>;
  onReactionClick: (reaction: string, event?: React.MouseEvent) => void;
}

export function UserReactions(props: IUserReactionsProps) {
  const { reactions, onReactionClick } = props;
  const { t } = useTranslation();
  const user = useAuthUser();
  // State
  const [groupedReactions, setGroupedReactions] = useState<Record<string, Array<string>>>({});

  const { data: userData } = useUsersQuery({
    variables: {
      orderBy: {
        name: SortOrder.Asc,
      },
    },
  });

  useEffect(() => {
    const groupedReactions: Record<string, Array<string>> = {};
    reactions.forEach((reaction) => {
      const user = userData?.users?.find((user) => user.id === reaction.userId);
      if (user) {
        if (!groupedReactions[reaction.reaction]) {
          groupedReactions[reaction.reaction] = [];
        }
        groupedReactions[reaction.reaction].push(user.id);
      }
    });
    setGroupedReactions(groupedReactions);
  }, [reactions, userData]);

  return (
    <Stack direction="row" justifyContent="space-around" spacing={1}>
      {Object.keys(groupedReactions).map((reaction) => {
        const reactedUserIds = groupedReactions[reaction];
        const tooltipText = getReactionsTooltipText(reactedUserIds, userData?.users, user.id, t);
        return (
          <Tooltip key={reaction} title={getTooltipTitle(reaction, tooltipText)} placement="top-start">
            <Chip
              label={t("Components.UserReactions.Reaction", { reaction, count: reactedUserIds.length })}
              onClick={(event) => {
                onReactionClick(reaction, event);
              }}
            />
          </Tooltip>
        );
      })}
    </Stack>
  );

  function getTooltipTitle(reaction: string, tooltipText: string) {
    return (
      <>
        <Typography variant="h4" sx={{ textAlign: "center" }}>
          {reaction}
        </Typography>
        <Typography variant="caption">{tooltipText}</Typography>
      </>
    );
  }
}
