// *********************************************
// Public constants
// *********************************************/
export const FILE_DOWNLOAD_PROXY_PATH = "file";
export const FILE_DOWNLOAD_ROUTE_TEMPLATE_CORE = `/${FILE_DOWNLOAD_PROXY_PATH}/:fileId`;
export const FILE_DOWNLOAD_ROUTE_TEMPLATE = `/${FILE_DOWNLOAD_PROXY_PATH}/files/:fileId/*`;

export const FILE_PREVIEW_DOWNLOAD_PROXY_PATH = `${FILE_DOWNLOAD_PROXY_PATH}/preview`;
export const FILE_PREVIEW_DOWNLOAD_ROUTE_TEMPLATE = `/${FILE_PREVIEW_DOWNLOAD_PROXY_PATH}/:organizationId/files/:fileId/*`;

export const DOCUMENT_IMAGE_DOWNLOAD_PROXY_PATH = "doc-image";
export const DOCUMENT_IMAGE_DOWNLOAD_ROUTE_TEMPLATE = `/${DOCUMENT_IMAGE_DOWNLOAD_PROXY_PATH}/:organizationId/documents/:documentId/images/:imageFilename`;

export const EXPORT_TO_WORD_PROXY_PATH = "export-to-word";
export const EXPORT_TO_WORD_ROUTE_TEMPLATE = `/${EXPORT_TO_WORD_PROXY_PATH}`;

export const WEBHOOK_API_GW_PROXY_PATH = "pantry-webhook";
export const WEBHOOK_PROXY_PATH = "webhook";
export const WEBHOOK_FRONTEGG_ROUTE_TEMPLATE = `/${WEBHOOK_PROXY_PATH}/frontegg`;

export const WORKSPACE_ASSET_DOWNLOAD_PROXY_PATH = "wsb-asset";
export const WORKSPACE_ASSET_DOWNLOAD_ROUTE_TEMPLATE = `/${WORKSPACE_ASSET_DOWNLOAD_PROXY_PATH}/workspaces/:workspaceId/workspaceBoards/:workspaceBoardId/assets/:filename`;

export const WORKSPACE_BOARD_SHAPE_ASSET_DOWNLOAD_PROXY_PATH = "wsb-shape-asset";
export const WORKSPACE_BOARD_SHAPE_ASSET_DOWNLOAD_ROUTE_TEMPLATE = `/${WORKSPACE_BOARD_SHAPE_ASSET_DOWNLOAD_PROXY_PATH}/workspaces/:workspaceId/workspaceBoards/:workspaceBoardId/shapes/:shapeId/:imageFilename`;

export const WORKSPACE_FILE_DOWNLOAD_PROXY_PATH = "workspace-file";
export const WORKSPACE_FILE_DOWNLOAD_ROUTE_TEMPLATE = `/${WORKSPACE_FILE_DOWNLOAD_PROXY_PATH}/workspaces/:workspaceId/files/:workspaceFileId/:filename`;

export const WORKSPACE_FILE_PREVIEW_DOWNLOAD_PROXY_PATH = `${WORKSPACE_FILE_DOWNLOAD_PROXY_PATH}/preview`;
export const WORKSPACE_FILE_PREVIEW_DOWNLOAD_ROUTE_TEMPLATE = `/${WORKSPACE_FILE_PREVIEW_DOWNLOAD_PROXY_PATH}/:organizationId/workspaces/:workspaceId/files/:workspaceFileId/*`;
