/**
 * Shape tags.
 */
export enum ShapeTag {
  /**
   * The shape is a knowledge base article.
   */
  KnowledgeBaseArticle = "KnowledgeBaseArticle",

  /**
   * The shape is a plan document. Normally used as part of instructions for questionnaire answering/completion.
   */
  Plan = "Plan",

  /**
   * The shape is a questionnaire.
   */
  Questionnaire = "Questionnaire",

  /**
   * The shape is read only.
   */
  ReadOnly = "ReadOnly",
}
