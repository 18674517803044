import { CommandActionType } from "../CommandActionType.js";
import { CommandOutputType } from "../CommandOutputType.js";
import { ICommand } from "../ICommand.js";

export const COMMAND_GENERATE_BOARD_QUESTIONNAIRE_SUGGESTIONS_TEMPLATE: ICommand = {
  id: "92c08761-b42b-4a56-92cb-34faccde161e",
  name: "Suggest answers to all questions in the document",
  description: "",
  action: {
    actionType: CommandActionType.SuggestAllAnswersAction,
    data: {},
  },
  inputs: [],
  inputTemplate: "",
  isBlocking: false,
  outputTypes: [CommandOutputType.None],
};
