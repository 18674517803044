import { ToggleButton, ToggleButtonGroup, ToggleButtonTypeMap } from "@mui/material";
import { useTranslation } from "react-i18next";

import { SuggestionStatus } from "GraphQL/Generated/Apollo";
import { IStatusCount } from "./WorkspaceBoardSuggestions";

function suggestionStatusToColor(status: SuggestionStatus): ToggleButtonTypeMap["props"]["color"] {
  switch (status) {
    case SuggestionStatus.Accepted:
      return "stateAccepted";
    case SuggestionStatus.Draft:
      return "stateDraft";
    case SuggestionStatus.Dismissed:
      return "stateDismissed";
    case SuggestionStatus.New:
      return "stateNew";
    case SuggestionStatus.Rejected:
      return "stateRejected";
  }
}

interface IWorkspaceBoardSuggestionStatusListProps {
  currentPositionOnly: boolean;
  filteredCount: number;
  currentStatusCounts: Array<IStatusCount>;
  onStatusClick: (status: SuggestionStatus) => void;
  selectedStatus?: SuggestionStatus;
  totalCount: number;
}

const statusList = [SuggestionStatus.New, SuggestionStatus.Draft, SuggestionStatus.Accepted, SuggestionStatus.Dismissed];

export function WorkspaceBoardSuggestionStatusList(props: IWorkspaceBoardSuggestionStatusListProps) {
  const { currentPositionOnly, filteredCount, currentStatusCounts, onStatusClick, selectedStatus, totalCount } = props;
  const { t } = useTranslation();
  let allLabel = "";

  if (currentPositionOnly) {
    allLabel = t(`Components.WorkspaceBoardSuggestion.StatusWithCount.AllFiltered`, { count: filteredCount, totalCount });
  } else {
    allLabel = t(`Components.WorkspaceBoardSuggestion.StatusWithCount.All`, { count: totalCount });
  }

  return (
    <ToggleButtonGroup exclusive value={selectedStatus || ""} onChange={(e, status) => onStatusClick(status)}>
      <ToggleButton color="standard" value="" size="small">
        {allLabel}
      </ToggleButton>
      {statusList.map((status, index) => {
        const statusCount = currentStatusCounts?.find((fg) => fg.status.toLowerCase() === status.toLowerCase());
        const count = statusCount?.count || 0;

        return (
          <ToggleButton
            color={suggestionStatusToColor(status)}
            value={status}
            key={index}
            size="small"
            disabled={count === 0}
            sx={{
              textDecoration: count === 0 ? "line-through" : "none",
            }}
          >
            {t(`Components.WorkspaceBoardSuggestion.StatusWithCount.${status}`, { count })}
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
}
