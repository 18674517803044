import { gql } from "@apollo/client";

export const COMMAND_STATUS_SUBSCRIPTION = gql`
  subscription OnCommandStatusChanged($id: ID!) {
    onCommandStatusChanged(id: $id) {
      commandId
      requestId
      status
    }
  }
`;
