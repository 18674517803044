import { TFunction } from "i18next";

import { Config } from "Config";
import { FilesQuery } from "GraphQL/Generated/Apollo";

/**
 * Converts given data url to file
 *
 * @param dataurl Data URL to convert to file
 * @param filename File name
 * @param type File type
 * @returns
 */
export function dataUrltoFile(dataUrl: string, filename: string, type: string) {
  const arr = dataUrl.split(",");
  const bstr = atob(arr[arr.length - 1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type });
}

export function formatFileCreatedAt(CreatedAt: FilesQuery["files"][number]["createdAt"], t: TFunction) {
  const daysSince = Math.floor((Date.now() - new Date(CreatedAt).getTime()) / (1000 * 60 * 60 * 24));
  switch (daysSince) {
    case 0:
      return t("Pages.Library.List.CreatedAt.CreatedToday");
    case 1:
      return t("Pages.Library.List.CreatedAt.CreatedYesterday");
    default:
      return t("Pages.Library.List.CreatedAt.CreatedDaysAgo", { days: daysSince });
  }
}
