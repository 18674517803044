import { Plugin, PluginKey } from "@tiptap/pm/state";
import { EditorView } from "@tiptap/pm/view";

import { ActionsPluginProps, ActionsView } from "../../ActionMenus/ActionsPluginView.js";

export interface ImageActionsPluginProps extends ActionsPluginProps {}

type ImageActionsViewProps = ImageActionsPluginProps & {
  view: EditorView;
};

class ImageActionsView extends ActionsView {
  public shouldShow: Exclude<ImageActionsPluginProps["shouldShow"], null> = ({ view, state, editor }) => {
    const { selection } = state;
    const { $anchor } = selection;
    const isAnImage =
      ($anchor.parent.type.name === "figure" && $anchor.nodeAfter?.type.name === "image") ||
      $anchor.nodeAfter?.type.name === "image";

    if (!view.hasFocus() || !isAnImage || !editor.isEditable) {
      return false;
    }

    return true;
  };

  constructor(imageActionsViewProps: ImageActionsViewProps) {
    super(imageActionsViewProps);
  }
}

// Plugin to show image actions on click
export const ImageActionsPlugin = (options: ImageActionsPluginProps) => {
  return new Plugin({
    key: typeof options.pluginKey === "string" ? new PluginKey(options.pluginKey) : options.pluginKey,
    view: (view) => new ImageActionsView({ view, ...options }),
  });
};
