import { gql } from "@apollo/client";

export const WORKSPACE_BOARD_TABLE_OF_CONTENTS_REACTION_CREATE_SUBSCRIPTION = gql`
  subscription OnWorkspaceBoardTableOfContentsReactionCreate($shapeId: ID!, $workspaceBoardId: ID!) {
    onWorkspaceBoardTableOfContentsReactionCreate(shapeId: $shapeId, workspaceBoardId: $workspaceBoardId) {
      id
      reaction
      userId
      workspaceBoardTableOfContentsId
    }
  }
`;

export const WORKSPACE_BOARD_TABLE_OF_CONTENTS_REACTION_DELETE_SUBSCRIPTION = gql`
  subscription OnWorkspaceBoardTableOfContentsReactionDelete($shapeId: ID!, $workspaceBoardId: ID!) {
    onWorkspaceBoardTableOfContentsReactionDelete(shapeId: $shapeId, workspaceBoardId: $workspaceBoardId) {
      id
    }
  }
`;
