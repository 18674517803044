import { Workspaces } from "@mui/icons-material";
import { Box } from "@mui/material";

import { useWorkspaceBoardThumbnailQuery } from "GraphQL/Generated/Apollo";
import { getImageSrcFromImageBase64 } from "Utils/DomToImageUtils";

export interface IWorkspaceBoardThumbnailProps {
  workspaceBoardId?: string;
}

export function WorkspaceBoardThumbnail(props: IWorkspaceBoardThumbnailProps) {
  const { workspaceBoardId } = props;

  const { data, loading } = useWorkspaceBoardThumbnailQuery({
    variables: {
      id: workspaceBoardId || "",
    },
    skip: !workspaceBoardId,
  });

  const thumbnail = data?.workspaceBoard?.thumbnail;

  if (workspaceBoardId && !loading && thumbnail) {
    return <img width="100%" style={{ padding: "6px" }} src={getImageSrcFromImageBase64(thumbnail)} />;
  } else {
    return (
      <Box sx={{ width: "100%" }}>
        <Workspaces sx={{ color: "rgba(0, 0, 0, 0.54)" }} />
      </Box>
    );
  }
}
