import { AwesomeGraphQLClient } from "awesome-graphql-client";
import {
  UploadWorkspaceBoardAssetDocument,
  UploadDocumentImageDocument,
  UploadWorkspaceBoardShapeAssetDocument,
} from "GraphQL/Generated/Requests";

function createPantryUploadClient(apiGwUrl: string, accessToken: string) {
  return new AwesomeGraphQLClient({
    endpoint: `${apiGwUrl}/pantry-upload`,
    fetchOptions: {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    },
  });
}

export function getUploadDocumentImageFn(apiGwUrl: string, getAccessToken: () => string, documentId: string) {
  return async function uploadDocumentImage(image: File): Promise<string> {
    const client = createPantryUploadClient(apiGwUrl, getAccessToken());
    const result = await client.request(UploadDocumentImageDocument, {
      file: image,
      documentId,
    });

    return result.uploadDocumentImage?.assetDownloadUrl ?? "";
  };
}

export function getUploadWorkspaceBoardAssetFn(apiGwUrl: string, getAccessToken: () => string, workspaceBoardId: string) {
  return async function uploadWorkspaceBoardAsset(image: File): Promise<string> {
    const client = createPantryUploadClient(apiGwUrl, getAccessToken());
    const result = await client.request(UploadWorkspaceBoardAssetDocument, {
      file: image,
      workspaceBoardId,
    });

    return result.uploadWorkspaceBoardAsset?.storageLocationUrl ?? "";
  };
}

export function getUploadWorkspaceBoardShapeAssetFn(
  apiGwUrl: string,
  getAccessToken: () => string,
  workspaceBoardId: string,
  shapeId: string,
) {
  return async function uploadWorkspaceBoardShapeAsset(file: File): Promise<string> {
    const client = createPantryUploadClient(apiGwUrl, getAccessToken());
    const result = await client.request(UploadWorkspaceBoardShapeAssetDocument, {
      file,
      workspaceBoardId,
      shapeId,
    });

    return result.uploadWorkspaceBoardShapeAsset?.assetDownloadUrl ?? "";
  };
}
