import { FormControl, MenuItem, OutlinedInput, Select } from "@mui/material";
import { useTranslation } from "react-i18next";

// *********************************************
// Types/Interfaces/Constants/Enums
// *********************************************/
interface IPaginationOptions {
  onPaginationValueChange: (value: number) => void;
  pageSize: number;
  pageSizeOptions: Array<number>;
}

// *********************************************
// Component
// *********************************************/
export function PaginationOptions(props: IPaginationOptions) {
  const { onPaginationValueChange, pageSize, pageSizeOptions } = props;
  const { t } = useTranslation();

  return (
    <FormControl size="small">
      <Select
        displayEmpty
        id="pagination-option"
        notched
        onChange={(e) => onPaginationValueChange(e.target.value as number)}
        sx={{ maxWidth: "240px" }}
        input={<OutlinedInput />}
        value={pageSize}
        autoWidth
      >
        {pageSizeOptions.map((pageSize) => (
          <MenuItem key={pageSize} value={pageSize}>
            {t(`Components.ContentFilterPanel.Pagination.PageSize`, { count: pageSize })}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
