import { SuggestionStatus } from "GraphQL/Generated/Apollo";

// Should this be in cookbook in case used in multiple places?
export enum SuggestionStatusAction {
  Accept = "Accept",
  Delete = "Delete",
  Dismiss = "Dismiss",
  Revert = "Revert",
}

export function getSuggestionStatusRelatedActions(status: SuggestionStatus) {
  switch (status) {
    case SuggestionStatus.Draft:
      return [SuggestionStatusAction.Delete];
    case SuggestionStatus.New:
      return [SuggestionStatusAction.Delete, SuggestionStatusAction.Dismiss, SuggestionStatusAction.Accept];
    case SuggestionStatus.Accepted:
      return [SuggestionStatusAction.Delete, SuggestionStatusAction.Revert];
    case SuggestionStatus.Dismissed:
      return [SuggestionStatusAction.Delete];
    default:
      return [];
  }
}
