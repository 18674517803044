import { Chip, ChipProps, ChipTypeMap } from "@mui/material";

import { SuggestionStatus } from "GraphQL/Generated/Apollo";

function suggestionStatusToColor(status: SuggestionStatus): ChipTypeMap["props"]["color"] {
  switch (status) {
    case SuggestionStatus.Accepted:
      return "stateAccepted";
    case SuggestionStatus.Draft:
      return "stateDraft";
    case SuggestionStatus.Dismissed:
      return "stateDismissed";
    case SuggestionStatus.New:
      return "stateNew";
    case SuggestionStatus.Rejected:
      return "stateRejected";
  }
}

interface ISuggestionStatusChipProps {
  label: string;
  onClick?: (status: SuggestionStatus) => void;
  status: SuggestionStatus;
  sx?: ChipProps["sx"];
}

export function SuggestionStatusChip(props: ISuggestionStatusChipProps) {
  const { label, onClick, status, sx } = props;

  return (
    <Chip
      clickable={onClick ? true : false}
      color={suggestionStatusToColor(status)}
      label={label}
      sx={{
        textTransform: "uppercase",
        ...sx,
      }}
      onClick={() => onClick && onClick(status)}
      variant="filled"
    />
  );
}
