import { FileSourceType, getFilePreviewDownloadUrl } from "@bigpi/cookbook";
import { useAuthUser } from "@frontegg/react";

import { Config } from "Config";
import { useFileQuery, useWorkspaceFileQuery } from "GraphQL/Generated/Apollo";
import { getWorkspaceFilePreviewDownloadUrl } from "Utils/WorkspaceFileUtils";

export interface IFilePreviewData {
  fileName: string;
  loading: boolean;
  url: string;
}

/**
 * Hook to get the file preview details (file name & url).
 *
 * @param fileId File ID to fetch the details.
 * @param fileType Enum (Library/WorkspaceFile) to generate specific file preview.
 *
 * @returns File name & URL to preview the file.
 */
export function useFilePreviewData(fileId: string, fileType: FileSourceType): IFilePreviewData {
  const user = useAuthUser();

  const { data: fileData, loading: loadingFile } = useFileQuery({
    variables: {
      id: fileId,
    },
    skip: fileType === FileSourceType.WorkspaceFile,
  });

  const { data: workspaceFileData, loading: loadingWorkspaceFile } = useWorkspaceFileQuery({
    variables: {
      id: fileId,
    },
    skip: fileType === FileSourceType.Library,
  });

  let result: IFilePreviewData = {
    fileName: "",
    loading: true,
    url: "",
  };

  if (fileType === FileSourceType.Library) {
    if (fileData?.file) {
      result = {
        fileName: fileData?.file?.name,
        loading: loadingFile,
        url: getFilePreviewDownloadUrl(Config.previewHttpUrl, user.tenantId, fileId, "index.html"),
      };
    } else {
      result.loading = loadingFile;
    }
  } else if (fileType === FileSourceType.WorkspaceFile) {
    if (workspaceFileData?.workspaceFile) {
      result = {
        fileName: workspaceFileData?.workspaceFile?.name,
        loading: loadingWorkspaceFile,
        url: getWorkspaceFilePreviewDownloadUrl(
          user.tenantId,
          workspaceFileData?.workspaceFile?.workspaceId,
          fileId,
          "index.html",
        ),
      };
    } else {
      result.loading = loadingWorkspaceFile;
    }
  }

  return result;
}
