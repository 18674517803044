import { ItemGridSize, ItemViewType } from "@bigpi/cookbook";
import { Box, Button } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { WorkspaceBoardGrid } from "./WorkspaceBoardGrid";
import { WorkspaceBoardList } from "./WorkspaceBoardList";
import { useWorkspaceBoardsQuery } from "GraphQL/Generated/Apollo";

const DEFAULT_PAGE_SIZE = 20;

export interface IWorkspaceBoardViewProps {
  gridSize: ItemGridSize;
  onGridSizeChange: (gridSize: ItemGridSize) => void;
  filterTags: Array<string>;
  includeUntagged: boolean;
  viewType: ItemViewType;
  workspaceId: string;
  searchValue: string;
}

export function WorkspaceBoardView(props: IWorkspaceBoardViewProps) {
  const { gridSize, onGridSizeChange, filterTags, includeUntagged, viewType, workspaceId, searchValue } = props;

  const { t } = useTranslation();

  const [offset, setOffset] = useState(0);
  const [orderBy, setOrderBy] = useState<Record<string, string>>({ updatedAt: "desc" });

  const {
    data: workspaceBoardsData,
    loading: workspaceBoardsLoading,
    fetchMore: fetchMoreWorkspaceBoards,
    error: workspaceBoardsError,
  } = useWorkspaceBoardsQuery({
    variables: {
      workspaceId,
      limit: DEFAULT_PAGE_SIZE,
      offset,
      orderBy,
      filters: {
        tags: filterTags,
        includeUntagged,
        searchTerm: searchValue,
      },
    },
  });
  const boards = workspaceBoardsData?.workspaceBoards || [];
  const boardCount = workspaceBoardsData?.workspaceBoardAggregate.count || 0;

  return (
    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
      <Box sx={{ display: "flex", p: 3, justifyContent: "center" }}>
        {viewType === ItemViewType.List ? (
          <WorkspaceBoardList
            workspaceId={workspaceId}
            rows={boards}
            error={workspaceBoardsError}
            loading={workspaceBoardsLoading}
          />
        ) : (
          <WorkspaceBoardGrid
            rows={boards}
            error={workspaceBoardsError}
            loading={workspaceBoardsLoading}
            gridSize={gridSize}
            onGridSizeChange={onGridSizeChange}
          />
        )}
      </Box>

      {boardCount > boards.length && (
        <Button
          sx={{ alignSelf: "center", maxWidth: "150px" }}
          onClick={() => {
            fetchMoreWorkspaceBoards({
              variables: {
                offset: boards.length,
                filters: {
                  tags: filterTags,
                  includeUntagged,
                  searchTerm: searchValue,
                },
              },
            });
          }}
        >
          {t("Components.WorkspaceBoardView.ShowMore")}
        </Button>
      )}
    </Box>
  );
}
