import { FileSourceType, parseUrn, UrnObjectType } from "@bigpi/cookbook";
import { Article, Folder, Workspaces } from "@mui/icons-material";
import { Box, Link, Tooltip } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useDocumentLazyQuery, useFileLazyQuery, useWorkspaceFileLazyQuery } from "GraphQL/Generated/Apollo";
import { FilePreviewDialog, IFilePreviewDetails } from "Components/FilePreviewDialog/FilePreviewDialog";
import { DocumentPreviewDialog } from "Components/DocumentPreviewDialog/DocumentPreviewDialog";

export interface ISuggestionReferenceProps {
  excerpt: string;
  sourceUri: string;
  title: string;
  workspaceId: string;
}

export function SuggestionReference(props: ISuggestionReferenceProps) {
  const { excerpt, sourceUri, title, workspaceId } = props;
  const [isUriValid, setIsUriValid] = useState(false);
  const [icon, setIcon] = useState<React.ReactNode | null>(null);
  const { t } = useTranslation();

  const [filePreviewDetails, setFilePreviewDetails] = useState<IFilePreviewDetails | null>(null);
  const [filePreviewDialogOpen, setFilePreviewDialogOpen] = useState<boolean>(false);
  const [documentPreviewId, setDocumentPreviewId] = useState<string | null>(null);
  const [documentPreviewDialogOpen, setDocumentPreviewDialogOpen] = useState<boolean>(false);
  const [getDocument, { loading: documentDataLoading }] = useDocumentLazyQuery();
  const [getFile, { loading: fileDataLoading }] = useFileLazyQuery();
  const [getWorkspaceFile, { loading: workspaceFileDataLoading }] = useWorkspaceFileLazyQuery();

  const linkTitle = title || t("Components.WorkspaceBoardSuggestion.References.UnnamedSource");
  useEffect(() => {
    // Check if we have a valid and accessible sourceUri
    if (sourceUri) {
      try {
        const parsedUrn = parseUrn(sourceUri);

        switch (parsedUrn.objectType) {
          case UrnObjectType.Document:
            getDocument({
              variables: {
                id: parsedUrn.documentId,
              },
            }).then(({ data }) => {
              if (data?.document) {
                setIsUriValid(true);

                setIcon(<Article color="disabled" />);
                setDocumentPreviewId(data.document.id);
                setFilePreviewDetails(null);
              }
            });
            break;
          case UrnObjectType.File:
            getFile({
              variables: {
                id: parsedUrn.fileId,
              },
            }).then(({ data }) => {
              if (data?.file) {
                setIsUriValid(true);

                setIcon(<Folder color="disabled" />);
                setFilePreviewDetails({
                  fileId: data.file.id,
                  fileSourceType: FileSourceType.Library,
                });
                setDocumentPreviewId(null);
              }
            });
            break;
          case UrnObjectType.WorkspaceFile:
            getWorkspaceFile({
              variables: {
                id: parsedUrn.workspaceFileId,
              },
            }).then(({ data }) => {
              if (data?.workspaceFile?.workspaceId === workspaceId) {
                setIsUriValid(true);

                setIcon(<Workspaces color="disabled" />);
                setFilePreviewDetails({
                  fileId: data.workspaceFile.id,
                  fileSourceType: FileSourceType.WorkspaceFile,
                });
                setDocumentPreviewId(null);
              }
            });
            break;
        }
      } catch (e) {
        setIsUriValid(false);
        setDocumentPreviewId(null);
        setFilePreviewDetails(null);
      }
    } else {
      setIsUriValid(false);
      setDocumentPreviewId(null);
      setFilePreviewDetails(null);
    }
  }, [sourceUri, workspaceId]);

  const onLinkClicked = useCallback(() => {
    if (documentPreviewId) {
      setDocumentPreviewDialogOpen(true);
    } else if (filePreviewDetails) {
      setFilePreviewDialogOpen(true);
    }
  }, [documentPreviewId, filePreviewDetails]);

  return (
    <>
      <Tooltip title={excerpt}>
        {/* Allow tooltip with disabled link */}
        <div>
          <Link component="button" sx={{ textAlign: "left" }} disabled={!isUriValid} onClick={onLinkClicked}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {icon}
              {linkTitle}
            </Box>
          </Link>
        </div>
      </Tooltip>
      {filePreviewDialogOpen && filePreviewDetails && (
        <FilePreviewDialog
          fileId={filePreviewDetails.fileId}
          fileType={filePreviewDetails.fileSourceType}
          onClose={() => setFilePreviewDialogOpen(false)}
        />
      )}
      {documentPreviewDialogOpen && documentPreviewId && (
        <DocumentPreviewDialog id={documentPreviewId} onClose={() => setDocumentPreviewDialogOpen(false)} />
      )}
    </>
  );
}
