import { getNodeType, getSplittedAttributes, RawCommands } from "@tiptap/core";
import { Node } from "@tiptap/pm/model";
import { TextSelection, Transaction } from "@tiptap/pm/state";

import { getIndentLevelByClass, getNewListItemClass } from "../../Utils/CommandUtil.js";

const updateListMarkers = (parent: Node, tr: Transaction, after: number) => {
  // Indent level for currently selected "list"
  const parentIndentLevel = getIndentLevelByClass(parent.attrs.class);

  let ignoreIndentLevelsAbove: number | null = null;
  tr.doc.forEach((node, offset, index) => {
    if (
      node.type.name === parent.type.name &&
      node.attrs["data-list-id"] === parent.attrs["data-list-id"] &&
      // This node falls after the currently selected list item
      offset > after
    ) {
      const nodeIndentLevel = getIndentLevelByClass(node.attrs.class);

      // If the list lifts somewhere then we don't want to change numbering
      // for those indent levels.
      //
      // 1. first
      // 2. second
      //   a. third|           <-- If the list is splitted here then we would not want
      //   b. fourth               to consider changing numbering for "fifth".
      //     i. fifth
      //   c. sixth
      // 3. seventh
      if (nodeIndentLevel < parentIndentLevel) {
        ignoreIndentLevelsAbove = ignoreIndentLevelsAbove
          ? Math.max(ignoreIndentLevelsAbove, parentIndentLevel)
          : parentIndentLevel;
      }

      const canChangeListMarker = ignoreIndentLevelsAbove === null ? true : nodeIndentLevel < ignoreIndentLevelsAbove;
      if (canChangeListMarker && nodeIndentLevel === parentIndentLevel) {
        // Change "start" attribute value.
        tr.setNodeAttribute(offset, "start", node.attrs.start ? node.attrs.start + 1 : 1);
      }
    }
  });
};

// This command is run when "Enter" key binding is invoked for "listItem".
// We will try and add a new "listItem" right before the current selection
// as part of this.
export const splitListItem: RawCommands["splitListItem"] =
  (typeOrName) =>
  ({ tr, state, dispatch, editor }) => {
    const type = getNodeType(typeOrName, state.schema);
    const { $from, $to } = state.selection;

    const range = $from.blockRange($to, (node) => node.childCount > 0 && node.firstChild!.type === type);
    if (!range) {
      return false;
    }

    // "listItem" node represents the "li" tag which is present on
    // "-1" depth from the current selection i.e. the paragraph.
    //
    // <ol>
    //   <li>
    //     <p> text </p>
    //   </li>
    // </ol>
    const listItem = $from.node(-1);
    if (listItem.type !== type) {
      return false;
    }

    const paragraph = listItem.firstChild;
    if (!paragraph || paragraph.content.size === 0) {
      return false;
    }

    // "list" node represents the "ol" tag which is present on
    // "-2" depth from the current selection i.e. the paragraph.
    //
    // <ol>
    //   <li>
    //     <p> text </p>
    //   </li>
    // </ol>
    const list = $from.node(-2);
    if (list.type.name !== "orderedList" && list.type.name !== "bulletList") {
      return false;
    }

    if (dispatch) {
      // Default attributes which come as part of the extension
      const extensionAttributes = editor.extensionManager.attributes;
      const after = range.end;

      // Update list markers for the "listItem" which are right after the currently
      // selected "listItem"
      updateListMarkers(list, tr, after);

      // If split command is invoked from middle of the text content then the
      // new "listItem" will have text content which falls after the current
      // selection, also the current "listItem" will have content which falls
      // before the current selection.
      //
      // BEFORE -
      // 1. test|text
      //
      // "|" represents current selection, if we hit "Enter" at this point then -
      //
      // AFTER -
      // 1. test
      // 2. |text
      const newContent = paragraph.slice(0, $from.parentOffset);
      const newListItemContent = paragraph.slice($from.parentOffset);

      // New "list" will have "start" value 1 more than the currently selected
      // "list".
      const newAttributes = getSplittedAttributes(extensionAttributes, list.type.name, {
        ...list.attrs,
        start: list.attrs.start ? list.attrs.start + 1 : 1,
        // We don't want to copy "data-id" attribute to the new list, this is generated
        // by the UniqueId extension.
        id: undefined,
      });
      const fragment = newListItemContent.content;
      const inner = paragraph
        ? paragraph.type.create(
            {
              ...paragraph.attrs,
            },
            fragment,
          )
        : fragment;
      const newNode = list.type.create(
        newAttributes,
        listItem.type.create(
          {
            ...listItem.attrs,
            class: getNewListItemClass(listItem.attrs.class, list.type.name, "split"),
            // We don't want to copy "data-id" attribute to the new list, this is generated
            // by the UniqueId extension.
            id: undefined,
          },
          inner,
        ),
      );

      // Insert new list node
      tr.insert(after, newNode);
      // Change the current list text content
      tr.replaceWith($from.before(), $from.after() - 1, newContent.content);
      // Move selection cursor to the beginning of the new list
      tr.setSelection(TextSelection.near(tr.doc.resolve(after - Math.max(newListItemContent.size - 4, 0))));
    }

    return true;
  };
