export enum CommandActionType {
  /**
   * Accepts a suggestion for a board document.
   */
  AcceptWorkspaceBoardSuggestionAction = "AcceptWorkspaceBoardSuggestionAction",

  /**
   * Chat message received.
   */
  ChatMessageAction = "ChatMessageAction",

  /**
   * Ingests file content into standard HTML and updates the HTML document shape.
   */
  CreateHtmlDocumentShapeFromFile = "CreateHtmlDocumentShapeFromFile",

  /**
   * Extract questions from the given document's content.
   */
  ExtractQuestionsAction = "ExtractQuestionsAction",

  /**
   * Run a client side action.
   */
  GenericClientAction = "GenericClientAction",

  /**
   * Generates distilled content for the given transcript.
   */
  GenerateDistilledTranscriptAction = "GenerateDistilledTranscriptAction",

  /**
   * Generate ThemeDiscussionAnalysis data for the given document.
   */
  GenerateThemeDiscussionAnalysisAction = "GenerateThemeDiscussionAnalysisAction",

  /**
   * Generate simulated headlines for the given text.
   */
  HeadlineSimulatorAction = "HeadlineSimulatorAction",

  /**
   * Generates responses using predefined HTML prompts.
   */
  HtmlPrompterAction = "HtmlPrompterAction",

  /**
   * Run a GraphQL query against an internal service.
   */
  InternalGraphQLQuery = "InternalGraphQLQuery",

  /**
   * Command pipeline aliveness ping.
   */
  PingAction = "PingAction",

  /**
   * A generic text processing action with specific parameters defining the desired behavior.
   */
  ProcessTextAction = "ProcessTextAction",

  /**
   * Reverts an applied suggestion for a board document.
   */
  RevertWorkspaceBoardSuggestionAction = "RevertWorkspaceBoardSuggestionAction",

  /**
   * Generates suggested answers for all given questionnaire questions.
   */
  SuggestAllAnswersAction = "SuggestAllAnswersAction",

  /**
   * Suggests a single answer for a given questionnaire question.
   */
  SuggestAnswerAction = "SuggestAnswerAction",
}
